/**
 * Input Box Component
 * @flow
 * @format
 */

import React from "react";

const Input = (props) => {
  const { name, id, value, placeHolder, onChange, onBlur, type, className } =
    props;
  return (
    <>
      <div className="w-full">
        <input
          autoComplete="off"
          type={type}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          placeholder={placeHolder || ""}
          className={`w-full h-[44px] mt-1  border-2 cursor-text border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover ${className}`}
        />
      </div>
    </>
  );
};

export default Input;
