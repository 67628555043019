import React from "react";

export const AddCircle = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9 0C4.0371 0 0 4.0371 0 9C0 13.9629 4.0371 18 9 18C13.9629 18 18 13.9622 18 9C18 4.0378 13.9629 0 9 0ZM9 16.6057C4.80674 16.6057 1.39426 13.194 1.39426 9C1.39426 4.80604 4.80674 1.39426 9 1.39426C13.1933 1.39426 16.6057 4.80604 16.6057 9C16.6057 13.194 13.194 16.6057 9 16.6057Z"
          fill="white"
        />
        <path
          d="M12.4856 8.24007H9.69711V5.45154C9.69711 5.06672 9.38548 4.75439 8.99996 4.75439C8.61444 4.75439 8.30281 5.06672 8.30281 5.45154V8.24007H5.51429C5.12876 8.24007 4.81714 8.55239 4.81714 8.93721C4.81714 9.32203 5.12876 9.63436 5.51429 9.63436H8.30281V12.4229C8.30281 12.8077 8.61444 13.12 8.99996 13.12C9.38548 13.12 9.69711 12.8077 9.69711 12.4229V9.63436H12.4856C12.8712 9.63436 13.1828 9.32203 13.1828 8.93721C13.1828 8.55239 12.8712 8.24007 12.4856 8.24007Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_8401">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
