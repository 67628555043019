import React from "react";

export const Driver = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9062 12.6562C8.41692 12.6562 5.57812 9.81745 5.57812 6.32812C5.57812 2.8388 8.41692 0 11.9062 0C15.3956 0 18.2344 2.8388 18.2344 6.32812C18.2344 9.81745 15.3956 12.6562 11.9062 12.6562ZM11.9062 1.875C9.4508 1.875 7.45312 3.87267 7.45312 6.32812C7.45312 8.78358 9.4508 10.7812 11.9062 10.7812C14.3617 10.7812 16.3594 8.78358 16.3594 6.32812C16.3594 3.87267 14.3617 1.875 11.9062 1.875ZM12 21.6562C11.3528 21.6562 10.8281 22.1809 10.8281 22.8281C10.8281 23.4753 11.3528 24 12 24C12.6472 24 13.1719 23.4753 13.1719 22.8281C13.1719 22.1809 12.6472 21.6562 12 21.6562ZM16.9688 23.0625C16.9688 20.3227 14.7397 18.0938 12 18.0938C9.26025 18.0938 7.03125 20.3227 7.03125 23.0625C7.03125 23.5803 7.45097 24 7.96875 24C8.48653 24 8.90625 23.5803 8.90625 23.0625C8.90625 21.3566 10.2941 19.9688 12 19.9688C13.7059 19.9688 15.0938 21.3566 15.0938 23.0625C15.0938 23.5803 15.5135 24 16.0312 24C16.549 24 16.9688 23.5803 16.9688 23.0625ZM21.2344 23.0625C21.2344 18.3584 17.4073 14.5312 12.7031 14.5312H11.2969C6.59273 14.5312 2.76562 18.3584 2.76562 23.0625C2.76562 23.5803 3.18534 24 3.70312 24C4.22091 24 4.64062 23.5803 4.64062 23.0625C4.64062 19.3922 7.62661 16.4062 11.2969 16.4062H12.7031C16.3734 16.4062 19.3594 19.3922 19.3594 23.0625C19.3594 23.5803 19.7791 24 20.2969 24C20.8147 24 21.2344 23.5803 21.2344 23.0625Z"
        fill="#07284B"
      />
    </svg>
  );
};
