/**
 * Text Area Box Component
 * @flow
 * @format
 */

import React from "react";

const TextArea = (props) => {
  const { name, id, onChange, disabled, placeholder, value, className } = props;
  return (
    <>
      <div className="w-full">
        <textarea
          className={`w-full p-4 mt-1 text-sm border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover ${className}`}
          placeholder={placeholder || ""}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default TextArea;
