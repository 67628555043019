import React from "react";

export const Clock = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16.9712C3.58867 16.9712 0 13.3825 0 8.97119C0 4.55986 3.58867 0.971191 8 0.971191C12.4113 0.971191 16 4.55986 16 8.97119C16 13.3825 12.4113 16.9712 8 16.9712ZM8 1.63786C3.95667 1.63786 0.666667 4.92786 0.666667 8.97119C0.666667 13.0145 3.95667 16.3045 8 16.3045C12.0433 16.3045 15.3333 13.0145 15.3333 8.97119C15.3333 4.92786 12.0433 1.63786 8 1.63786ZM11.3333 8.97119H8V4.30452H7.33333V9.63786H11.3333V8.97119Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
};
