/**
 *  Current Camp Card Component
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LiveIcon } from "../../../assets/icons/live-icon";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { User } from "../../../assets/icons/User";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import CampFlowStepComponent from "./campFlowStepCard";
import CounterCardComponent from "./counterCard";
import StepWithCountsComponent from "./stepWithNumber";

const CurrentCampCardComponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCampFlow, setIsCampFlow] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const { name } = props;

  const counters = [
    {
      user_name: "Kiran Kumar",
      counter_number: "1",
      completedBeneficiary: "75",
      waitingBeneficiary: "05",
    },
    {
      user_name: "Kiran Kumar",
      counter_number: "2",
      completedBeneficiary: "75",
      waitingBeneficiary: "05",
    },
  ];
  const campSteps = [
    {
      name: "check in",
      completedBeneficiary: "75",
      waitingBeneficiary: "05",
    },
    {
      name: "Assessment",
      completedBeneficiary: "75",
      waitingBeneficiary: "05",
    },
  ];

  const [isStepOpen, setIsStepOpen] = useState(
    new Array(campSteps.length).fill(false)
  );

  // Functions
  // handle toggle of step
  const handleToggleStep = (index) => {
    setIsStepOpen((prev) =>
      prev.map((open, i) => (i === index ? !open : open))
    );
  };

  return (
    <>
      <div className="flex flex-col gap-3 items-start justify-between w-full">
        <div className="flex gap-3 items-start justify-between w-full">
          <div className="flex flex-col items-start">
            <div className="flex gap-1 items-start">
              <div className="font-semibold whitespace-nowrap max-w-[140px] overflow-hidden truncate">
                First Assessment CaFirst Assessment Ca...
              </div>
              <div className="bg-[#00A638] rounded-md flex items-center justify-center px-[2px] py-[2px]">
                <LiveIcon width={24} height={14}></LiveIcon>
              </div>
            </div>
            <div className="flex gap-1 items-start">
              <div className="font-semibold whitespace-nowrap max-w-[140px] overflow-hidden truncate">
                Assessment camp
              </div>
            </div>
          </div>
          <div className="text-center whitespace-nowrap">
            Camp Code <br></br>
            <span className="font-bold">EXY1234D</span>
          </div>
        </div>
        {/* date n time */}
        <div className="flex justify-between gap-1 items-start w-full">
          <div className="flex justify-between gap-1 items-start">
            <CalendarNew />
            <div className="text-[#666]">Apr 20, 2024</div>
          </div>
          <div className="flex justify-between gap-1 items-start">
            <Clock width={18} height={18} />
            <div className="text-[#666]">9:30 Am - 6:30 Pm</div>
          </div>
        </div>
        {/* location n users */}
        <div className="flex justify-between gap-1 items-center">
          <div className="flex justify-between gap-1 items-start">
            <LocationMap width={14} height={16} fillColor={"#7B7B7B"} />

            <div className="text-[#666]">
              <a> Venkat Centre, Silukkapatti , Dist-Kottayam, Kerala,</a>
            </div>
          </div>
          <div className="flex justify-between gap-1 items-center">
            <User width={16} height={16} />

            <div className="font-bold text-lg">12</div>
          </div>
        </div>
        {/* Beneficiaries */}
        <div className="flex flex-col justify-between bg-[#F4FBFF] rounded-lg w-full">
          {/* Beneficiaries card */}
          <div className="p-3">
            <div className="flex justify-between gap-1 items-center rounded-lg w-full mb-2">
              {/* card-heading */}
              <div className="text-base font-bold">Beneficiaries</div>
              <div className="flex justify-between gap-1 items-center">
                <button
                  className={`h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center min-w-[24px] w-[24px] min-h-[24px] ${
                    isCampFlow ? "rotate-180" : "rotate-90"
                  }`}
                  onClick={() => {
                    setIsCampFlow(!isCampFlow);
                  }}
                >
                  <ArrowIcon
                    width={6}
                    height={10}
                    isRotated={isCampFlow}
                    fillColor={"#ffffff"}
                  />
                </button>
              </div>
            </div>

            {/* list beneficiary */}
            <div className="flex flex-col justify-between gap-4 items-center rounded-lg w-full">
              <div className="flex flex-row justify-between items-center w-full">
                <StepWithCountsComponent
                  data={{ name: "Scheduled", CountNumber: "80" }}
                  colors={{ text: "#FF5976", background: "#F5E7EE" }}
                />
                <StepWithCountsComponent
                  data={{ name: "Checked-in", CountNumber: "28" }}
                  colors={{ text: "#256CD0", background: "#DDF1FE" }}
                />
              </div>
              <div className="flex flex-row justify-between items-center w-full">
                <StepWithCountsComponent
                  data={{ name: "In Progress", CountNumber: "80" }}
                  colors={{ text: "#FAAE00", background: "#F5EFD6" }}
                />
                <StepWithCountsComponent
                  data={{ name: "Checked-out", CountNumber: "28" }}
                  colors={{ text: "#04CF3D", background: "#D6F5E7" }}
                />
              </div>
            </div>
          </div>

          {/* camp flow*/}
          {isCampFlow && (
            <div className="p-3 border-[#DBDBDB] border-t-2 flex flex-col gap-2">
              {/* heading */}
              <div className="text-base font-bold">Camp Flow</div>

              {/* card 1 with toggle active*/}

              {campSteps?.map((step, stepIndex) => (
                <div
                  className="bg-[white] border-2 border-[#61B1E5] overflow-hidden rounded-xl space-y-3 w-full"
                  key={stepIndex}
                >
                  {/* heading with toggle btn */}
                  <CampFlowStepComponent
                    stepData={step}
                    isViewCounter={isStepOpen[stepIndex]}
                    setIsViewCounter={() => handleToggleStep(stepIndex)}
                  />
                  {/* toggle-body */}
                  {isStepOpen[stepIndex] && (
                    <div className="space-y-2">
                      {counters?.map((item, index) => {
                        return (
                          <CounterCardComponent
                            key={index}
                            counterData={{
                              user_name: item?.user_name,
                              counter_number: item?.counter_number,
                              completedBeneficiary: item?.completedBeneficiary,
                              waitingBeneficiary: item?.waitingBeneficiary,
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}

              {/* card 2 with toggle*/}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentCampCardComponent;
