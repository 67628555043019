/**
 * List Beneficiary of Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState, useRef } from "react";
import { Cross } from "../../../assets/icons/Cross";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllAssinBeneficiayDataList,
  selectBeneficiaryQueryCurrentPage,
  selectBeneficiaryQueryRespPerPage,
  selectBeneficiaryQuerySearch,
} from "../selector";
import {
  deleteBeneficiaryFromQuery,
  getAllAssignBeneficiariesOfCamp,
  setbeneficiaryQuerySearch,
} from "../slice";
import { NoRecord } from "../../../components/noRecords";
import { Delete } from "../../../assets/icons/Delete";
import Pagination from "../../../components/pagination";
import { formatDate } from "../../../utils";
import { Loader } from "../../../components";
import DeleteConfirm from "../../../components/deleteConfirm";

const ListCampBeneficiary = ({ toggleModal, rowData, getData }) => {
  const dispatch = useDispatch();
  const { confirm, DeleteConfirmModal } = DeleteConfirm();

  const [dataTableLoading, setDataTableLoading] = useState(false);
  const { data: beneficiaryListData = [], pagination } = useSelector(
    selectAllAssinBeneficiayDataList
  );
  const searchQuery = useSelector(selectBeneficiaryQuerySearch);
  const beneficiaryQueryCurrentPage = useSelector(
    selectBeneficiaryQueryCurrentPage
  );
  const beneficiaryQueryRespPerPage = useSelector(
    selectBeneficiaryQueryRespPerPage
  );

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black",
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      style: columnStyle,
      sortable: true,
      backgroundColor: "black",
    },
    {
      name: "Age",
      selector: (row) => row?.age,
      style: columnStyle,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Gender",
      selector: (row) => row?.gender,
      style: columnStyle,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Address",
      selector: (row) => row?.address_1,
      style: columnStyle,
      sortable: true,
      // maxWidth: "150px",
      cell: (row) => (
        <>
          <div className="w-full">
            {row?.district},{row?.state}, Pincode - {row?.pin_code}
          </div>
        </>
      ),
    },
    {
      name: "Contact",
      selector: (row) => row?.phone,
      style: columnStyle,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: (
        <div className="flex flex-col item-center">
          <span className="">
            Registered on <SortIcon isSorted={false} />
          </span>
          <span className="">
            Verified on <SortIcon isSorted={false} />
          </span>
        </div>
      ),
      selector: (row) => row?.from_time,
      style: columnStyle,
      center: true,
      // sortable: true,
      minWidth: "200px",
      cell: (row) => (
        <>
          <div className="w-full flex flex-col item-center  space-y-2">
            <span className="">{formatDate(row?.registered_on)}</span>
            <span className="">{formatDate(row?.verification_date)}</span>
          </div>
        </>
      ),
    },
    {
      name: "Reg No.",
      selector: (row) => row?.registration_number,
      style: columnStyle,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "",
      selector: (row) => row?.registration_number,
      style: columnStyle,
      sortable: true,
      minWidth: "70px",
      maxWidth: "70px",
      cell: (row) => (
        <>
          <div className="w-full flex flex-col item-center justify-center">
            <span
              className="cursor-pointer"
              onClick={async () => {
                const isConfirmed = await confirm(
                  `'${row?.name}' Beneficiary will be removed from this Camp. Are you sure?`
                );
                if (isConfirmed) {
                  removeBeneficiayFromQuery(rowData?._id, row?._id);
                }
              }}
            >
              <Delete width={16} height={16} />
            </span>
          </div>
        </>
      ),
    },
  ];

  const getBeneficiayData = (search, page, perPage) => {
    setDataTableLoading(true);
    dispatch(
      getAllAssignBeneficiariesOfCamp({
        query_id: rowData?._id,
        search,
        page,
        perPage,
        onSuccess: () => {
          setDataTableLoading(false);
        },
      })
    );
  };
  const removeBeneficiayFromQuery = (id, beneficiary_id) => {
    dispatch(
      deleteBeneficiaryFromQuery({
        id,
        beneficiary_id,
        onSuccess: () => {
          getBeneficiayData(
            searchQuery,
            beneficiaryQueryCurrentPage,
            beneficiaryQueryRespPerPage
          );
        },
      })
    );
  };
  useEffect(() => {
    if (rowData) {
      getBeneficiayData(
        searchQuery,
        beneficiaryQueryCurrentPage,
        beneficiaryQueryRespPerPage
      );
    }
  }, [
    rowData,
    searchQuery,
    beneficiaryQueryCurrentPage,
    beneficiaryQueryRespPerPage,
  ]);
  return (
    <>
      <div className="fixed h-full inset-0 z-50 flex justify-end">
        <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
        <div
          data-aos-easing="linear"
          data-aos="fade-left"
          className="flex flex-col relative w-[80%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
        >
          <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
            <h2 className="text-xl font-bold">Beneficiary List</h2>
            <button onClick={toggleModal} className="text-xl font-semibold">
              <Cross width={32} height={32} />
            </button>
          </div>
          {/* Modal content */}
          <div className="w-full flex flex-col flex-auto justify-start space-y-4 py-6 px-8 ">
            <div className="w-full">
              <p>
                <strong>Criteria</strong> : {rowData?.queryText}
              </p>
            </div>
            {/* Search Bar */}
            <dvi className="w-full flex flex-row justify-between items-center">
              <div className="container p-0 flex justify-between items-center w-[60%] ">
                <div className="relative flex-1 rounded-lg h-full ">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none h-full">
                    <SearchIcon width={20} height={20} />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full h-[100%] px-3 py-3 font-DM ps-10 text-sm text-gray-tone-100 rounded-lg border border-gray-border-100"
                    placeholder="Search By Mobile Number/Name"
                    onChange={(event) => {
                      dispatch(setbeneficiaryQuerySearch(event.target.value));
                    }}
                  />
                </div>
              </div>
              {/* pagination */}
              <div>
                <Pagination
                  name={"queryBeneficiary"}
                  totalNumber={pagination?.totalCounts}
                  dataPerPage={beneficiaryQueryRespPerPage}
                  data={getBeneficiayData}
                  currentPageApi={pagination?.currentPage}
                  perPageOptions={[5, 10, 30, 50, 100]}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </div>
            </dvi>
            <div className="">
              <div className=" overflow-y-auto scrollbar-hide theme-table">
                <DataTable
                  columns={columns}
                  data={beneficiaryListData}
                  progressPending={dataTableLoading}
                  progressComponent={
                    <div className="h-[400px] w-full flex justify-center items-center bg-white">
                      <Loader />
                    </div>
                  }
                  customStyles={{
                    headCells: {
                      style: {
                        backgroundColor: "#DDF1FE",
                        color: "Black",
                        fontWeight: "bold",
                        fontSize: "14px",
                      },
                    },
                  }}
                  sortIcon={<SortIcon />}
                  noDataComponent={
                    <div className="h-full py-16">
                      <NoRecord
                        title_1={"There are no records to display"}
                        title_2={""}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {DeleteConfirmModal}
      </div>
    </>
  );
};

export { ListCampBeneficiary };
