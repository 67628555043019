/**
 * Navigation Bar Components
 * @format
 */

import React, { useState } from "react";
import {
  selectBeneficiaryDetailCurrentPage,
  selectBeneficiaryDetailDataPerPage,
  selectCurrCampCurrentPage,
  selectCurrCampDataPerPage,
  selectCurrCampDistrictId,
  selectCurrCampFilter,
  selectCurrCampNavStatus,
  selectCurrCampStateId,
  selectDistricId,
  selectFilter,
  selectStateId,
} from "../selector";
import { useDispatch, useSelector } from "react-redux";
import {
  allBeneficiaryDetailListByStatus,
  setActiveUser,
  setBeneficiaryStatus,
  setCurrCampNavStatus,
} from "../slice";
import {
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
} from "../../camp_management/selector";
import { setScheduleCampFilter } from "../../camp_management/slice";

const NavigationBar = ({
  statuses,
  getData,
  beneficiaryDetailToggle,
  barName,
  pageName,
}) => {
  const dispatch = useDispatch();
  const [activeStatus, setActiveStatus] = useState(0);
  const currentPage = useSelector(selectBeneficiaryDetailCurrentPage);
  const limit = useSelector(selectBeneficiaryDetailDataPerPage);
  const districtId = useSelector(selectDistricId);
  const stateId = useSelector(selectStateId);
  const filter = useSelector(selectFilter);
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);
  // running camp
  const runningCampCurrentPage = useSelector(selectCurrCampCurrentPage);
  const runningCampLimit = useSelector(selectCurrCampDataPerPage);
  const runningCampDistrictId = useSelector(selectCurrCampDistrictId);
  const runningCampStateId = useSelector(selectCurrCampStateId);
  const runningCampFilter = useSelector(selectCurrCampFilter);
  const navStatus = useSelector(selectCurrCampNavStatus);

  const handleStatusClick = async (index) => {
    try {
      // Get the status string and trim it to get the status name
      const statusString = statuses[index];
      const statusName = statusString.split("(")[0].trim();

      // Set the active status index
      setActiveStatus(index);

      if (barName === "beneficiaryVerificationScreen") {
        dispatch(setActiveUser(null));
        const getStatusName = (page_name, statusName) => {
          if (page_name === "Verification") {
            if (statusName === "All") {
              return "";
            } else if (statusName === "Verified") {
              return "Verified";
            } else if (statusName === "Rejected") {
              return "Rejected";
            } else if (statusName === "Pending") {
              return "Pending";
            }
          } else if (page_name === "Assessments") {
            if (statusName === "All") {
              return "";
            } else if (statusName === "In Progress") {
              return "assessment_in_progress";
            } else if (statusName === "Scheduled") {
              return "assessement_scheduled";
            } else if (statusName === "To be Scheduled") {
              return "verified";
            }
          } else if (page_name === "Fabrication") {
            if (statusName === "All") {
              return "";
            } else if (statusName === "In Progress") {
              return "fabrication_in_progress";
            } else if (statusName === "Not Yet started") {
              return "assessement_completed";
            } else if (statusName === "Not Eligible") {
              return "not_eligible_for_fabrication";
            }
          } else if (page_name === "Distribution") {
            if (statusName === "All") {
              return "";
            } else if (statusName === "In Progress") {
              return "distribution_in_progress";
            } else if (statusName === "Scheduled") {
              return "distribution_scheduled";
            } else if (statusName === "To be Scheduled") {
              return "fabrication_completed";
            }
          }
        };
        // Update the beneficiary status
        const nabStatus = getStatusName(pageName, statusName);
        // Update the beneficiary status
        dispatch(setBeneficiaryStatus(nabStatus));

        // Call the API with the updated status
        getData("", stateId, districtId, nabStatus, currentPage, limit, filter);
      }

      if (barName === "campBeneficiaryScreen") {
        const getStatusName = (statusName) => {
          if (statusName === "Screened by me") {
            return "screenedByMe";
          } else if (statusName === "Waiting for me") {
            return "waitingForMe";
          } else if (statusName === "Waiting at other desk") {
            return "waitingForOtherDesk";
          }
        };
        // Update the beneficiary status
        const nabStatus = getStatusName(statusName);
        dispatch(setCurrCampNavStatus(nabStatus));

        // Call the API with the updated status
        getData(
          "",
          runningCampStateId,
          runningCampDistrictId,
          nabStatus,
          runningCampCurrentPage,
          runningCampLimit,
          runningCampFilter
        );
      }

      if (barName === "scheduleCamp") {
        const getStatusName = (statusName) => {
          if (statusName === "All") {
            return "";
          } else if (statusName === "Today's Camps") {
            return "live";
          } else if (statusName === "Upcoming Camps") {
            return "upcoming";
          } else if (statusName === "Past camps") {
            return "past";
          }
        };

        const scheduleCampFilter = getStatusName(statusName);

        dispatch(setScheduleCampFilter(scheduleCampFilter));
        getData(
          scheduleCampSearchQuery,
          scheduleCampFilter,
          scheduleCampCurrentPage,
          scheduleCampPerPage
        );
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div className="container p-0 text-black h-[30px] w-full min-w-full">
      <div
        className={`container p-0 flex flex-row justify-start items-center w-full h-full ${
          barName === "scheduleCamp"
            ? "border-none"
            : "border-b  border-customBlue-border"
        }`}
      >
        {statuses.map((status, index) => {
          const [statusName, statusCount] = status.split("(");
          return (
            <button
              key={index}
              onClick={() => handleStatusClick(index)}
              className={`xl:mr-8 mr-6 h-full xl:text-base text-sm p-navigation leading-4 flex items-center justify-center whitespace-nowrap ${
                activeStatus === index
                  ? `text-black font-[600] border-b-2 ${
                      barName === "scheduleCamp"
                        ? "border-customBlue-activeBorder"
                        : " border-customBlue-activeBorder"
                    }`
                  : "text-black"
              }`}
            >
              {statusName}
              <span className="ml-2 text-[#333333] flex items-center justify-center leading-none font-[600] text-[10px] bg-[#E6E6E6] rounded-lg py-[4px] px-[8px]">
                {statusCount?.slice(0, -1) || ""}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default NavigationBar;
