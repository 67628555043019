/**
 * Utils
 * all veriable declare here
 * @format
 */

exports.constants = {
  SSSDS: "Sri Sathya Sai Divyangjan Seva",

  // Dashboard

  // Beneficiary Management
  registration: "Registration",
  registration_no: "Registration No:",
  complete_on: "Completed on",
  beneficiary_list: "Beneficiary List",
  select_beneficiary: "Select beneficiary to view beneficiaryData's details",
  Communication_history: "Communication History",
  age: "Age:",
  disability_type: "Disability Type:",

  // User Management
  state: "66a2201ee86fb3ddeba06b58",
  district: "66a22030e86fb3ddeba06b5b",
  national: "66a22036e86fb3ddeba06b5e",
  pinCode: "66a2203ee86fb3ddeba06b61",
  orgUser: "66c5b0bb09747ac9393979d0",
  // Role Management
};
