import React from "react";

export const Briefcase = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 3.9375H12.375V3.375C12.375 2.41875 11.5875 1.6875 10.6875 1.6875H7.3125C6.4125 1.6875 5.625 2.41875 5.625 3.375V3.9375H2.25C1.29375 3.9375 0.5625 4.725 0.5625 5.625V14.625C0.5625 15.525 1.29375 16.3125 2.25 16.3125H15.75C16.65 16.3125 17.4375 15.525 17.4375 14.625V5.625C17.4375 4.725 16.65 3.9375 15.75 3.9375ZM6.75 3.375C6.75 3.0375 6.975 2.8125 7.3125 2.8125H10.6875C10.9688 2.8125 11.25 3.0375 11.25 3.375V3.9375H6.75V3.375ZM2.25 5.0625H15.75C16.0312 5.0625 16.3125 5.2875 16.3125 5.625V7.425L10.35 9.1125C10.0687 8.71875 9.5625 8.4375 9 8.4375C8.4375 8.4375 7.93125 8.71875 7.65 9.16875L1.6875 7.425V5.625C1.6875 5.2875 1.96875 5.0625 2.25 5.0625ZM9.5625 10.125C9.5625 10.4062 9.28125 10.6875 9 10.6875C8.71875 10.6875 8.4375 10.4062 8.4375 10.125C8.4375 9.84375 8.6625 9.5625 9 9.5625C9.28125 9.5625 9.5625 9.84375 9.5625 10.125ZM15.75 15.1875H2.25C1.96875 15.1875 1.6875 14.9062 1.6875 14.625V8.60625L7.3125 10.2375C7.36875 11.1375 8.1 11.8125 9 11.8125C9.9 11.8125 10.6313 11.1375 10.6875 10.2375L16.3125 8.60625V14.625C16.3125 14.9062 16.0312 15.1875 15.75 15.1875Z"
        fill="#3B395F"
      />
    </svg>
  );
};
