import React, { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { Cross } from "../assets/icons/Cross";

const MultipleTagsInput = ({ name, placeHolder, value, onChange }) => {

  const [tags, setTags] = useState(
    value.map((item) => ({
      id: item,
      text: item,
    }))
  );

  const handleDelete = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleAddition = (tag) => {
    setTags((prevTags) => {
      return [...prevTags, tag];
    });
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const onClearAll = () => {
    setTags([]);
  };

  useEffect(() => {
    if (tags) {
      const tagTexts = tags.map((tag) => tag.text);
      // console.log({ value });
      onChange(tagTexts);
    }
  }, [tags]);
  return (
    <div className="w-full flex flex-row justify-between items-center px-2 min-h-[44px] p-0 border-2 border-customBlue-border rounded-xl bg-white">
      <ReactTags
        tags={tags}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        allowDragDrop={false}
        name={name}
        placeholder={placeHolder}
        inputFieldPosition="inline"
        handleTagClick={handleTagClick}
        allowUnique={true}
        maxLength={6}
        editable={false}
      />
      {/* Clear All cross button */}
      {tags.length > 0 && (
        <button
          onClick={onClearAll}
          className="ml-2 border-2 rounded-full text-black text-lg font-bold"
          title="Clear All"
        >
          <Cross height={30} width={30} />
        </button>
      )}
    </div>
  );
};

export default MultipleTagsInput;
