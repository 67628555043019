/**
 * Step and CountNumber Component for camp overview
 * @flow
 * @format
 */

import React from "react";

const StepWithCountsComponent = (props) => {
  const { data, colors } = props;

  return (
    <>
      <div className="flex flex-col justify-between gap-1 items-center rounded-lg w-max">
        <div className=" uppercase text-[11px]">{data?.name}</div>
        <div
          className={` rounded-md flex items-center justify-center px-[6px] py-[2px]`}
          style={{ backgroundColor: colors?.background }}
        >
          <span className={` font-bold`} style={{ color: colors?.text }}>
            {data.CountNumber}
          </span>
        </div>
      </div>
    </>
  );
};

export default StepWithCountsComponent;
