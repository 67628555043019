import React from "react";

export const JourneyLink = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.375 7.27478V16.2748H9.71438L13.5 22.9213V30.8998H22.5V21.8998H15.5059L12.3053 16.2748H12.375V12.8998H23.625V16.2748H32.625V7.27478H23.625V10.6498H12.375V7.27478H3.375ZM5.625 9.52478H10.125V14.0248H5.625V9.52478ZM25.875 9.52478H30.375V14.0248H25.875V9.52478ZM16.2203 24.1498H20.25V28.6498H15.75V24.4175L16.2203 24.1498Z"
        fill="#8E98A8"
      />
    </svg>
  );
};
