/**
 * Check Box Component
 * @flow
 * @format
 */

import React from "react";

const CheckBox = (props) => {
  const { name, id, onChange, disabled, isChecked } = props;
  return (
    <>
      <div className="w-full">
        <input
          type="checkbox"
          className={`w-4 h-4 mr-4 ${
            isChecked ? "accent-WildWatermelon-button" : ""
          }`}
          checked={isChecked}
          name={name}
          id={id}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default CheckBox;
