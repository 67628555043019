/**
 * Role Management Selector
 * @format
 */

const roleManagement = (state) => state.roleManagementReducer;

export const selectAllRoleDataList = (state) =>
  roleManagement(state).allRoleDataList;

export const selectRole = (state) => roleManagement(state).role;

export const selectRoleWithUser = (state) => roleManagement(state).roleWithUser;

export const selectRoleCurrentPage = (state) =>
  roleManagement(state).roleCurrentPage;

export const selectRoleDataPerPage = (state) =>
  roleManagement(state).roleDataPerPage;

export const selectRoleSearchQuery = (state) =>
  roleManagement(state).roleSearchQuery;
