/**
 * User Management slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUserDataList: {},
  singleUserData: {},
  userCurrentPage: 1,
  userDataPerPage: 10,
  userSearchQuery: "",
  stateIdForUser: "",
  stateWithUserCount: [],
};

const useManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setUserDataList(state, action) {
      state.allUserDataList = action.payload;
    },
    setUserCurrentPage(state, action) {
      state.userCurrentPage = action.payload;
    },
    setUserDataPerPage(state, action) {
      state.userDataPerPage = action.payload;
    },
    setSingleUserData(state, action) {
      state.singleUserData = action.payload;
    },
    setUserSearchQuery(state, action) {
      state.userSearchQuery = action.payload;
    },
    setStateIdForUser(state, action) {
      state.stateIdForUser = action.payload;
    },
    setAllStateWithUserCount(state, action) {
      state.stateWithUserCount = action.payload;
    },
  },
});

// Reducer
export const userManagementReducer = useManagementSlice.reducer;

// Actions
export const {
  setUserDataList,
  setUserCurrentPage,
  setUserDataPerPage,
  setSingleUserData,
  setStateIdForUser,
  setUserSearchQuery,
  setAllStateWithUserCount,
} = useManagementSlice.actions;

export const allUsersList = createAction("ALLUSERSLIST");
export const createUser = createAction("CREATENEWUSER");
export const updateProfile = createAction("UPDAETPROFILE");
export const updateUserById = createAction("UPDATEUSERBYID");
export const updateUserStatusById = createAction("UPDATEUSESTATUSRBYID");
// export const getUserProfile = createAction("GETUSERPROFILE");
export const getUserById = createAction("GETUSERBYID");
export const deleteUser = createAction("DELETEUSER");
export const getUserFromSSSApi = createAction("GETUSESSSAPI");
export const getAllStateWithUserCount = createAction("GETSTATEWITHUSERCOUNT");
