/**
 * Edit Role Model
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { RoleFields } from "../../../constants";
import { Switch } from "@material-tailwind/react";
import { updateRoleById } from "../slice";
import { selectRoleCurrentPage, selectRoleDataPerPage } from "../selector";
import { Cross } from "../../../assets/icons/Cross";
import SelectDropDown from "../../../components/selectDropDown";
import CustomButton from "../../../components/customButtion";

const EditRoleModels = ({ role, toggleModal, getData }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectRoleCurrentPage);
  const limit = useSelector(selectRoleDataPerPage);

  const initialValues = {
    [RoleFields.ROLE_NAME]: "",
    [RoleFields.ROLE_TYPE]: "",
    [RoleFields.ROLE_TYPE_DISPLAY_NAME]: "",
    [RoleFields.ROLE_DESCRIPTION]: "",
    [RoleFields.PERMISSION]: [],
    [RoleFields.IS_ACTIVE]: null,
  };
  const [initialObject, setInitialObject] = useState(initialValues);

  useEffect(() => {
    setInitialObject(role);
  }, []);

  const roleTypeOptions = [
    { label: "Org User", value: "org_user" },
    { label: "Camp User", value: "camp_user" },
  ];

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    [RoleFields.ROLE_NAME]: Yup.string()
      .required("Role Name is required")
      .max(50, "Role Name cannot exceed 50 characters"),
    [RoleFields.ROLE_TYPE]: Yup.string()
      .required("Role Type is required")
      .oneOf(["org_user", "camp_user"], "Invalid Role Type"),
    [RoleFields.ROLE_DESCRIPTION]: Yup.string()
      .required("Role Description is required")
      .max(200, "Role Description cannot exceed 200 characters"),
  });

  return (
    <Formik
      initialValues={initialObject}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const updatedValues = {
          ...values,
          id: values._id,
        };
        delete updatedValues.permission;
        delete updatedValues._id;
        dispatch(
          updateRoleById({
            updatedValues,
            onSuccess: (props) => {
              toast.success(props?.message);
              toggleModal();
              getData("", currentPage, limit);
            },
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
        >
          <div className="fixed h-full inset-0 z-50 flex justify-end">
            <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
            <div
              data-aos-easing="linear"
              data-aos="fade-left"
              className="flex flex-col relative w-[43%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
            >
              <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                <h2 className="text-xl font-bold">Edit Role</h2>
                <button onClick={toggleModal} className="text-xl font-semibold">
                  <Cross width={32} height={32} />
                </button>
              </div>
              {/* Modal content */}
              <div className="w-full flex flex-col flex-auto justify-between py-6 px-8 ">
                <div className={"w-full flex flex-col space-y-3"}>
                  <div className="w-full">
                    <label className="text-sm font-bold">Role Name</label>
                    <input
                      autoComplete="off"
                      type="text"
                      name={RoleFields.ROLE_NAME}
                      id={RoleFields.ROLE_NAME}
                      onChange={handleChange}
                      value={values[RoleFields.ROLE_NAME]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                    />

                    {touched[RoleFields.ROLE_NAME] &&
                      errors[RoleFields.ROLE_NAME] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[RoleFields.ROLE_NAME]}
                        </div>
                      )}
                  </div>

                  <div className="w-full">
                    <label className="text-sm font-bold">Role Type</label>
                    <SelectDropDown
                      options={roleTypeOptions}
                      name={RoleFields.ROLE_TYPE}
                      placeholder="Select"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          RoleFields.ROLE_TYPE,
                          selectedOption?.value || ""
                        );
                        setFieldValue(
                          RoleFields.ROLE_TYPE_DISPLAY_NAME,
                          selectedOption?.label
                        );
                      }}
                      value={
                        roleTypeOptions?.find(
                          (item) => item?.value === values[RoleFields.ROLE_TYPE]
                        ) || null
                      }
                      touched={touched}
                      errors={errors}
                      isSearchable={true}
                      isClearable={false}
                    />

                    {touched[RoleFields.ROLE_TYPE] &&
                      errors[RoleFields.ROLE_TYPE] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[RoleFields.ROLE_TYPE]}
                        </div>
                      )}
                  </div>

                  <div className="w-full">
                    <label className="text-sm font-bold">
                      Role Description
                    </label>
                    <textarea
                      autoComplete="off"
                      name={RoleFields.ROLE_DESCRIPTION}
                      id={RoleFields.ROLE_DESCRIPTION}
                      onChange={handleChange}
                      value={values[RoleFields.ROLE_DESCRIPTION]}
                      onBlur={handleBlur}
                      className="w-full h-[88px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] resize-none focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                    />
                    {touched[RoleFields.ROLE_DESCRIPTION] &&
                      errors[RoleFields.ROLE_DESCRIPTION] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[RoleFields.ROLE_DESCRIPTION]}
                        </div>
                      )}
                  </div>
                  <div className="w-full">
                    <label className="text-sm font-bold">Status</label>
                    <div className="flex items-center space-x-4">
                      <Switch
                        ripple={false}
                        checked={values[RoleFields.IS_ACTIVE]}
                        onChange={() =>
                          setFieldValue(
                            RoleFields.IS_ACTIVE,
                            !values[RoleFields.IS_ACTIVE]
                          )
                        }
                        className="h-full w-full checked:bg-[#B0E0FF]"
                        containerProps={{
                          className: "w-8 h-4",
                        }}
                        circleProps={{
                          className:
                            "before:hidden left-1 border-none w-[12px] h-[12px]",
                        }}
                      />
                      <span>
                        {values[RoleFields.IS_ACTIVE] ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                </div>

                {/* buttons */}
                <div className="w-full flex justify-end space-x-4 h-[48px]">
                  <CustomButton
                    name="Reset"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      resetForm();
                    }}
                    loading={false}
                    disabled={false}
                    className={"min-w-[32%] bg-black text-white h-full"}
                  />
                  <CustomButton
                    name="Update"
                    type="submit"
                    loading={false}
                    disabled={false}
                    className={
                      " flex min-w-[32%] font-bold items-center justify-center h-full"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export { EditRoleModels };
