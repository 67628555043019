import React from "react";

export const UserWithCheck = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_569_1197)">
        <path
          d="M6.79722 0C4.31871 0 2.29722 2.02148 2.29722 4.5C2.29722 6.04941 3.08824 7.42551 4.28606 8.23661C1.99337 9.22101 0.368652 11.4961 0.368652 14.1429H1.65437C1.65437 11.2952 3.94957 9 6.79722 9C7.68115 9 8.50478 9.23104 9.22799 9.6228C8.51487 10.5067 8.08294 11.6367 8.08294 12.8571C8.08294 15.6897 10.3932 18 13.2258 18C16.0584 18 18.3687 15.6897 18.3687 12.8571C18.3687 10.0246 16.0584 7.71429 13.2258 7.71429C12.1033 7.71429 11.0587 8.08341 10.2124 8.69863C9.92865 8.52036 9.61975 8.36968 9.30835 8.23661C10.5062 7.42551 11.2972 6.04941 11.2972 4.5C11.2972 2.02148 9.27576 0 6.79722 0ZM6.79722 1.28571C8.58012 1.28571 10.0115 2.71707 10.0115 4.5C10.0115 6.2829 8.58012 7.71429 6.79722 7.71429C5.0143 7.71429 3.58294 6.2829 3.58294 4.5C3.58294 2.71707 5.0143 1.28571 6.79722 1.28571ZM13.2258 9C15.3628 9 17.0829 10.7202 17.0829 12.8571C17.0829 14.9941 15.3628 16.7143 13.2258 16.7143C11.0888 16.7143 9.36865 14.9941 9.36865 12.8571C9.36865 10.7202 11.0888 9 13.2258 9ZM15.3351 11.1093L13.2258 13.2188L11.7593 11.7522L10.8351 12.6764L12.7637 14.6049L13.2258 15.0468L13.6879 14.6049L16.2593 12.0335L15.3351 11.1093Z"
          fill="#8E98A8"
        />
      </g>
      <defs>
        <clipPath id="clip0_569_1197">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.368652)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
