import React from "react";

export const LocationMap = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96333 12.5929C7.41583 11.2766 10 8.58742 10 6.11961C10 4.75417 9.47322 3.44464 8.53553 2.47913C7.59785 1.51361 6.32608 0.971191 5 0.971191C3.67392 0.971191 2.40215 1.51361 1.46447 2.47913C0.526784 3.44464 1.97602e-08 4.75417 0 6.11961C0 8.58742 2.58333 11.2766 4.03667 12.5929C4.30176 12.8365 4.64467 12.9712 5 12.9712C5.35533 12.9712 5.69824 12.8365 5.96333 12.5929ZM3.33333 6.11961C3.33333 5.66446 3.50893 5.22796 3.82149 4.90612C4.13405 4.58428 4.55797 4.40347 5 4.40347C5.44203 4.40347 5.86595 4.58428 6.17851 4.90612C6.49107 5.22796 6.66667 5.66446 6.66667 6.11961C6.66667 6.57476 6.49107 7.01127 6.17851 7.33311C5.86595 7.65495 5.44203 7.83575 5 7.83575C4.55797 7.83575 4.13405 7.65495 3.82149 7.33311C3.50893 7.01127 3.33333 6.57476 3.33333 6.11961Z"
        fill={fillColor}
        fillOpacity="0.5"
      />
    </svg>
  );
};
