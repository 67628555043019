import React from "react";

export const DndDotsIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -1 9 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.71429" cy="1.71429" r="1.71429" fill="#B0B0B0" />
      <circle cx="1.71429" cy="8.57171" r="1.71429" fill="#B0B0B0" />
      <circle cx="1.71429" cy="15.4282" r="1.71429" fill="#B0B0B0" />
      <circle cx="1.71429" cy="22.2856" r="1.71429" fill="#B0B0B0" />
      <circle cx="8.57146" cy="1.71429" r="1.71429" fill="#B0B0B0" />
      <circle cx="8.57146" cy="8.57171" r="1.71429" fill="#B0B0B0" />
      <circle cx="8.57146" cy="15.4282" r="1.71429" fill="#B0B0B0" />
      <circle cx="8.57146" cy="22.2856" r="1.71429" fill="#B0B0B0" />
    </svg>
  );
};
