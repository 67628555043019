/**
 * All ROle List Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderScreen, Loader, Sidebar } from "../../../components";
import { Edit } from "../../../assets/icons/Edit";
import { Delete } from "../../../assets/icons/Delete";
import { AddCircle } from "../../../assets/icons/AddCircle";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import {
  selectAllRoleDataList,
  selectRoleCurrentPage,
  selectRoleDataPerPage,
} from "../selector";
import { toast } from "react-toastify";
import { allRoleList, deleteRole, updateRoleById } from "../slice";
import { AddRoleModel } from "./addRoleScreen";
import { EditRoleModels } from "./editRoleScreen";
import { UserWithCheck } from "../../../assets/icons/UserWithCheck";
import { UserWithKey } from "../../../assets/icons/UserWithKey";
import { AssignedUsers } from "./assignedUsers";
import { MapPermissions } from "./mapPermissionsScreen";
import { Switch } from "@material-tailwind/react";
import { endPoints } from "../../../services";
import DateTableComponent from "../../../components/dataTableComponent";
import {
  createLoader,
  selectLoader,
  selectUserAllPermissions,
} from "../../common";
import DeleteConfirm from "../../../components/deleteConfirm";

const RoleListScreen = () => {
  const dispatch = useDispatch();
  const setLoader = createLoader();
  const { confirm, DeleteConfirmModal } = DeleteConfirm();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAssignedUsersOpen, setIsAssignedUsersOpen] = useState(false);
  const [isMapPermissionsOpen, setIsMapPermissionsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const loader = useSelector(selectLoader);
  const userPermissions = useSelector(selectUserAllPermissions) || {};
  const { data: roleData = [], totalRole } =
    useSelector(selectAllRoleDataList) || {};
  const currentPage = useSelector(selectRoleCurrentPage);
  const limit = useSelector(selectRoleDataPerPage);

  const getData = (searchData, page, perPage) => {
    setLoading(true);
    dispatch(setLoader.present());
    dispatch(
      allRoleList({
        search: searchData,
        page: page,
        perPage: perPage,
        onSuccess: (props) => {
          setLoading(false);
          dispatch(setLoader.dismiss());
          console.log("Role loaded successfully");
        },
      })
    );
  };

  useEffect(() => {
    getData(searchQuery, currentPage, limit);
  }, [searchQuery]);

  const toggleRoleModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const toggleEditModal = (role) => {
    setSelectedRole(role);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleAssignedUsersModal = (role) => {
    setSelectedRole(role);
    setIsAssignedUsersOpen(!isAssignedUsersOpen);
  };

  const toggleMapPermissionsModal = (role) => {
    setSelectedRole(role);
    setIsMapPermissionsOpen(!isMapPermissionsOpen);
  };

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black",
  };
  const columnStyleStatus = {
    justifyContent: "end",
  };

  const columns = [
    {
      name: "Role Name",
      selector: (row) => row?.role_name,
      sortable: true,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Role Type",
      selector: (row) => row?.role_type_display_name,
      sortable: true,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Role Description",
      selector: (row) => row?.role_description,
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Mapped Permissions",
      selector: (row) =>
        row?.permission?.map((item) => item?.permission_display_name),
      sortable: true,
      minWidth: "300px",
      maxWidth: "400px",
      style: columnStyle,
      cell: (row) => (
        <>
          <div className="flex flex-col w-[100%] justify-center items-start space-y-1 py-1">
            {/* Permissions List */}
            <div className="flex gap-2 items-center justify-between px-1 w-full">
              <div className="font-medium">
                {row?.permission
                  ?.map((item) => item?.permission_display_name)
                  ?.slice(0, 3)
                  .join(", ")}
              </div>
              {row?.permission?.length - 3 > 0 && (
                <div className="flex h-[24px] w-[24px] min-w-[24px] justify-center items-center bg-blue-100 text-blue-700 rounded-full align-middle">
                  +{row?.permission?.length - 3}
                </div>
              )}
              {/* Map Permission Button */}
              {userPermissions?.Map_Role_Permission === true && (
                <span
                  className="grid justify-center items-center text-[#8E98A8]"
                  onClick={() => toggleMapPermissionsModal(row)}
                >
                  <UserWithKey width={15} height={16} />
                </span>
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Number of users",
      selector: (row) => row?.role_name,
      sortable: true,
      style: columnStyle,
      maxWidth: "150px",
      cell: (row) => (
        <div className="flex items-center gap-3 w-[100%] py-1 justify-center">
          <div className={""}>{row?.totalAdmin}</div>
          <div
            className={""}
            onClick={() => {
              toggleAssignedUsersModal(row);
            }}
          >
            {userPermissions?.Assign_User_To_Role === true && (
              <div
                className={"flex items-center justify-center cursor-pointer "}
              >
                <UserWithCheck width={19} height={18} />
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      minWidth: "180px",
      maxWidth: "180px",
      sortable: true,
      style: columnStyleStatus,
      cell: (row) => (
        <div className="flex justify-between gap-4">
          <span className="cursor-pointer flex justify-end items-center flex-auto text-gray-tone-900">
            {row.is_active === true ? "Active" : "Inactive"}
          </span>
          {userPermissions?.Activate_Deactivate_Role === true && (
            <div className="flex justify-center items-center">
              <Switch
                id={row.id}
                ripple={false}
                checked={row.is_active === true}
                onChange={() => {
                  const updatedValues = {
                    id: row?._id,
                    is_active: !row.is_active,
                  };
                  dispatch(
                    updateRoleById({
                      updatedValues,
                      onSuccess: (props) => {
                        toast.success(props?.message);
                        getData(searchQuery, currentPage, limit);
                      },
                    })
                  );
                }}
                className="h-full w-full checked:bg-[#B0E0FF]"
                containerProps={{
                  className: "w-8 h-4",
                }}
                circleProps={{
                  className:
                    "before:hidden left-1 border-none w-[12px] h-[12px] drop-shadow-toggle",
                }}
              />
            </div>
          )}
          <div className="flex space-x-2">
            {userPermissions?.Edit_Role === true && (
              <div
                className="cursor-pointer flex justify-center"
                onClick={() => toggleEditModal(row)}
              >
                <Edit width={12} height={12} />
              </div>
            )}
            {userPermissions?.Delete_Role === true && (
              <div
                className="cursor-pointer"
                onClick={async () => {
                  if (userPermissions?.Delete_User === true) {
                    const isConfirmed = await confirm(
                      `Are you sure you want to delete '${row?.role_name}' Role?`
                    );
                    if (isConfirmed) {
                      dispatch(
                        deleteRole({
                          id: row?._id,
                          onSuccess: (props) => {
                            toast.success(props?.message);
                            getData(searchQuery, currentPage, limit);
                          },
                        })
                      );
                    }
                  }
                }}
              >
                <Delete width={12} height={12} />
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="h-screen min-w-screen relative">
      <HeaderScreen />
      <div className="flex h-[calc(100vh-80px)]">
        <Sidebar />
        <div className="flex flex-col h-full bg-customBlue-mainBackground w-[100%] overflow-auto">
          <div className="container min-h-[80px] min-w-full px-6 flex items-center justify-between">
            <div className="font-bold font-raleway text-2xl">
              Role & Permission
            </div>
            <div className="container flex justify-between items-center space-x-4 h-[55%] w-[45%]">
              {/* Search and Filter */}
              <div className="container p-0 flex justify-between items-center w-[70%] ">
                {/* Search Bar */}
                <div className="relative flex-1 rounded-lg h-full ">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none h-full">
                    <SearchIcon width={20} height={20} />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full h-[100%] px-3 py-2 ps-10 text-sm text-gray-tone-100 rounded-lg border border-gray-border-100"
                    placeholder="Search"
                    onChange={(event) => {
                      setSearchQuery(event.target.value);
                    }}
                  />
                </div>
              </div>
              {/* {userPermissions?.Create_Role === true && ( */}
              <div className="">
                <span
                  className="bg-[#3699FF] flex font-semibold h-full items-center justify-center px-3 py-2 rounded-lg space-x-2 text-sm text-white w-full whitespace-nowrap cursor-pointer"
                  onClick={toggleRoleModal}
                >
                  <AddCircle width={18} height={18} />
                  <span>Create New Role</span>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between ">
            {/* Data Table */}
            <div className="px-6 overflow-y-auto scrollbar-hide theme-table">
              <DateTableComponent
                searchQuery={searchQuery}
                apiColumn={columns}
                data={roleData}
                totalRow={totalRole}
                loading={loading}
                getData={getData}
                currentPages={currentPage}
                limit={limit}
                sortIcon={<SortIcon />}
                backgroundColor={"#DDF1FE"}
                selectableRows={false}
                pagination={true}
              />
            </div>
          </div>
        </div>
        {/* )} */}
      </div>

      {/* Modals */}
      {isAddModalOpen && (
        <AddRoleModel toggleModal={toggleRoleModal} getData={getData} />
      )}

      {isEditModalOpen && (
        <EditRoleModels
          role={selectedRole}
          toggleModal={toggleEditModal}
          getData={getData}
        />
      )}

      {isAssignedUsersOpen && (
        <AssignedUsers
          role={selectedRole}
          toggleModal={toggleAssignedUsersModal}
          getData={getData}
        />
      )}

      {isMapPermissionsOpen && (
        <MapPermissions
          role={selectedRole}
          toggleModal={toggleMapPermissionsModal}
          getData={getData}
        />
      )}
      {DeleteConfirmModal}
    </div>
  );
};

export { RoleListScreen };
