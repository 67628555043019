import React from "react";

export const Hourglass = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2084_455)">
        <circle cx="16.5" cy="16.0475" r="15.5" fill="white" stroke="#0162DD" />
        <path
          d="M16.6828 16.8315L16.5458 16.742L16.4088 16.832C15.5728 17.3805 14.1308 18.547 13.8663 20.2L13.8198 20.4895H19.2583L19.2128 20.2005C18.9508 18.5355 17.5153 17.375 16.6828 16.8315ZM14.4218 19.9895C14.7483 18.756 15.8243 17.8375 16.5468 17.3415C17.2658 17.8335 18.3363 18.7475 18.6593 19.9895H14.4218ZM18.3958 15.9895C20.1428 14.5265 21.0188 12.9285 21.0003 11.237C20.9928 10.555 20.4318 10 19.7493 10L13.2483 10.002C12.5683 10.002 12.0083 10.556 12.0003 11.2365C11.9808 12.9345 12.8563 14.532 14.6038 15.9895C12.8758 17.431 12.0003 19.0125 12.0003 20.694V21.9895H21.0003V20.694C21.0003 19.019 20.1243 17.4375 18.3958 15.9895ZM20.5003 21.4895H12.5003V20.694C12.5003 19.0965 13.3938 17.5795 15.1553 16.1855L15.4033 15.989L15.1553 15.7925C13.3748 14.384 12.4818 12.8525 12.5003 11.2415C12.5048 10.8335 12.8408 10.5015 13.2483 10.5015L19.7493 10.4995C20.1588 10.4995 20.4958 10.8325 20.5003 11.2415C20.5178 12.8465 19.6248 14.378 17.8443 15.793L17.5983 15.989L17.8443 16.185C19.6063 17.5865 20.5003 19.103 20.5003 20.694V21.4895Z"
          fill="#0162DD"
          stroke="#0162DD"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
