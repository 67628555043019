import React from "react";

export const ArrowIcon = ({ width, height, isRotated, fillColor }) => {
  return (
    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 8 12"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={isRotated ? "rotate-90" : ""}
    // >
    //   <path
    //     d="M1.10543 11.2362L1.10554 11.2363C1.43794 11.5687 1.98296 11.5668 2.30341 11.2463L6.89341 6.65633L6.89352 6.65622C6.97226 6.57764 7.03473 6.4843 7.07736 6.38155C7.11998 6.27879 7.14192 6.16864 7.14192 6.0574C7.14192 5.94615 7.11998 5.836 7.07736 5.73325C7.03473 5.63049 6.97226 5.53715 6.89352 5.45857L6.89341 5.45846L2.30341 0.868461C2.14456 0.709615 1.92912 0.620376 1.70448 0.620376C1.47983 0.620376 1.26439 0.709615 1.10554 0.868461C0.946695 1.02731 0.857455 1.24275 0.857455 1.4674C0.857455 1.69204 0.946694 1.90748 1.10554 2.06633L4.98554 5.94633L5.09161 6.0524L4.98554 6.15846L1.10554 10.0385L1.10543 10.0386C1.02669 10.1172 0.964219 10.2105 0.921595 10.3132C0.878971 10.416 0.857031 10.5262 0.857031 10.6374C0.857031 10.7486 0.878971 10.8588 0.921595 10.9615C0.964219 11.0643 1.02669 11.1576 1.10543 11.2362Z"
    //     fill="#181818"
    //     stroke="#181818"
    //   />
    // </svg>
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isRotated ? "rotate-90" : ""}
    >
      <path
        d="M0.398401 11.5395L0.39851 11.5396C0.73091 11.872 1.27593 11.8701 1.59638 11.5496L6.18638 6.95962L6.18649 6.95952C6.26523 6.88094 6.3277 6.7876 6.37032 6.68484C6.41295 6.58209 6.43489 6.47194 6.43489 6.36069C6.43489 6.24945 6.41295 6.13929 6.37032 6.03654C6.3277 5.93379 6.26523 5.84045 6.18649 5.76187L6.18638 5.76176L1.59638 1.17176C1.43753 1.01291 1.22209 0.923671 0.997444 0.923671C0.7728 0.923671 0.557357 1.01291 0.39851 1.17176C0.239663 1.3306 0.150423 1.54605 0.150423 1.77069C0.150423 1.99534 0.239663 2.21078 0.39851 2.36963L4.27851 6.24962L4.38458 6.35569L4.27851 6.46176L0.39851 10.3418L0.398401 10.3419C0.319659 10.4204 0.257188 10.5138 0.214564 10.6165C0.17194 10.7193 0.15 10.8294 0.15 10.9407C0.15 11.0519 0.17194 11.1621 0.214564 11.2648C0.257188 11.3676 0.319659 11.4609 0.398401 11.5395Z"
        fill={fillColor}
        stroke="#DDF1FE"
        strokeWidth="0.3"
      />
    </svg>
  );
};
