/**
 * main index file of project
 * @format
 */

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./styles/css/index.css";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./redux";
import AppRouter from "./routes";
import "react-circular-progressbar/dist/styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <AppRouter />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
