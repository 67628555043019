/**
 * All Camp list Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { AssignBeneficiariesForCampModel } from "./assignBeneficiariesScreen";
import { AssignUserForCampModel } from "./assignUserScreen";
import { AddCampModel } from "./addCampScreen";
import { ListCampBeneficiary } from "./campBeneficiaryListScreen";
import { SetupStepFlowModel } from "./campFlowSetupScreen";
import { InstructionOfCampModel } from "./instructionsOfCampScreen";
import { HeaderScreen, Loader, Sidebar } from "../../../components";
import { AddCircle } from "../../../assets/icons/AddCircle";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { Dots } from "../../../assets/icons/Dots";
import NavigationBar from "../../beneficiary_management/components/navigationBar";
import { EditScheduleCampModel } from "./editCampScreen";
import {
  selectAllScheduleCampList,
  selectAllStateWithScheduleCampCount,
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
  selectStateIdForScheduleCamp,
} from "../selector";
import { useSelector } from "react-redux";
import {
  allScheduleCampList,
  getallStateWithScheduleCampCount,
  setScheduleCampSearchQuery,
  setStateIdForScheduleCamp,
} from "../slice";
import { NoRecord } from "../../../components/noRecords";
import { formatDate } from "../../../utils";
import {
  createLoader,
  selectLoader,
  selectUserAllPermissions,
} from "../../common";

const ListCampModel = () => {
  const dispatch = useDispatch();
  const setLoader = createLoader();
  const [selectedRow, setSelectedRow] = useState({});
  const [activeState, setActiveState] = useState("");
  const [dataTableLoading, setDataTableLoading] = useState(false);

  const loader = useSelector(selectLoader);
  const stateId = useSelector(selectStateIdForScheduleCamp) || "";
  const stateWithScheduleCampCount =
    useSelector(selectAllStateWithScheduleCampCount) || [];
  const userPermissions = useSelector(selectUserAllPermissions) || {};
  const {
    data = [],
    overAllCamps,
    totalUpcomingCamps,
    totalPastCamps,
    totalLiveCamps,
  } = useSelector(selectAllScheduleCampList) || {};
  const currentPage = useSelector(selectScheduleCampCurrentPage);
  const perPage = useSelector(selectScheduleCampRespPerPage);
  const filter = useSelector(selectScheduleCampFilter);
  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);

  const [isAssignedUsersModelOpen, setIsAssignedUsersModelOpen] =
    useState(false);
  const [isAssignBeneficiariesModelOpen, setIsAssignBeneficiariesModelOpen] =
    useState(false);
  const [isInstructionCampModelOpen, setIsInstructionCampModelOpen] =
    useState(false);
  const [isStepsFlowCampModelOpen, setIsStepsFlowCampModelOpen] =
    useState(false);
  const [isAddCampModalOpen, setIsAddCampModalOpen] = useState(false);
  const [isEditScheduleCampModalOpen, setIsEditScheduleCampModalOpen] =
    useState(false);

  const toggleAddModal = () => {
    setIsAddCampModalOpen(!isAddCampModalOpen);
  };

  const toggleEditScheduleCampModal = (row) => {
    setIsEditScheduleCampModalOpen(!isEditScheduleCampModalOpen);
    setSelectedRow(row);
  };

  const toggleAssignedUsersModal = (row) => {
    setIsAssignedUsersModelOpen(!isAssignedUsersModelOpen);
    setSelectedRow(row);
  };

  const toggleAssignBeneficiariesInCampModal = (row) => {
    setIsAssignBeneficiariesModelOpen(!isAssignBeneficiariesModelOpen);
    setSelectedRow(row);
  };

  const toggleInstructionCampModal = (row) => {
    setIsInstructionCampModelOpen(!isInstructionCampModelOpen);
    setSelectedRow(row);
  };

  const toggleStepsFlowModal = (row) => {
    setIsStepsFlowCampModelOpen(!isStepsFlowCampModelOpen);
    setSelectedRow(row);
  };

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black",
  };

  const columns = [
    {
      name: "State",
      selector: (row) => row?.state?.state_name,
      style: columnStyle,
      maxWidth: "150px",
      backgroundColor: "black",
    },
    {
      name: "Center",
      selector: (row) => row?.camp_location_id?.camp_location_name,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Camp Code",
      selector: (row) => row?.camp_code,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Camp Type",
      selector: (row) => row?.camp_type_id?.camp_type_display_name,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Date",
      selector: (row) => formatDate(row?.from_date),
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Time",
      selector: (row) => row?.from_time,
      style: columnStyle,
      minWidth: "200px",
      cell: (row) => (
        <>
          <div className="w-full">
            {row?.from_time} - {row?.to_time}
          </div>
        </>
      ),
    },
    {
      name: "No. of Beneficiaries",
      selector: (row) => row?.max_numbers,
      style: columnStyle,
      cell: (row) => (
        <>
          <div className="w-full">
            {row?.stepStatus?.beneficiaryCount || 0}/{row?.max_numbers}
          </div>
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      maxWidth: "180px",

      cell: (row) => (
        <Menu>
          <MenuHandler>
            <span className="px-4 py-2 cursor-pointer min-w-4">
              {" "}
              <Dots></Dots>{" "}
            </span>
          </MenuHandler>
          <MenuList className="p-0 text-black rounded-none">
            {userPermissions?.Edit_Scheduled_Camps === true && (
              <MenuItem
                className="border-b border-[#E6E6E6] rounded-none p-3"
                onClick={() => {
                  toggleEditScheduleCampModal(row);
                }}
              >
                Edit
              </MenuItem>
            )}
            <MenuItem
              className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
              onClick={() => {
                toggleInstructionCampModal(row);
              }}
            >
              {" "}
              <div
                className={`h-[8px] w-[8px] rounded-2xl ${
                  row?.stepStatus?.Instructions
                    ? "bg-[#4ADB06]"
                    : "bg-[#FC5454]"
                }`}
              ></div>{" "}
              Instructions
            </MenuItem>
            {userPermissions?.Setup_Camp_Flow === true && (
              <MenuItem
                className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
                onClick={() => {
                  toggleStepsFlowModal(row);
                }}
              >
                {" "}
                <div
                  className={`h-[8px] w-[8px] rounded-2xl ${
                    row?.stepStatus?.CampFlowSetup
                      ? "bg-[#4ADB06]"
                      : "bg-[#FC5454]"
                  }`}
                ></div>{" "}
                Camp Flow Setup
              </MenuItem>
            )}
            {userPermissions?.Assign_Users_To_Camp === true && (
              <MenuItem
                className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
                onClick={() => {
                  toggleAssignedUsersModal(row);
                }}
              >
                {" "}
                <div
                  className={`h-[8px] w-[8px] rounded-2xl ${
                    row?.stepStatus?.AssignUser
                      ? "bg-[#4ADB06]"
                      : "bg-[#FC5454]"
                  }`}
                ></div>{" "}
                Assign Users
              </MenuItem>
            )}
            {userPermissions?.Assign_Beneficiaries_To_Camp === true && (
              <MenuItem
                className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
                onClick={() => {
                  toggleAssignBeneficiariesInCampModal(row);
                }}
              >
                {" "}
                <div
                  className={`h-[8px] w-[8px] rounded-2xl ${
                    row?.stepStatus?.AssignBeneficiaries
                      ? "bg-[#4ADB06]"
                      : "bg-[#FC5454]"
                  }`}
                ></div>{" "}
                Assign Beneficiaries
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      ),

      button: true,
    },
  ];

  // functions
  const getData = (searchData, filter, page, perPage, stateId) => {
    setDataTableLoading(true);
    dispatch(
      allScheduleCampList({
        search: searchData,
        filter: filter,
        page: page,
        perPage: perPage,
        stateId,
        onSuccess: (props) => {
          setDataTableLoading(false);
        },
      })
    );
  };

  const getStateWithScheduleCampCount = () => {
    dispatch(
      getallStateWithScheduleCampCount({
        onSuccess: (props) => {},
      })
    );
  };

  useEffect(() => {
    getData(scheduleCampSearchQuery, filter, currentPage, perPage, stateId);
    getStateWithScheduleCampCount();
  }, [scheduleCampSearchQuery, perPage, currentPage, stateId]);

  return (
    <>
      <div className="h-screen min-w-screen relative">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-80px)]">
          <Sidebar />
          <div className="flex flex-col h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            <div className="container min-h-[80px] min-w-full px-6 flex items-center justify-between">
              <div className="font-bold font-raleway text-2xl">
                Scheduled Camps
              </div>
              <div className="container flex justify-between items-center space-x-4 h-[55%] w-[45%]">
                {/* Search and Filter */}
                <div className="container p-0 flex justify-between items-center w-[70%] ">
                  {/* Search Bar */}
                  <div className="relative flex-1 rounded-lg h-full ">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none h-full">
                      <SearchIcon width={20} height={20} />
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full h-[100%] px-3 py-2 ps-10 text-sm text-gray-tone-100 rounded-lg border border-gray-border-100"
                      placeholder="Search"
                      onChange={(event) => {
                        dispatch(
                          setScheduleCampSearchQuery(event.target.value)
                        );
                        // setSearchQuery(event.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* {userPermissions?.Create_Schedule_Camps === true && ( */}
                <div className="h-full">
                  <div
                    className="container cursor-pointer flex flex-row px-2 justify-around rounded-lg items-center gap-2 h-[100%] w-[100%] bg-[#3699FF] text-white text-sm font-semibold"
                    onClick={() => {
                      toggleAddModal();
                    }}
                  >
                    <span className="flex-shrink-0">
                      <AddCircle width={18} height={18} />
                    </span>
                    <span className="text-sm font-semibold truncate">
                      Schedule Camp
                    </span>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="flex flex-col justify-between px-6 space-y-4">
              <div className="">
                <NavigationBar
                  statuses={[
                    `All(${overAllCamps || 0})`,
                    `Today's Camps(${totalLiveCamps || 0})`,
                    `Upcoming Camps(${totalUpcomingCamps || 0})`,
                    `Past camps(${totalPastCamps || 0})`,
                  ]}
                  getData={getData}
                  // beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                  barName="scheduleCamp"
                />
              </div>
              <div className="flex flex-row justify-between space-x-2 px-0">
                <div className="">
                  <div className="flex flex-row text-sm text-[#666666] font-bold min-h-[52px] min-w-[150px] bg-[#DDF1FE] items-center p-4">
                    State
                  </div>
                  <div className="text-sm font-medium min-h-[52px] min-w-[220px] max-h-[calc(100vh-215px)] overflow-auto">
                    {stateWithScheduleCampCount?.map((item, index) => (
                      <div
                        key={index}
                        className={`flex flex-row text-sm min-h-[52px] min-w-[150px] border-b-2 items-center p-4 cursor-pointer ${
                          activeState === item?.state_id
                            ? "font-bold bg-[#F4FBFF]"
                            : "font-medium bg-[#ffffff]"
                        }`}
                        onClick={() => {
                          setActiveState(item?.state_id);
                          dispatch(setStateIdForScheduleCamp(item?.state_id));
                        }}
                      >
                        {item?.state_name}
                        <div className="w-full text-right">{item?.count}</div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Data Table */}
                <div className="w-full theme-table">
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={dataTableLoading}
                    progressComponent={
                      <div className="h-[400px] w-full flex justify-center items-center bg-customBlue-mainBackground">
                        <Loader />
                      </div>
                    }
                    customStyles={{
                      headCells: {
                        style: {
                          backgroundColor: "#DDF1FE",
                          color: "Black",
                          fontWeight: "bold",
                          fontSize: "14px",
                        },
                      },
                    }}
                    sortIcon={<SortIcon />}
                    noDataComponent={
                      <div className="h-full py-16">
                        <NoRecord
                          title_1={"There are no records to display"}
                          title_2={""}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}

        {isAddCampModalOpen && (
          <AddCampModel
            toggleModal={toggleAddModal}
            getData={getData}
            getStateWithScheduleCampCount={getStateWithScheduleCampCount}
          />
        )}

        {isAssignedUsersModelOpen && (
          <AssignUserForCampModel
            toggleModal={toggleAssignedUsersModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isAssignBeneficiariesModelOpen && (
          <AssignBeneficiariesForCampModel
            toggleModal={toggleAssignBeneficiariesInCampModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isStepsFlowCampModelOpen && (
          <SetupStepFlowModel
            toggleModal={toggleStepsFlowModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isInstructionCampModelOpen && (
          <InstructionOfCampModel
            toggleModal={toggleInstructionCampModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isEditScheduleCampModalOpen && (
          <EditScheduleCampModel
            toggleModal={toggleEditScheduleCampModal}
            ScheduleCampId={selectedRow?._id}
            getData={getData}
          />
        )}
      </div>
    </>
  );
};

export { ListCampModel };
