import React from "react";

export const UserWithKey = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.82341 13.5085H1.79007C1.75474 13.5085 1.72074 13.4939 1.69607 13.4692C1.67074 13.4439 1.65674 13.4105 1.65674 13.3752C1.65674 13.3745 1.65674 12.4085 1.65674 12.4085C1.65674 11.8545 2.05207 11.3665 2.66141 10.9519C3.7494 10.2092 5.51007 9.74121 7.49007 9.74121C7.81674 9.74121 8.13741 9.75454 8.45074 9.77921C8.7254 9.80121 8.96674 9.59588 8.98874 9.32054C9.01007 9.04521 8.80474 8.80454 8.52941 8.78254C8.19074 8.75521 7.84407 8.74121 7.49007 8.74121C5.2754 8.74121 3.31474 9.29521 2.0974 10.1252C1.16874 10.7585 0.656738 11.5632 0.656738 12.4079V13.3752C0.656738 13.6752 0.776072 13.9639 0.988738 14.1765C1.2014 14.3885 1.4894 14.5085 1.79007 14.5079C3.13407 14.5085 6.82341 14.5085 6.82341 14.5085C7.09941 14.5085 7.32341 14.2839 7.32341 14.0085C7.32341 13.7325 7.09941 13.5085 6.82341 13.5085Z"
        fill="#8E98A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48991 0.833252C5.46591 0.833252 3.82324 2.47592 3.82324 4.49992C3.82324 6.52392 5.46591 8.16659 7.48991 8.16659C9.51391 8.16659 11.1566 6.52392 11.1566 4.49992C11.1566 2.47592 9.51391 0.833252 7.48991 0.833252ZM7.48991 1.83325C8.96191 1.83325 10.1566 3.02792 10.1566 4.49992C10.1566 5.97192 8.96191 7.16659 7.48991 7.16659C6.01791 7.16659 4.82324 5.97192 4.82324 4.49992C4.82324 3.02792 6.01791 1.83325 7.48991 1.83325Z"
        fill="#8E98A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9249 13.472C12.7329 13.5933 13.5869 13.3427 14.2089 12.7207C15.2502 11.6793 15.2502 9.98866 14.2089 8.94799C13.1682 7.90666 11.4776 7.90666 10.4362 8.94799C9.81423 9.56999 9.56357 10.424 9.6849 11.2327L8.1369 12.78C8.0429 12.874 7.99023 13.0013 7.99023 13.134V14.6667C7.99023 14.9427 8.21423 15.1667 8.49023 15.1667H10.0229C10.1556 15.1667 10.2829 15.114 10.3769 15.02L11.9249 13.472ZM11.8902 12.4453C11.7182 12.3993 11.5342 12.4487 11.4082 12.5747L9.81623 14.1667H8.99023V13.3407L10.5822 11.7487C10.7082 11.6227 10.7576 11.4387 10.7116 11.2667C10.5636 10.71 10.7069 10.0913 11.1436 9.65466C11.7942 9.00399 12.8509 9.00399 13.5022 9.65466C14.1529 10.306 14.1529 11.3627 13.5022 12.0133C13.0656 12.45 12.4469 12.5933 11.8902 12.4453Z"
        fill="#8E98A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8116 11.3453C11.5296 11.0633 11.5296 10.6053 11.8116 10.3233C12.0936 10.0413 12.5516 10.0413 12.8336 10.3233C13.1156 10.6053 13.1156 11.0633 12.8336 11.3453C12.5516 11.6273 12.0936 11.6273 11.8116 11.3453Z"
        fill="#8E98A8"
      />
    </svg>
  );
};
