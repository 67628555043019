import React from "react";

export const Graduation = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3119 12.1913V7.00063L18.46 6.91438C18.7744 6.73188 18.9619 6.40625 18.9619 6.04313C18.9619 5.68 18.7744 5.35375 18.46 5.17125L10.8619 0.757504C10.2225 0.385004 9.42688 0.385629 8.7875 0.757504L1.18875 5.17188C0.875 5.35438 0.6875 5.68 0.6875 6.04313C0.6875 6.40625 0.875 6.73188 1.18875 6.915L3.4925 8.25375V11.3656C3.4925 12.0881 3.88125 12.76 4.50625 13.1206L7.6775 14.9488C8.33938 15.33 9.08187 15.5213 9.82438 15.5213C10.5669 15.5213 11.3087 15.3306 11.9712 14.9488L15.1419 13.1206C15.7675 12.7606 16.1562 12.0881 16.1562 11.3656V8.25313L17.1869 7.65438V12.1913C16.9225 12.3713 16.7488 12.6744 16.7488 13.0188C16.7488 13.5713 17.1969 14.0194 17.7494 14.0194C18.3019 14.0194 18.75 13.5713 18.75 13.0188C18.75 12.675 18.5763 12.3719 18.3119 12.1913ZM15.0319 11.365H15.0312C15.0312 11.6863 14.8594 11.985 14.5806 12.145L11.41 13.9731C10.4325 14.5363 9.21688 14.5363 8.24 13.9731L5.06875 12.145C4.79062 11.985 4.61812 11.6863 4.61812 11.365V8.90688L8.7875 11.3294C9.1075 11.515 9.46625 11.6081 9.825 11.6081C10.1838 11.6081 10.5425 11.5156 10.8625 11.3294L15.0319 8.90688V11.365ZM10.2969 10.3569C10.0056 10.5263 9.64375 10.5263 9.3525 10.3569L1.92813 6.04313L9.35188 1.72938C9.64312 1.56 10.005 1.56 10.2963 1.72938L17.7206 6.0425L10.2969 10.3569Z" fill="#3B395F"/>
      </svg>
  );
};
