import React from "react";

export const User = ({ width, height }) => {
  return (
    <svg   width={width}
    height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.9375 8.4375C5.61128 8.4375 3.71875 6.54497 3.71875 4.21875C3.71875 1.89253 5.61128 0 7.9375 0C10.2637 0 12.1562 1.89253 12.1562 4.21875C12.1562 6.54497 10.2637 8.4375 7.9375 8.4375ZM7.9375 1.25C6.30053 1.25 4.96875 2.58178 4.96875 4.21875C4.96875 5.85572 6.30053 7.1875 7.9375 7.1875C9.57447 7.1875 10.9062 5.85572 10.9062 4.21875C10.9062 2.58178 9.57447 1.25 7.9375 1.25ZM8 14.4375C7.56853 14.4375 7.21875 14.7873 7.21875 15.2188C7.21875 15.6502 7.56853 16 8 16C8.43147 16 8.78125 15.6502 8.78125 15.2188C8.78125 14.7873 8.43147 14.4375 8 14.4375ZM11.3125 15.375C11.3125 13.5485 9.8265 12.0625 8 12.0625C6.1735 12.0625 4.6875 13.5485 4.6875 15.375C4.6875 15.7202 4.96731 16 5.3125 16C5.65769 16 5.9375 15.7202 5.9375 15.375C5.9375 14.2377 6.86272 13.3125 8 13.3125C9.13728 13.3125 10.0625 14.2377 10.0625 15.375C10.0625 15.7202 10.3423 16 10.6875 16C11.0327 16 11.3125 15.7202 11.3125 15.375ZM14.1562 15.375C14.1562 12.2389 11.6048 9.6875 8.46875 9.6875H7.53125C4.39516 9.6875 1.84375 12.2389 1.84375 15.375C1.84375 15.7202 2.12356 16 2.46875 16C2.81394 16 3.09375 15.7202 3.09375 15.375C3.09375 12.9282 5.08441 10.9375 7.53125 10.9375H8.46875C10.9156 10.9375 12.9062 12.9282 12.9062 15.375C12.9062 15.7202 13.1861 16 13.5312 16C13.8764 16 14.1562 15.7202 14.1562 15.375Z" fill="black"/>
    </svg>
    
  );
};
