/**
 * Axios 
 * Setup common url for api
 * @format
 */

import axios from "axios";

import endPoints from "./endpoints";
import { store } from "../redux";

const baseURL = process.env.REACT_APP_BASE_URL;
const apiTimeout = parseInt(process.env.REACT_APP_API_TIMEOUT);

const httpRequest = axios.create({
  baseURL: baseURL,
  timeout: apiTimeout,
});

httpRequest.interceptors.request.use(
  (config) => {
    const token = store.getState().common.authToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (err) => Promise.reject(err)
);

httpRequest.interceptors.response.use(
  (res) => {
    if (res?.data?.error) {
      return Promise.reject(res?.data?.error);
    }
    return res.data;
  },
  (err) => Promise.reject(err)
);

export { httpRequest, endPoints };
