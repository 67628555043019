/**
 * Auth sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { createLoader, setAuthToken, setUser } from "../common";
import { login, reSendOTP, sendOTP } from "./slice";
import { endPoints, httpRequest } from "../../services";

/**
 * Login with Mobile Number and OTP
 * @param {*} MobileNumber
 * @param {*} OTP
 */
function* loginSaga(action) {
  try {
    const { mobile_number, otp, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.Login, {
      mobile_number,
      otp,
    });
    yield put(setAuthToken(response?.token?.accessToken));
    yield put(setUser(response?.user));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Send OTP from  Mobile Number
 * @param {*} MobileNumber
 */
function* sendOTPSaga(action) {
  try {
    const { mobile_number, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.SendOtp}/${mobile_number}`
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    console.log({ error });
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("payload...done OTP.......");
  }
}

/**
 * ReSend OTP from  Mobile Number
 * @param {*} MobileNumber
 */
function* ReSendOTPSaga(action) {
  console.log(action);

  try {
    const { mobile_number, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.ReSendOtp}/${mobile_number}`
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    console.log({ error });
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("payload..Re.done OTP.......");
  }
}

export function* authSaga() {
  yield takeLatest(login, loginSaga);
  yield takeLatest(sendOTP, sendOTPSaga);
  yield takeLatest(reSendOTP, ReSendOTPSaga);
}
