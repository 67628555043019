/**
 * beneficiary Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { endPoints, httpRequest } from "../../services";
import {
  allBeneficiaryDetailList,
  allBeneficiaryDetailListByStatus,
  allBeneficiaryDetailListForAssessment,
  allBeneficiaryDetailListForDistribution,
  allBeneficiaryDetailListForFabrication,
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
  setAllBeneficiaryDetailDataList,
  setAllBeneficiaryDetailListForAssessments,
  setAllBeneficiaryDetailListForDistribution,
  setAllBeneficiaryDetailListForFabrication,
  setBeneficiaryDetails,
  setBeneficiaryProcessDetail,
  updateVerifyBeneficiary,
  verifyBeneficiary,
} from "./slice";

/**
 * Get all Beneficiary Detail list
 * @param {*}
 */
function* allBeneficiaryDetailListSaga(action) {
  try {
    const {
      search,
      page,
      stateId,
      districtId,
      status,
      perPage,
      onSuccess,
      filter,
    } = action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsList,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Beneficiary list For Assessment
 * @param {*}
 */
function* allBeneficiaryDetailListForAssessmentSaga(action) {
  try {
    const {
      search,
      page,
      stateId,
      districtId,
      status,
      perPage,
      onSuccess,
      filter,
    } = action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListAssessment,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailListForAssessments(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Beneficiary Detail list Fro Fabrication
 * @param {*}
 */
function* allBeneficiaryDetailListForFabricationSaga(action) {
  try {
    const {
      search,
      page,
      stateId,
      districtId,
      status,
      perPage,
      onSuccess,
      filter,
    } = action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListFabrication,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailListForFabrication(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Beneficiary Detail list For Distribution
 * @param {*}
 */
function* allBeneficiaryDetailListForDistributionSaga(action) {
  try {
    const {
      search,
      page,
      stateId,
      districtId,
      status,
      perPage,
      onSuccess,
      filter,
    } = action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListDistribution,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailListForDistribution(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Beneficiary Detail list By status
 * @param {*}
 */
function* allBeneficiaryDetailListByStatusSaga(action) {
  try {
    const { search, page, stateId, districtId, status, perPage, onSuccess } =
      action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListStatus,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Beneficiary Detail By Id
 * @param {*} Id
 */
function* getBeneficiaryDetailByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getBeneficiaryDetailById}/${id}`
    );
    yield onSuccess({ message: response?.message });
    yield put(setBeneficiaryDetails(response?.data));
    if (response?.data?.status === "Pending") {
      yield put(setBeneficiaryProcessDetail({}));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Beneficiary Detail By Id
 * @param {*} Id
 */
function* getBeneficiaryProcessDataByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.beneficiaryJourneyByBeneficiaryId}/${id}`
    );
    yield onSuccess({ message: response?.message, data: response?.data });
    yield put(setBeneficiaryProcessDetail(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Beneficiary Verification By User
 * @param {*} Id
 */
function* verifyBeneficiarySaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { beneficiary_id, beneficiary_stage_id, notes, call_status, status } =
      values;
    const verification = {
      beneficiary_stage_id,
      status_id: status,
      data: [{ call_status, notes }],
    };
    const response = yield httpRequest.post(endPoints.verifyBeneficiaryData, {
      beneficiary_id,
      beneficiary_status: verification,
    });
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Beneficiary Verification Update By User
 * @param {*} Id
 */
function* verifyUpdateBeneficiarySaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { beneficiary_id, beneficiary_stage_id, notes, call_status, status } =
      values;
    const verification = {
      beneficiary_stage_id,
      status_id: status,
      data: [{ call_status, notes }],
    };
    const response = yield httpRequest.post(
      endPoints.updateVerifyBeneficiaryData,
      {
        beneficiary_id,
        beneficiary_status: verification,
      }
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* beneficiaryDetailManagementSaga() {
  yield takeLatest(allBeneficiaryDetailList, allBeneficiaryDetailListSaga);
  yield takeLatest(
    allBeneficiaryDetailListForAssessment,
    allBeneficiaryDetailListForAssessmentSaga
  );
  yield takeLatest(
    allBeneficiaryDetailListForFabrication,
    allBeneficiaryDetailListForFabricationSaga
  );
  yield takeLatest(
    allBeneficiaryDetailListForDistribution,
    allBeneficiaryDetailListForDistributionSaga
  );
  yield takeLatest(
    allBeneficiaryDetailListByStatus,
    allBeneficiaryDetailListByStatusSaga
  );
  yield takeLatest(getBeneficiaryDetailById, getBeneficiaryDetailByIdSaga);
  yield takeLatest(verifyBeneficiary, verifyBeneficiarySaga);
  yield takeLatest(
    getBeneficiaryProcessDetails,
    getBeneficiaryProcessDataByIdSaga
  );
  yield takeLatest(updateVerifyBeneficiary, verifyUpdateBeneficiarySaga);
}
