import React from "react";

export const BloodGroup = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.37106 19.1633C5.49534 19.1907 4.62383 19.0312 3.81456 18.6954C3.0053 18.3597 2.27684 17.8554 1.67771 17.2161C1.08915 16.5148 0.656378 15.6964 0.408093 14.8152C0.159808 13.9339 0.101678 13.01 0.237556 12.1046C0.644326 8.44623 5.74804 1.33016 5.96526 1.0289C6.01379 0.96781 6.07549 0.918464 6.14576 0.884544C6.21602 0.850625 6.29304 0.833008 6.37106 0.833008C6.44908 0.833008 6.5261 0.850625 6.59636 0.884544C6.66663 0.918464 6.72833 0.96781 6.77686 1.0289C6.99408 1.33016 12.0978 8.44623 12.5042 12.1046C12.6401 13.01 12.582 13.9343 12.3337 14.8155C12.0854 15.6967 11.6526 16.5151 11.0641 17.2164C10.4649 17.8556 9.73651 18.3598 8.92732 18.6955C8.11812 19.0312 7.2467 19.1907 6.37106 19.1633ZM6.37106 2.19287C5.14521 3.9688 1.54646 9.38581 1.23218 12.2153C1.10821 12.9808 1.15041 13.7641 1.35591 14.5119C1.56142 15.2596 1.92541 15.9544 2.42318 16.5491C2.92917 17.0826 3.54286 17.5025 4.22352 17.7807C4.90417 18.0589 5.63621 18.1892 6.37106 18.1628C7.10581 18.1892 7.83776 18.0589 8.51831 17.7807C9.19886 17.5025 9.81244 17.0826 10.3183 16.5491C10.8163 15.9545 11.1805 15.2596 11.3861 14.5118C11.5918 13.764 11.6341 12.9806 11.5103 12.215C11.1957 9.38581 7.59691 3.9688 6.37106 2.19287Z"
        fill="#3B395F"
        stroke="#3B395F"
        strokeWidth="0.2"
      />
      <path
        d="M6.37134 11.8266C6.23867 11.8266 6.11146 11.7739 6.01765 11.6801C5.92384 11.5863 5.87113 11.4591 5.87109 11.3264V8.65845C5.87109 8.52577 5.9238 8.39853 6.01761 8.30472C6.11143 8.21091 6.23866 8.1582 6.37134 8.1582C6.50401 8.1582 6.63125 8.21091 6.72506 8.30472C6.81887 8.39853 6.87158 8.52577 6.87158 8.65845V11.3264C6.87154 11.4591 6.81883 11.5863 6.72502 11.6801C6.63122 11.7739 6.504 11.8266 6.37134 11.8266Z"
        fill="#3B395F"
      />
      <path
        d="M7.70531 10.4927H5.03735C4.90468 10.4927 4.77744 10.44 4.68363 10.3462C4.58981 10.2523 4.53711 10.1251 4.53711 9.99243C4.53711 9.85976 4.58981 9.73252 4.68363 9.63871C4.77744 9.54489 4.90468 9.49219 5.03735 9.49219H7.70531C7.83798 9.49219 7.96522 9.54489 8.05904 9.63871C8.15285 9.73252 8.20555 9.85976 8.20555 9.99243C8.20555 10.1251 8.15285 10.2523 8.05904 10.3462C7.96522 10.44 7.83798 10.4927 7.70531 10.4927Z"
        fill="#3B395F"
      />
      <path
        d="M7.70531 14.8276H5.03735C4.90468 14.8276 4.77744 14.7749 4.68363 14.6811C4.58981 14.5873 4.53711 14.4601 4.53711 14.3274C4.53711 14.1947 4.58981 14.0675 4.68363 13.9737C4.77744 13.8799 4.90468 13.8271 5.03735 13.8271H7.70531C7.83798 13.8271 7.96522 13.8799 8.05904 13.9737C8.15285 14.0675 8.20555 14.1947 8.20555 14.3274C8.20555 14.4601 8.15285 14.5873 8.05904 14.6811C7.96522 14.7749 7.83798 14.8276 7.70531 14.8276Z"
        fill="#3B395F"
      />
    </svg>
  );
};
