import React from "react";

export const RightArrow = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.266469 10.0472C0.181999 9.9677 0.114984 9.87322 0.0692601 9.76921C0.0235359 9.6652 0 9.5537 0 9.4411C0 9.3285 0.0235359 9.217 0.0692601 9.11299C0.114984 9.00898 0.181999 8.9145 0.266469 8.83496L3.80184 5.49906L0.266469 2.16315C0.0960988 2.00239 0.000385821 1.78436 0.000385821 1.55701C0.000385821 1.32967 0.0960988 1.11163 0.266469 0.950873C0.436838 0.790115 0.667909 0.699804 0.908848 0.699804C1.14979 0.699804 1.38086 0.790115 1.55123 0.950873L5.73353 4.89722C5.818 4.97676 5.88502 5.07124 5.93074 5.17525C5.97646 5.27925 6 5.39075 6 5.50335C6 5.61596 5.97646 5.72745 5.93074 5.83146C5.88502 5.93547 5.818 6.02995 5.73353 6.10949L1.55123 10.0558C1.20498 10.3825 0.621828 10.3825 0.266469 10.0472Z"
        fill="#181818"
      />
    </svg>
  );
};
