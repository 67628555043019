import React from "react";

export const InfoIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1495_1645)">
    <path d="M10 0C4.475 0 0 4.475 0 10C0 15.525 4.475 20 10 20C15.525 20 20 15.525 20 10C20 4.475 15.525 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z" fill="#666666"/>
    </g>
    <defs>
    <clipPath id="clip0_1495_1645">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>    

  );
};
