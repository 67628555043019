/**
 * Personal Details Components
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { SingleUser } from "../../../assets/icons/SingleUser";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import PersonalInformation from "./personalInformation";

const PersonalDetails = (props) => {
  const [isRotated, setIsRotated] = useState(false);
  const beneficiary = props.userData || {};

  const handleClick = () => {
    setIsRotated(!isRotated);
  };

  useEffect(() => {
    if (beneficiary) {
      setIsRotated(false);
    }
  }, [beneficiary]);

  return (
    <>
      <div className="w-full h-full flex flex-col ">
        <div className="container flex flex-row justify-between items-center xl:p-4 p-2 w-full h-[100%] border border-customBlue-border rounded-lg bg-customBlue-DetailsComponentBackground">
          <div className="container w-[37px]  ">
            <SingleUser width={36} height={36} />
          </div>

          <div className="container flex flex-row justify-between items-center px-4  w-[calc(100%-37px)]">
            <div className=" container flex justify-between items-center">
              <div>
                <h2 className="font-bold xl:text-xl text-base leading-tight font-raleway">
                  Personal Details
                </h2>
                <h4 className="mt-1 xl:text-sm text-xs font-medium leading-tight text-[#7e7c7c]  font-inter">
                  Includes problem statement, disability images and documents.
                </h4>
              </div>
            </div>
            <div className="container p-0 min-w-[36px] w-[36px] h-[36px] min-h-[36px] flex items-center justify-center">
              <button
                className="w-full h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center"
                onClick={handleClick}
              >
                <ArrowIcon
                  width={8}
                  height={12}
                  isRotated={isRotated}
                  fillColor={"#ffffff"}
                />
              </button>
            </div>
          </div>
        </div>
        {isRotated && (
          <div className="container ">
            <PersonalInformation singleBeneficiary={beneficiary} />
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalDetails;
