import React from "react";

export const Dots = ({ width, height }) => {
  return (
    <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 10.6943C2.39782 10.6943 2.77936 10.8524 3.06066 11.1337C3.34196 11.415 3.5 11.7965 3.5 12.1943C3.5 12.5922 3.34196 12.9737 3.06066 13.255C2.77936 13.5363 2.39782 13.6943 2 13.6943C1.60218 13.6943 1.22064 13.5363 0.93934 13.255C0.658036 12.9737 0.5 12.5922 0.5 12.1943C0.5 11.7965 0.658036 11.415 0.93934 11.1337C1.22064 10.8524 1.60218 10.6943 2 10.6943ZM2 5.44434C2.39782 5.44434 2.77936 5.60237 3.06066 5.88368C3.34196 6.16498 3.5 6.54651 3.5 6.94434C3.5 7.34216 3.34196 7.72369 3.06066 8.005C2.77936 8.2863 2.39782 8.44434 2 8.44434C1.60218 8.44434 1.22064 8.2863 0.93934 8.005C0.658036 7.72369 0.5 7.34216 0.5 6.94434C0.5 6.54651 0.658036 6.16498 0.93934 5.88368C1.22064 5.60237 1.60218 5.44434 2 5.44434ZM2 0.194336C2.39782 0.194336 2.77936 0.352371 3.06066 0.633676C3.34196 0.91498 3.5 1.29651 3.5 1.69434C3.5 2.09216 3.34196 2.47369 3.06066 2.755C2.77936 3.0363 2.39782 3.19434 2 3.19434C1.60218 3.19434 1.22064 3.0363 0.93934 2.755C0.658036 2.47369 0.5 2.09216 0.5 1.69434C0.5 1.29651 0.658036 0.91498 0.93934 0.633676C1.22064 0.352371 1.60218 0.194336 2 0.194336Z" fill="black"/>
    </svg>
    
  );
};
