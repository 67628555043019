/**
 * Common sagas
 * @format
 */

import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  getAllCampTypes,
  getAllDistrict,
  getAllStages,
  getAllState,
  getAllStatus,
  getallUsersOptions,
  getAllUserType,
  getCampAllStages,
  getCampAllStepTypes,
  getDistrictByStateId,
  getDistrictsWithBeneficiariesCount,
  getStatesWithBeneficiariesCount,
  getUserProfile,
  logOutApi,
  setAllUserOptions,
  setCampAllStages,
  setCampAllStepTypes,
  setCampTypes,
  setDistrict,
  setDistrictByState,
  setDistrictWithBeneficiaryCounts,
  setStages,
  setState,
  setStateWithBeneficiaryCounts,
  setStatus,
  setUserAllPermissions,
  setUserProfile,
  setUserType,
} from "./slice";
import { endPoints, httpRequest } from "../../services";
import { storeUserAllPermissions } from "../../utils";

/**
 * Get all District list
 * @param {*}
 */
function* allDistrictSaga(action) {
  try {
    const { district_name, state_code, sai_connect_state_id, onSuccess } =
      action.payload;
    const response = yield httpRequest.post(endPoints.allDistrict, {
      district_name,
      state_code,
      sai_connect_state_id,
    });

    yield onSuccess({ message: response?.message });
    yield put(setDistrict(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get District list By State Id
 * @param {*}
 */
function* districtByStateIdSaga(action) {
  try {
    const { stateId } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getDistrictByState}/${stateId}`
    );

    yield put(setDistrictByState(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get logedin User Profile
 * @param {*}
 */
function* loginUserProfileSaga(action) {
  try {
    const response = yield httpRequest.get(endPoints.userProfile);
    const result = storeUserAllPermissions(response?.data?.permissions);
    yield put(setUserAllPermissions(result));
    yield put(setUserProfile(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all User Type list
 * @param {*}
 */
function* allUserTypeSaga(action) {
  try {
    const { search, onSuccess } = action.payload;
    const response = yield httpRequest.get(endPoints.allUserType, {
      search,
    });

    yield onSuccess({ message: response?.message });
    yield put(setUserType(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all State list
 * @param {*}
 */
function* allStateSaga(action) {
  try {
    const { state_name, iso_code, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allState, {
      state_name,
      iso_code,
    });

    yield onSuccess({ message: response?.message });
    yield put(setState(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Status list
 * @param {*}
 */
function* allStatusSaga(action) {
  try {
    const { search = "", onSuccess } = action.payload;
    const response = yield httpRequest.get(endPoints.allStatus, {
      params: { search },
    });

    yield onSuccess({ message: response?.message });
    yield put(setStatus(response?.data || []));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Stages list
 * @param {*}
 */
function* allStagesSaga(action) {
  try {
    const { search = "", onSuccess } = action.payload;
    const response = yield httpRequest.get(endPoints.allStage, {
      params: { search },
    });

    yield onSuccess({ message: response?.message });
    yield put(setStages(response?.data || []));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Camp Types list
 * @param {*}
 */
function* allCampTypesSaga(action) {
  try {
    const { search = "", onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allCampType, {
      search,
    });

    yield onSuccess({ message: response?.message });
    yield put(setCampTypes(response?.data || []));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Camp Stages list
 * @param {*}
 */
function* allCampAllStagesSaga(action) {
  try {
    const { search = "", onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allCampStages, {
      search,
    });

    // yield onSuccess({ message: response?.message });
    yield put(setCampAllStages(response?.data || []));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Camp Step Type list
 * @param {*}
 */
function* allCampAllStepTypeSaga(action) {
  try {
    const { search = "" } = action.payload;
    const response = yield httpRequest.post(endPoints.allCampStepTypes, {
      search,
    });
    yield put(setCampAllStepTypes(response?.data || []));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get State With Beneficiaries Count
 * @param {*}
 */
function* stateWithBeneficiariesCountSaga() {
  try {
    const response = yield httpRequest.get(endPoints.stateWithBeneficiary);
    yield put(setStateWithBeneficiaryCounts(response || {}));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get District With Beneficiaries Count
 * @param {*}
 */
function* districtWithBeneficiariesCountSaga() {
  try {
    const response = yield httpRequest.get(endPoints.districtWithBeneficiary);
    yield put(setDistrictWithBeneficiaryCounts(response || {}));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get All User For Dropdown Options
 * @param {*}
 */
function* allUsersOptionsSaga(action) {
  try {
    const { scheduled_camp_id } = action.payload;
    const response = yield httpRequest.post(endPoints.allUsersOption, {
      scheduled_camp_id,
    });
    yield put(setAllUserOptions(response || {}));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * LogOut
 * @param {*}
 */
function* logOutApiSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.put(endPoints.logOut);
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* commonSagas() {
  yield takeLatest(getAllDistrict, allDistrictSaga);
  yield takeLatest(getAllState, allStateSaga);
  yield takeLatest(getAllUserType, allUserTypeSaga);
  yield takeLatest(getAllStatus, allStatusSaga);
  yield takeLatest(getAllStages, allStagesSaga);
  yield takeLatest(getAllCampTypes, allCampTypesSaga);
  yield takeLatest(getUserProfile, loginUserProfileSaga);
  yield takeLatest(getCampAllStages, allCampAllStagesSaga);
  yield takeLatest(getCampAllStepTypes, allCampAllStepTypeSaga);
  yield takeLatest(
    getDistrictsWithBeneficiariesCount,
    districtWithBeneficiariesCountSaga
  );
  yield takeLatest(
    getStatesWithBeneficiariesCount,
    stateWithBeneficiariesCountSaga
  );
  yield takeLatest(getallUsersOptions, allUsersOptionsSaga);
  yield takeLatest(getDistrictByStateId, districtByStateIdSaga);
  yield takeLatest(logOutApi, logOutApiSaga);
}
