/**
 * Personal Information Components
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { MarriedStatus } from "../../../assets/icons/MarriedStatus";
import { BloodGroup } from "../../../assets/icons/BloodGroup";
import { Graduation } from "../../../assets/icons/Graduation";
import { Briefcase } from "../../../assets/icons/briefcase";
import { IndianRupee } from "../../../assets/icons/IndianRupee";
import AudioPlayer from "./audioPlayer";
import { Documents } from "../../../assets/icons/Documents";
import { Link } from "react-router-dom";
import { manipulateDateAndTime } from "../../../utils";
import { Date } from "../../../assets/icons/Date";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const PersonalInformation = (props) => {
  const [dateTime, setDateTime] = useState({});
  const [index, setIndex] = React.useState(-1);
  const [uidiOpen, setUdidOpen] = useState(false);

  const beneficiary = props.singleBeneficiary;

  useEffect(() => {
    const result = manipulateDateAndTime(beneficiary?.createdAt);
    setDateTime(result);
  }, [beneficiary]);

  const images = beneficiary?.disability_images || [];
  const [docs, setDocs] = useState([
    { id: 1, des: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaa" },
    { id: 2, des: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaa" },
  ]);

  return (
    <>
      <div className="container flex flex-col justify-between flex-auto bg-white xl:p-4 p-2  divide-y-2 divide-[#c1c1c1] divide-dashed">
        {/* Personal Details and Address */}
        <div className="container p-0 flex flex-col justify-between">
          <div className="">
            <div className="flex flex-row flex-1 mb-3 gap-5  flex-wrap">
              {" "}
              <div className=" ">
                <div className=" text-[#181818] font-bold xl:text-base sm:text-sm text-base mb-3">
                  Personal Details
                </div>
                <div className="grid grid-cols-2 xl:text-base text-sm font-DM font-medium gap-3">
                  <div className="flex gap-2">
                    <div className="">
                      <MarriedStatus width={18} height={18} />
                    </div>
                    <div className="">{beneficiary?.marital_status}</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="">
                      <BloodGroup width={20} height={20} />
                    </div>
                    <div className="">{beneficiary?.blood_group}</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="">
                      <Graduation width={19} height={18} />
                    </div>
                    <div className="">{beneficiary?.education?.education}</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="">
                      <Date width={18} height={18} />
                    </div>
                    <div className="">{beneficiary?.dob}</div>
                  </div>
                </div>
              </div>
              <div className="flex-1 xl:text-base sm:text-sm text-base">
                <div className=" text-[#181818] font-bold xl:text-base sm:text-sm text-base  mb-2">
                  Occupation Details
                </div>
                <div className="grid grid-row-2 font-DM  gap-3 font-medium">
                  <div className="flex gap-2">
                    <div className="">
                      <Briefcase width={18} height={18} />
                    </div>
                    <div className="">
                      {beneficiary?.occupation?.occupation}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="">
                      <IndianRupee width={20} height={18} />
                    </div>
                    <div className="">{beneficiary?.annual_income}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 xl:pt-3 pt-0 text-left">
              <div className=" text-black font-bold xl:text-base sm:text-sm text-base">
                Address Details
              </div>
              <div className="xl:text-base sm:text-sm text-base text-gray-tone-200 ">
                {beneficiary?.address_1}
                {beneficiary?.address_2}
                {", "}
                {beneficiary?.district?.district_name}
                {", "}
                {beneficiary?.state?.state_name}
                {", "}
                Pincode -{beneficiary?.pin_code}
                {", "}
              </div>
            </div>
          </div>
        </div>
        {/* Problem Statement */}
        <div className="container p-0 flex flex-col justify-between mt-3">
          <div className="flex flex-col justify-between mt-4">
            {/* Problem */}
            <div className="flex flex-col flex-1 text-left gap-2">
              <div className=" text-black font-bold xl:text-base sm:text-sm text-base">
                Problem Statement
              </div>
              <div className="xl:text-base text-sm text-gray-tone-200 xl:mb-2 mb-0 font-400">
                {beneficiary?.problem_statement}
              </div>
            </div>
            {/* Audio Player */}
            {/* <div className="my-2">
              <AudioPlayer voice_record={beneficiary?.voice_record} />
            </div> */}

            {/* Documents */}
            <div className="flex flex-col flex-1 text-left mb-2 mt-2">
              <div className="text-black font-bold xl:text-base sm:text-sm text-base xl:mb-4 mb-2">
                UDID
              </div>
              <div className="">
                <Lightbox
                  open={uidiOpen}
                  close={() => setUdidOpen(false)}
                  slides={[
                    { src: beneficiary?.udid_front },
                    { src: beneficiary?.udid_back },
                  ]}
                />
                <div className="flex flex-row justify-between items-center p-2 space-x-2 rounded-lg border">
                  <div className=" font-DM text-sm text-gray-tone-200 text-base font-medium">
                    {` UD ID No. ${beneficiary?.udid || "NA"} `}
                  </div>
                  {beneficiary?.udid && (
                    <div
                      className="flex justify-center items-center text-blue-600 underline ml-2 text-sm cursor-pointer"
                      onClick={() => setUdidOpen(true)}
                    >
                      View Images
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Disability Images */}
            <div className="flex flex-col flex-1 text-left">
              <div className=" text-black font-bold xl:text-base sm:text-sm text-base xl:my-3 my-2">
                Disability Images
              </div>
              <div className="">
                <div className="flex xl:space-x-3 space-x-2">
                  <Lightbox
                    open={index >= 0}
                    close={() => setIndex(-1)}
                    slides={images.map((image) => ({
                      src: image,
                    }))}
                    index={index}
                  />
                  {images?.map((image, imageIndex) => (
                    <div key={imageIndex} className="w-1/4 h-[96px]">
                      <img
                        src={image}
                        alt={`Preview ${imageIndex}`}
                        className="w-full h-[100%] object-cover rounded-lg"
                        onClick={() => setIndex(imageIndex)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
