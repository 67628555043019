import React from "react";

export const Date = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2672)">
        <path
          d="M4.48214 1.73182H4.53214V1.68182V1.09091C4.53214 0.79956 4.81354 0.55 5.17857 0.55C5.5436 0.55 5.825 0.79956 5.825 1.09091V1.68182V1.73182H5.875H13.5357H13.5857V1.68182V1.09091C13.5857 0.79956 13.8671 0.55 14.2321 0.55C14.5972 0.55 14.8786 0.79956 14.8786 1.09091V1.68182V1.73182H14.9286H15.625C16.7593 1.73182 17.6643 2.5105 17.6643 3.45455V11.7273C17.6643 12.6714 16.7593 13.45 15.625 13.45H3.08929C1.955 13.45 1.05 12.6713 1.05 11.7273V3.45455C1.05 2.5105 1.955 1.73182 3.08929 1.73182H4.48214ZM16.3214 4.69955H16.3714V4.64955V3.45455C16.3714 3.09319 16.0292 2.81364 15.625 2.81364H3.08929C2.68507 2.81364 2.34286 3.0932 2.34286 3.45455V4.64955V4.69955H2.39286H16.3214ZM2.39286 5.86667H2.34286V5.91667V11.7273C2.34286 12.0886 2.68507 12.3682 3.08929 12.3682H15.625C16.0292 12.3682 16.3714 12.0886 16.3714 11.7273V5.91667V5.86667H16.3214H2.39286Z"
          fill="#3B395F"
          stroke="white"
          strokeWidth="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2672">
          <rect
            width="18"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
