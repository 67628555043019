import React from "react";

export const SingleUser = ({ width, height }) => {
  return (
   <svg width={width} height={height} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18.7449C22.1421 18.7449 25.5 15.387 25.5 11.2449C25.5 7.10274 22.1421 3.74487 18 3.74487C13.8579 3.74487 10.5 7.10274 10.5 11.2449C10.5 15.387 13.8579 18.7449 18 18.7449Z" stroke="#8E98A8" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M30.8852 33.7449C30.8852 27.9399 25.1102 23.2449 18.0002 23.2449C10.8902 23.2449 5.11523 27.9399 5.11523 33.7449" stroke="#8E98A8" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};
