/**
 * Role Management Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allRoleDataList: {},
  roleCurrentPage: 1,
  roleDataPerPage: 10,
  roleSearchQuery: "",
  role: {},
  roleWithUser: {},
};

const roleManagementSlice = createSlice({
  name: "roleManagement",
  initialState,
  reducers: {
    setAllRoleDataList(state, action) {
      state.allRoleDataList = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setRoleWithUser(state, action) {
      state.roleWithUser = action.payload;
    },
    setRoleCurrentPage(state, action) {
      state.roleCurrentPage = action.payload;
    },
    setRoleDataPerPage(state, action) {
      state.roleDataPerPage = action.payload;
    },
    setRoleSearchQuery(state, action) {
      state.roleSearchQuery = action.payload;
    },
  },
});

// Reducer
export const roleManagementReducer = roleManagementSlice.reducer;

// Actions
export const {
  setAllRoleDataList,
  setRoleCurrentPage,
  setRoleDataPerPage,
  setRole,
  setRoleWithUser,
  setRoleSearchQuery,
} = roleManagementSlice.actions;

export const allRoleList = createAction("ALLROLELIST");
export const createRole = createAction("CREATENEWROLE");
export const updateRoleById = createAction("UPDATEROLEBYID");
export const getRoleById = createAction("GETROLEBYID");
export const getRoleByIdWithUser = createAction("GETROLEBYIDWITHADMINUSER");
export const deleteRole = createAction("DELETEROLE");
export const removeUserFromRole = createAction("REMOVEUSERFROMROLE");
