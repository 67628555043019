import React, { useEffect, useState } from "react";
import { Progress } from "@material-tailwind/react";
import { JourneyLink } from "../../../assets/icons/JourneyLink";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import { CheckFill } from "../../../assets/icons/CheckFill";
import { Hourglass } from "../../../assets/icons/Hourglass";
import { EmptyCircle } from "../../../assets/icons/EmptyCircle";
import { useDispatch, useSelector } from "react-redux";

const BeneficiaryJourney = (props) => {
  const dispatch = useDispatch();
  const [isRotated, setIsRotated] = useState(false);
  const { steps, beneficiary, allData, campData, beneficiaryJourney } = props;

  const stepsCompleted = beneficiaryJourney?.completedCount;
  const completePercentage = (stepsCompleted / 5) * 100;

  const [expandedSteps, setExpandedSteps] = useState({
    registration: false,
    verification: false,
    assessment: false,
    fabrication: false,
    distribution: false,
  });

  const toggleStep = (step) => {
    setExpandedSteps({
      ...expandedSteps,
      [step]: !expandedSteps[step],
    });
  };

  const getClassNames = (stepNumber, step) => {
    if (stepNumber <= stepsCompleted && step?.lastStatus === "complete") {
      // Completed steps (green border and text)
      return "border-green-500 text-green-600";
    } else if (
      stepNumber === stepsCompleted + 1 &&
      step?.lastStatus === "in_progress"
    ) {
      // Next step after completed (blue border and text)
      return "border-blue-500 text-blue-600";
    } else {
      // Remaining steps (gray border and text)
      return "border-gray-400 text-gray-400";
    }
  };
  let updatedSteps = [];
  if (allData) {
    updatedSteps = steps;
    // const campType = allData?.scheduled_camp_id?.camp_type_id?.camp_type_name;
    // // Update steps to disable/enable specific components based on campType
    // updatedSteps = steps?.map((step) => {
    //   if (campType === "assessment" && step.name === "Distribution") {
    //     return { ...step, disabled: true };
    //   } else if (campType === "distribution" && step.name === "Assessment") {
    //     return { ...step, disabled: true };
    //   }
    //   return { ...step, disabled: false };
    // });
    // console.log(updatedSteps);
  } else {
    updatedSteps = steps;
  }

  useEffect(() => {}, [props.beneficiary]);

  return (
    <>
      <div className="container flex flex-row justify-between items-center p-4 w-full h-[100%] border border-customBlue-border rounded-lg bg-customBlue-DetailsComponentBackground">
        <div className="container w-[37px]">
          <JourneyLink width={36} height={36} />
        </div>

        <div className="container flex flex-row justify-between items-center px-4 w-[calc(100%-37px)]">
          <div className="container flex flex-col justify-center items-start">
            <div className="container flex flex-row justify-between items-center">
              <div>
                <h2 className="font-bold xl:text-xl text-lg leading-tight font-raleway">
                  Beneficiary Journey
                </h2>
                <h4 className="mt-1 text-xs font-medium leading-tight text-[#7e7c7c] font-inter">
                  {stepsCompleted} of 5 Steps Completed
                </h4>
              </div>
              <div className="container p-0 min-w-[36px] w-[36px] h-[36px] min-h-[36px] flex items-center justify-center">
                <button
                  className="w-full h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center"
                  onClick={() => {
                    setIsRotated(!isRotated);
                  }}
                >
                  <ArrowIcon
                    width={8}
                    height={12}
                    isRotated={isRotated}
                    fillColor={"#ffffff"}
                  />
                </button>
              </div>
            </div>

            <div className="bar-line-container flex items-center w-full mt-2">
              <Progress value={completePercentage} color="blue" />
            </div>

            <div className="components md:flex-wrap flex items-center justify-between w-full mt-4 overflow-auto">
              {updatedSteps?.map((step, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`component-box border bg-${
                      stepsCompleted >= index + 1 &&
                      step?.lastStatus === "complete"
                        ? "[#EDFFF3]"
                        : "white"
                    } py-1.5 px-2.5 min-w-[105px] rounded-full flex gap-1 items-center justify-center ${getClassNames(
                      index + 1,
                      step
                    )}`}
                  >
                    <span
                      className={` display-${
                        stepsCompleted >= index + 1 &&
                        step?.lastStatus === "complete"
                          ? "block"
                          : "none"
                      } `}
                    >
                      <CheckFill width={12} height={12} />
                    </span>
                    <span className="font-medium font-inter text-sm">
                      {step.name}
                    </span>
                  </div>
                  {index < updatedSteps.length - 1 && (
                    <div
                      className={`separator h-[3px] min-w-[16px] flex-auto bg-${
                        stepsCompleted >= index + 1 &&
                        step?.lastStatus === "complete"
                          ? "green"
                          : "gray"
                      }-500 m-0`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isRotated && (
        <div className="container flex flex-col m-[24px_24px_24px_24px] max-w-[650px] space-y-1">
          {updatedSteps?.map((step, index) => (
            <div
              key={index}
              className="w-full min-h-[70px] flex flex-row justify-between gap-3"
            >
              <div className="flex flex-col h-full justify-between items-center space-y-1">
                <div className="">
                  {index < stepsCompleted && step?.lastStatus === "complete" ? (
                    <CheckFill width={32} height={32} />
                  ) : index === stepsCompleted &&
                    step?.lastStatus === "in_progress" ? (
                    <Hourglass width={32} height={32} />
                  ) : (
                    <EmptyCircle width={32} height={32} />
                  )}
                </div>

                {index < updatedSteps.length - 1 && (
                  <div
                    className={`flex-auto border border-dashed w-0 ${
                      index < stepsCompleted
                        ? "border-[#000000]"
                        : "border-[#CCCCCC]"
                    }`}
                  ></div>
                )}
              </div>
              <div className="container w-[599px]  pt-2">
                <div className="flex flex-row justify-between ">
                  <div>
                    <h2
                      className={`font-bold text-sm font-Inter ${
                        index < stepsCompleted &&
                        step?.lastStatus === "complete"
                          ? "text-[#00A638]"
                          : index === stepsCompleted &&
                            step?.lastStatus === "in_progress"
                          ? "text-[#0162DD]"
                          : "text-[#465668]"
                      }`}
                    >
                      {step.name}
                    </h2>
                    {step.campScheduled ? (
                      <h4 className="mt-1 text-[13px] font-medium text-[#7e7c7c]">
                        {step.campScheduled}
                      </h4>
                    ) : step.campNotSchedled ? (
                      <h4 className="mt-1 text-[13px] font-medium text-[#7e7c7c]">
                        {step.campNotSchedled}
                      </h4>
                    ) : index < stepsCompleted && step.campComplete ? (
                      <h4 className="mt-1 text-[13px] font-medium text-[#7e7c7c]">
                        {step.campComplete}
                      </h4>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="container p-0 min-w-[36px] w-[36px] h-[36px] min-h-[36px] flex items-center justify-center">
                    <button
                      className="w-full h-full bg-[#DDF1FE] rounded-full cursor-pointer flex items-center justify-center"
                      onClick={() => toggleStep(step.name.toLowerCase())}
                      aria-label={`Toggle ${step.name} details`}
                      disabled={step.disabled}
                    >
                      <span
                        className={
                          expandedSteps[step.name.toLowerCase()]
                            ? "rotate-90"
                            : ""
                        }
                      >
                        <ArrowIcon
                          width={8}
                          height={12}
                          fillColor={"#181818"}
                        />
                      </span>
                    </button>
                  </div>
                </div>
                {expandedSteps[step.name.toLowerCase()] && (
                  <div className="container p-0 flex items-center justify-center w-full mt-2 max-w-full">
                    <div className="w-full">
                      {step.name === "Verification" ||
                      step.name === "Fabrication" ||
                      step.name === "Distribution" ||
                      step.name === "Assessment"
                        ? step.component
                        : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BeneficiaryJourney;
