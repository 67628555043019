/**
 * All permissions
 * Define all permissions with corresponding numbers
 * @format
 */

const allPermissions = [
  "View_Dashboard",
  "View_Dashboard_User_Count",
  "View_Dashboard_Camp_Count",
  "View_Dashboard_Upcoming_Camps",
  "Lookup_Beneficiary",
  "View_Beneficiary_List",
  "Verify_Beneficiary",
  "View_UserList",
  "Activate_Deactivate_User",
  "Edit_User",
  "Delete_User",
  "Create_User",
  "View_User_Details",
  "View_Roles_List",
  "Activate_Deactivate_Role",
  "Edit_Role",
  "Delete_Role",
  "Map_Role_Permission",
  "Assign_User_To_Role",
  "View_Scheduled_Camps",
  "Schedule_Camps",
  "Edit_Scheduled_Camps",
  "Assign_Beneficiaries_To_Camp",
  "Setup_Camp_Flow",
  "Assign_Users_To_Camp",
  "View_Camp_Overview",
  "View_Camp_Centres",
  "Create_Camp_Centres",
  "Edit_Camp_Centres",
  "Fabrication",
];

// Function to return permissions in true/false values without numbering
const checkPermissions = (permissionsToCheck) => {
  const result = {};

  // Mark all permissions as false initially
  allPermissions.forEach((permission) => {
    result[permission] = false;
  });

  // Mark only the permissions specified in `permissionsToCheck` as true
  permissionsToCheck.forEach((permission) => {
    if (allPermissions.includes(permission)) {
      result[permission] = true;
    }
  });

  return result;
};

const storeUserAllPermissions = (userPermission) => {
  const permissionResult = checkPermissions(userPermission);
  return permissionResult;
};

// Export the functions to be used in other files
module.exports = { allPermissions, checkPermissions, storeUserAllPermissions };
