import React from "react";

export const CalendarNew = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_836_5843)">
<path d="M5 8H3.66667C3.11533 8 2.66667 8.44867 2.66667 9V10.3333C2.66667 10.8847 3.11533 11.3333 3.66667 11.3333H5C5.55133 11.3333 6 10.8847 6 10.3333V9C6 8.44867 5.55133 8 5 8ZM5.33333 10.3333C5.33333 10.5173 5.18333 10.6667 5 10.6667H3.66667C3.48333 10.6667 3.33333 10.5173 3.33333 10.3333V9C3.33333 8.816 3.48333 8.66667 3.66667 8.66667H5C5.18333 8.66667 5.33333 8.816 5.33333 9V10.3333ZM13 1.33333H12V0.333333C12 0.149333 11.8507 0 11.6667 0C11.4827 0 11.3333 0.149333 11.3333 0.333333V1.33333H4.66667V0.333333C4.66667 0.149333 4.51733 0 4.33333 0C4.14933 0 4 0.149333 4 0.333333V1.33333H3C1.346 1.33333 0 2.67933 0 4.33333V13C0 14.654 1.346 16 3 16H13C14.654 16 16 14.654 16 13V4.33333C16 2.67933 14.654 1.33333 13 1.33333ZM3 2H13C14.2867 2 15.3333 3.04667 15.3333 4.33333V5.33333H0.666667V4.33333C0.666667 3.04667 1.71333 2 3 2ZM13 15.3333H3C1.71333 15.3333 0.666667 14.2867 0.666667 13V6H15.3333V13C15.3333 14.2867 14.2867 15.3333 13 15.3333Z" fill="black" fill-opacity="0.5"/>
</g>
<defs>
<clipPath id="clip0_836_5843">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
