import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import { Delete } from "../assets/icons/Delete";

// DeleteConfirm component and hook combined in one file
const DeleteConfirm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolve, setResolve] = useState(null);
  const [message, setMessage] = useState("Are you sure you want to delete?");

  // Function to open the modal and return a promise
  const confirm = (customMessage = "Are you sure you want to delete?") => {
    setMessage(customMessage);
    setIsOpen(true);
    return new Promise((resolve) => {
      setResolve(() => resolve);
    });
  };

  // Function to handle confirmation result (true or false)
  const handleConfirm = (result) => {
    setIsOpen(false);
    resolve(result);
  };

  // Modal content
  const DeleteConfirmModal = isOpen && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="flex flex-row items-center justify-start w-full space-x-2 text-center">
          <Delete height={20} width={20} />
          <span className="text-lg font-semibold text-center">Delete</span>
        </div>
        <div className="my-6">
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Alert severity="error">{message}</Alert>
          </Stack>
        </div>
        <div className="mt-8 flex justify-end space-x-2 font-semibold">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-600 rounded-lg hover:bg-gray-400"
            onClick={() => handleConfirm(false)}
          >
            No, cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            onClick={() => handleConfirm(true)}
          >
            Yes, I'm sure
          </button>
        </div>
      </div>
    </div>
  );

  return {
    confirm,
    DeleteConfirmModal,
  };
};

export default DeleteConfirm;
