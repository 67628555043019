import React from "react";

export const LeftArrow = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.73353 0.952763C5.818 1.0323 5.88502 1.12678 5.93074 1.23079C5.97646 1.3348 6 1.4463 6 1.5589C6 1.6715 5.97646 1.783 5.93074 1.88701C5.88502 1.99102 5.818 2.0855 5.73353 2.16504L2.19816 5.50094L5.73353 8.83685C5.9039 8.99761 5.99961 9.21564 5.99961 9.44299C5.99961 9.67033 5.9039 9.88837 5.73353 10.0491C5.56316 10.2099 5.33209 10.3002 5.09115 10.3002C4.85021 10.3002 4.61914 10.2099 4.44877 10.0491L0.266468 6.10278C0.181998 6.02324 0.114984 5.92876 0.0692592 5.82475C0.0235353 5.72075 0 5.60925 0 5.49665C0 5.38404 0.0235353 5.27255 0.0692592 5.16854C0.114984 5.06453 0.181998 4.97005 0.266468 4.89051L4.44877 0.944165C4.79502 0.617453 5.37817 0.617453 5.73353 0.952763Z"
        fill="#181818"
      />
    </svg>
  );
};
