/**
 * Beneficiary Verification Page
 * @flow
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeaderScreen, Loader, Sidebar } from "../../../components";
import NavigationBar from "../components/navigationBar";
import BeneficiaryAllDetail from "../components/beneficiaryAllDetail";
import BeneficiaryList from "../components/beneficiaryList";
import {
  allBeneficiaryDetailList,
  allBeneficiaryDetailListForAssessment,
  allBeneficiaryDetailListForDistribution,
  allBeneficiaryDetailListForFabrication,
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
  setBeneficiaryDetailCurrentPage,
  setBeneficiaryStatus,
  setDistrictId,
  setFilter,
  setBeneficiarySearch,
  setStateId,
  setActiveUser,
} from "../slice";
import {
  selectAllBeneficiaryDetailDataList,
  selectAllBeneficiaryDetailListForAssessments,
  selectAllBeneficiaryDetailListForDistribution,
  selectAllBeneficiaryDetailListForFabrication,
  selectBeneficiaryDetailCurrentPage,
  selectBeneficiaryDetailDataPerPage,
  selectBeneficiaryDetails,
  selectBeneficiaryProcessDetail,
  selectDistricId,
  selectFilter,
  selectBeneficiaySearch,
  selectStateId,
  selectBeneficiaryStatus,
} from "../selector";
import BeneficiaryJourney from "../components/beneficiaryJourney";
import PersonalDetails from "../components/personalDetails";
import BeneficiaryDetail from "../components/beneficiaryDetail";
import Pagination from "../../../components/pagination";
import { constants, formatDate, manipulateDateAndTime } from "../../../utils";
import { NoRecord } from "../../../components/noRecords";
import BeneficiaryVerificationPart from "../components/beneficiaryVerificationForm";
import { FabricationComponent } from "../components/fabricationCard";
import { AssessmentComponent } from "../components/assessmentCard";
import { DistributionComponent } from "../components/distributionCard";
import { useLocation } from "react-router-dom";
import {
  selectDistrictIdOrName,
  selectStateIdOrName,
} from "../../dashboard/selector";
import { setDistrictIdOrName, setStateIdOrName } from "../../dashboard/slice";

function BeneficiaryVerificationsScreen() {
  const location = useLocation();
  const [pageName, setPageName] = useState("");
  const [navigationStatus, setNavigationStatus] = useState([]);

  const dispatch = useDispatch();
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [dateTime, setDateTime] = useState({});
  const [loading, setLoading] = useState(false);
  const [singleDetailLoading, setSingleDetailLoading] = useState(false);
  const scrollableRef = useRef(null);

  const searchQuery = useSelector(selectBeneficiaySearch) || "";
  const beneficiaryNabStatus = useSelector(selectBeneficiaryStatus);
  const { id: state_id = "", name: state_name = "" } =
    useSelector(selectStateIdOrName) || {};
  const { id: district_id = "", name: district_name = "" } =
    useSelector(selectDistrictIdOrName) || {};
  const {
    data: users = [],
    count,
    totalBeneficiary,
    verified,
    rejected,
    pending,
    currentPage: currentPageApi,
  } = useSelector(selectAllBeneficiaryDetailDataList) || {};

  const {
    data: assessementBeneficiaryData = [],
    count: assessementCount,
    totalBeneficiary: assessementTotalBeneficiary,
    assessmentInProgressCount,
    assessementToBeScheduledCount,
    assessementScheduledCount,
    currentPage: assessementCurrentPageApi,
  } = useSelector(selectAllBeneficiaryDetailListForAssessments) || {};
  const {
    data: fabricationBeneficiaryData = [],
    count: fabricationCount,
    totalBeneficiary: fabricationTotalBeneficiary,
    fabricationInProgressCount,
    fabricationCompletedCount,
    fabricationNotYetStartedCount,
    notEligibleForFabricationcount,
    currentPage: fabricationCurrentPageApi,
  } = useSelector(selectAllBeneficiaryDetailListForFabrication) || {};
  const {
    data: distributionBeneficiaryData = [],
    count: distributionCount,
    totalBeneficiary: distributionTotalBeneficiary,
    distributionInProgressCount,
    distributionToBeScheduledCount,
    distributionScheduledCount,
    refabricationRequiredCount,
    distributionCompletedCount,
    currentPage: distributionCurrentPageApi,
  } = useSelector(selectAllBeneficiaryDetailListForDistribution) || {};
  const { beneficiaryDetails, campData, beneficiaryJourney } =
    useSelector(selectBeneficiaryProcessDetail) || {};
  const singleBeneficiary = useSelector(selectBeneficiaryDetails) || {};
  const currentPage = useSelector(selectBeneficiaryDetailCurrentPage);
  const limit = useSelector(selectBeneficiaryDetailDataPerPage);
  const districtId = useSelector(selectDistricId);
  const stateId = useSelector(selectStateId);
  const filter = useSelector(selectFilter);
  // console.log({ beneficiaryDetails, campData, beneficiaryJourney });

  // verification data manage
  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };

  // assessment data status managae
  const assessment_last_Status =
    beneficiaryJourney?.stages?.assessments?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.assessments?.data[0] // Correct reference here
    )?.status_id?.status_name;

  // Distribution data status manage
  const distribution_last_Status =
    beneficiaryJourney?.stages?.distribution?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.distribution?.data[0] // Set the first item as the initial value
    )?.status_id?.status_name; // Access status_name after reduce

  // console.log({ assessment_last_Status });

  // Api calling functions
  const getBeneficiaryData = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    setLoading(true);
    dispatch(
      allBeneficiaryDetailList({
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          setLoading(false);
        },
      })
    );
  };
  const getBeneficiaryDataForAssessment = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    setLoading(true);
    dispatch(
      allBeneficiaryDetailListForAssessment({
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          setLoading(false);
        },
      })
    );
  };
  const getBeneficiaryDataForFabrication = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    setLoading(true);
    dispatch(
      allBeneficiaryDetailListForFabrication({
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          setLoading(false);
        },
      })
    );
  };
  const getBeneficiaryDataForDistribution = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    setLoading(true);
    dispatch(
      allBeneficiaryDetailListForDistribution({
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          setLoading(false);
        },
      })
    );
  };

  const getBeneficiaryDetailByUserId = (userId) => {
    setSingleDetailLoading(true);
    dispatch(
      getBeneficiaryDetailById({
        id: userId,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(true);
          setSingleDetailLoading(false);
          console.log("Role loaded successfully");
        },
      })
    );
  };

  const getBeneficiaryProcessDetailByBeneficiaryId = (id) => {
    dispatch(
      getBeneficiaryProcessDetails({
        id,
        onSuccess: (props) => {
          console.log("Data process loaded successfully");
        },
      })
    );
  };

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      campScheduled: "",
      campComplete: `Completed on ${dateTime.date}; ${dateTime.time}`,
      campNotSchedled: "",
      lastStatus: "complete",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={singleBeneficiary}
          userVerificationData={latest_data}
        />
      ),
      campScheduled: "",
      campNotSchedled: "",
      campComplete: `Completed on ${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
      lastStatus:
        latest_data?.status === "verified"
          ? "complete"
          : latest_data?.status === "rejected"
          ? "in_progress"
          : "",
    },
    {
      name: "Assessment",
      component: (
        <AssessmentComponent
          campData={campData?.assessment}
          // campSteps={campData?.assessment[0]?.scheduled_camp_id?.camp_flow}
          beneficiary_id={singleBeneficiary?._id}
        />
      ),
      campScheduled:
        campData?.assessment?.length > 0
          ? `Scheduled on ${formatDate(
              campData?.assessment[0]?.scheduled_camp_id?.from_date
            )} at ${
              campData?.assessment[0]?.scheduled_camp_id?.camp_location_id
                ?.camp_location_name
            } ${
              campData?.assessment[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp`
          : "",
      campComplete: ``,
      campNotSchedled: !campData?.assessment ? `Not yet scheduled` : "",
      disabled: campData?.assessment ? false : true,
      lastStatus:
        assessment_last_Status === "assessement_complete"
          ? "complete"
          : assessment_last_Status === "assessment_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Fabrication",
      component: <FabricationComponent />,
      campScheduled: "",
      campComplete: ``,
      campNotSchedled: `Not yet scheduled`,
      disabled: campData?.fabrication ? false : true,
      descriptions: `Started on 23-01-2024; 11.25 AM | Completed on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Distribution",
      component: <DistributionComponent />,
      campScheduled:
        campData?.distribution?.length > 0
          ? `Scheduled on ${formatDate(
              campData?.distribution[0]?.scheduled_camp_id?.from_date
            )} at ${
              campData?.distribution[0]?.scheduled_camp_id?.camp_location_id
                ?.camp_location_name
            } ${
              campData?.distribution[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp`
          : "",
      campComplete: ``,
      campNotSchedled: !campData?.distribution ? `Not yet scheduled` : "",
      disabled: campData?.distribution ? false : true,
      lastStatus:
        distribution_last_Status === "distribution_complete"
          ? "complete"
          : distribution_last_Status === "distribution_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
  ];
  // console.log(campData);

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    } else if (singleBeneficiary?._id) {
      const result = manipulateDateAndTime(singleBeneficiary?.createdAt);
      setDateTime(result);
      getBeneficiaryProcessDetailByBeneficiaryId(singleBeneficiary?._id);
    }
  }, [singleBeneficiary]);

  useEffect(() => {
    // Set the Navigation Status
    if (pageName === "Verification") {
      getBeneficiaryData(
        searchQuery,
        stateId,
        districtId,
        beneficiaryNabStatus,
        currentPage,
        limit,
        filter
      );
    } else if (pageName === "Assessments") {
      getBeneficiaryDataForAssessment(
        searchQuery,
        stateId,
        districtId,
        beneficiaryNabStatus,
        currentPage,
        limit,
        filter
      );
    } else if (pageName === "Fabrication") {
      getBeneficiaryDataForFabrication(
        searchQuery,
        stateId,
        districtId,
        beneficiaryNabStatus,
        currentPage,
        limit,
        filter
      );
    } else if (pageName === "Distribution") {
      getBeneficiaryDataForDistribution(
        searchQuery,
        stateId,
        districtId,
        beneficiaryNabStatus,
        currentPage,
        limit,
        filter
      );
    }
  }, [
    pageName,
    searchQuery,
    beneficiaryNabStatus,
    currentPage,
    stateId,
    districtId,
    limit,
    filter,
  ]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const path = pathSegments.length > 2 ? pathSegments[2] : "";
    // Set the page name based on the path
    setPageName(path);
    // Set the Navigation Status
    if (path === "Verification") {
      setNavigationStatus([
        `All(${totalBeneficiary || 0})`,
        `Verified(${verified || 0})`,
        `Rejected(${rejected || 0})`,
        `Pending(${pending || 0})`,
      ]);
    } else if (path === "Assessments") {
      setNavigationStatus([
        `All(${assessementTotalBeneficiary || 0})`,
        `In Progress(${assessmentInProgressCount || 0})`,
        `Scheduled(${assessementScheduledCount || 0})`,
        `To be Scheduled(${assessementToBeScheduledCount || 0})`,
      ]);
    } else if (path === "Fabrication") {
      setNavigationStatus([
        `All(${fabricationTotalBeneficiary || 0})`,
        `In Progress(${fabricationInProgressCount || 0})`,
        `Not Yet started(${fabricationNotYetStartedCount || 0})`,
        `Not Eligible(${notEligibleForFabricationcount || 0})`,
      ]);
    } else if (path === "Distribution") {
      setNavigationStatus([
        `All(${distributionTotalBeneficiary || 0})`,
        `In Progress(${distributionInProgressCount || 0})`,
        `Scheduled(${distributionScheduledCount || 0})`,
        `To be Scheduled(${distributionToBeScheduledCount || 0})`,
      ]);
    }
  }, [
    location.pathname,
    totalBeneficiary,
    verified,
    rejected,
    pending,
    assessementTotalBeneficiary,
    assessmentInProgressCount,
    assessementScheduledCount,
    assessementToBeScheduledCount,
    fabricationTotalBeneficiary,
    fabricationInProgressCount,
    fabricationNotYetStartedCount,
    notEligibleForFabricationcount,
    distributionTotalBeneficiary,
    distributionInProgressCount,
    distributionScheduledCount,
    distributionToBeScheduledCount,
  ]);

  const currentPageNumber =
    pageName === "Verification"
      ? currentPageApi
      : pageName === "Assessments"
      ? assessementCurrentPageApi
      : pageName === "Fabrication"
      ? fabricationCurrentPageApi
      : pageName === "Distribution"
      ? distributionCurrentPageApi
      : 0;

  return (
    <>
      <div className="h-full w-screen">
        <HeaderScreen />
        <div className="flex h-[calc(100%-80px)] w-[100%]">
          <Sidebar />

          <div className=" flex flex-col xl:p-0 p-2 justify-between h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            <div className="  w-full max-w-full xl:p-4 p-2 mt-0 flex items-center ">
              <div className="flex flex-row items-center justify-between font-bold xl:text-2xl text-lg title max-w-[42%] min-w-[42%]">
                <div>
                  {constants.beneficiary_list}
                  <span
                    className="text-[#497BC1] xl:text-2xl text-lg cursor-pointer"
                    onClick={() => {
                      if (state_name) {
                        dispatch(setDistrictId(""));
                        dispatch(setStateId(""));
                        dispatch(setBeneficiaryStatus(""));
                        dispatch(setBeneficiarySearch(""));
                        dispatch(setBeneficiaryDetailCurrentPage(1));
                        dispatch(
                          setFilter({
                            startDate: "",
                            endDate: "",
                            pinCode: [],
                          })
                        );
                        dispatch(setStateIdOrName({ id: "", name: "" }));
                        dispatch(setDistrictIdOrName({ id: "", name: "" }));
                      }
                    }}
                  >{` > ${pageName}`}</span>
                  {state_name || district_name ? (
                    <>
                      {state_name && (
                        <span
                          className="text-[#497BC1] xl:text-2xl text-lg cursor-pointer"
                          onClick={() => {
                            if (district_name) {
                              dispatch(setDistrictId(""));
                              // dispatch(setBeneficiaryStatus("")); // Uncomment if needed
                              dispatch(setBeneficiarySearch(""));
                              dispatch(setBeneficiaryDetailCurrentPage(1));
                              dispatch(
                                setFilter({
                                  startDate: "",
                                  endDate: "",
                                  pinCode: [],
                                })
                              );
                              dispatch(
                                setDistrictIdOrName({ id: "", name: "" })
                              );
                            }
                          }}
                        >
                          {` > ${state_name}`}
                        </span>
                      )}
                      {district_name && (
                        <span className="text-[#497BC1] xl:text-2xl text-lg">{` > ${district_name}`}</span>
                      )}
                    </>
                  ) : (
                    <span className="text-[#497BC1] xl:text-2xl text-lg">{` > All`}</span>
                  )}
                </div>
                {/* <div>
                  <Pagination
                    name={"beneficiaryVerficationScreen"}
                    totalNumber={
                      pageName === "Verification"
                        ? count
                        : pageName === "Assessments"
                        ? assessementCount
                        : pageName === "Fabrication"
                        ? fabricationCount
                        : pageName === "Distribution"
                        ? distributionCount
                        : 0
                    }
                    dataPerPage={limit}
                    data={
                      pageName === "Verification"
                        ? getBeneficiaryData
                        : pageName === "Assessments"
                        ? getBeneficiaryDataForAssessment
                        : pageName === "Fabrication"
                        ? getBeneficiaryDataForFabrication
                        : pageName === "Distribution"
                        ? getBeneficiaryDataForDistribution
                        : null
                    }
                    // currentPageApi={currentPageNumber}
                    currentPageApi={
                      pageName === "Verification"
                        ? currentPageApi
                        : pageName === "Assessments"
                        ? assessementCurrentPageApi
                        : pageName === "Fabrication"
                        ? fabricationCurrentPageApi
                        : pageName === "Distribution"
                        ? distributionCurrentPageApi
                        : 0
                    }
                  />
                </div> */}
              </div>
            </div>
            <div className=" flex w-full xl:px-4 px-2  max-w-[42%] min-w-[42%]  ">
              <NavigationBar
                statuses={navigationStatus}
                getData={
                  pageName === "Verification"
                    ? getBeneficiaryData
                    : pageName === "Assessments"
                    ? getBeneficiaryDataForAssessment
                    : pageName === "Fabrication"
                    ? getBeneficiaryDataForFabrication
                    : pageName === "Distribution"
                    ? getBeneficiaryDataForDistribution
                    : null
                }
                beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                barName="beneficiaryVerificationScreen"
                pageName={pageName}
              />
              <div className="col-span-6"></div>
            </div>

            <div className="  min-h-[50%] flex-auto  flex flex-row justify-between xl:gap-6 gap-3 w-full xl:p-4 p-2  pb-0 max-w-full">
              {/* User List with Search  and Filter */}
              {/* <div className="h-full w-[42%] max-h-[1530px] "> */}
              <BeneficiaryList
                users={
                  pageName === "Verification"
                    ? users
                    : pageName === "Assessments"
                    ? assessementBeneficiaryData
                    : pageName === "Fabrication"
                    ? fabricationBeneficiaryData
                    : pageName === "Distribution"
                    ? distributionBeneficiaryData
                    : []
                }
                setSearchQuery={(data) => {
                  dispatch(setBeneficiarySearch(data));
                  dispatch(setActiveUser(null));
                }}
                getBeneficiaryDetailByUserId={getBeneficiaryDetailByUserId}
                beneficiaryStatus={true}
                completionTime={false}
                dateRange={true}
                currentStages={false}
                loading={loading}
              />
              {/* </div> */}
              {/* User Details*/}
              {beneficiaryDetailToggle &&
              Object.keys(singleBeneficiary).length > 0 ? (
                singleBeneficiary?.status === "Verified" ? (
                  singleDetailLoading ? (
                    <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                      <Loader />
                    </div>
                  ) : (
                    <div className="container p-0 h-full self-end w-[58%] pr-4">
                      <div
                        className="container h-full overflow-y-scroll scrollbar-hide xl:space-y-6 space-y-2 flex flex-col bg-white xl:px-6 p-2 xl:py-4 max-w-full w-full rounded-xl"
                        ref={scrollableRef}
                      >
                        {/* Beneficiary Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full border-b-[2px] border-[#c1c1c1] border-dashed ">
                          <BeneficiaryDetail userData={singleBeneficiary} />
                        </div>

                        {/* Personal Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                          <PersonalDetails userData={singleBeneficiary} />
                        </div>
                        {/* Beneficiary Journey */}
                        <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                          <BeneficiaryJourney
                            beneficiary={singleBeneficiary}
                            steps={steps}
                            beneficiaryJourney={beneficiaryJourney}
                            campData={campData}
                          />
                        </div>
                      </div>
                    </div>
                  )
                ) : singleDetailLoading ? (
                  <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                    <Loader />
                  </div>
                ) : (
                  <div className="container h-full self-end pr-4 w-[58%]">
                    <BeneficiaryAllDetail
                      singleBeneficiary={singleBeneficiary}
                      data={getBeneficiaryData}
                      bySearch={false}
                    />
                  </div>
                )
              ) : singleDetailLoading ? (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <Loader />
                </div>
              ) : (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <NoRecord
                    title_1={"Select a Beneficiary to view"}
                    title_2={"Click on the Beneficiaries to view details"}
                  />
                </div>
              )}
            </div>
            <div className=" w-full flex justify-start items-center">
              <Pagination
                name={"beneficiaryVerficationScreen"}
                totalNumber={
                  pageName === "Verification"
                    ? count
                    : pageName === "Assessments"
                    ? assessementCount
                    : pageName === "Fabrication"
                    ? fabricationCount
                    : pageName === "Distribution"
                    ? distributionCount
                    : 0
                }
                dataPerPage={limit}
                data={
                  pageName === "Verification"
                    ? getBeneficiaryData
                    : pageName === "Assessments"
                    ? getBeneficiaryDataForAssessment
                    : pageName === "Fabrication"
                    ? getBeneficiaryDataForFabrication
                    : pageName === "Distribution"
                    ? getBeneficiaryDataForDistribution
                    : null
                }
                // currentPageApi={currentPageNumber}
                currentPageApi={
                  pageName === "Verification"
                    ? currentPageApi
                    : pageName === "Assessments"
                    ? assessementCurrentPageApi
                    : pageName === "Fabrication"
                    ? fabricationCurrentPageApi
                    : pageName === "Distribution"
                    ? distributionCurrentPageApi
                    : 0
                }
                perPageOptions={[5, 10, 30, 50, 100]}
                showFirstButton={true}
                showLastButton={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { BeneficiaryVerificationsScreen };
