import React from "react";

export const Delete = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32275 1.43301H7.54175V0.868506C7.54135 0.679661 7.46616 0.498664 7.33263 0.36513C7.19909 0.231596 7.0181 0.156402 6.82925 0.156006H3.984C3.79505 0.156237 3.61389 0.231358 3.48022 0.36491C3.34655 0.498462 3.27127 0.679553 3.27087 0.868506V1.43301H1.48987C1.31028 1.4332 1.13811 1.50463 1.01112 1.63162C0.884128 1.75861 0.812699 1.93079 0.8125 2.11038V2.84251C0.812732 3.02209 0.884172 3.19424 1.01115 3.32123C1.13814 3.44821 1.31029 3.51965 1.48987 3.51988H1.49875V10.9C1.49901 11.15 1.59842 11.3896 1.77516 11.5664C1.9519 11.7431 2.19154 11.8426 2.4415 11.8429H8.37113C8.62109 11.8426 8.86073 11.7431 9.03747 11.5664C9.2142 11.3896 9.31361 11.15 9.31388 10.9V3.51988H9.32275C9.50232 3.51965 9.67446 3.4482 9.80143 3.32122C9.92839 3.19423 9.9998 3.02207 10 2.84251V2.11038C9.99984 1.9308 9.92843 1.75862 9.80146 1.63163C9.67449 1.50464 9.50233 1.4332 9.32275 1.43301ZM3.70837 0.868506C3.70861 0.795534 3.73774 0.725627 3.7894 0.674087C3.84105 0.622546 3.91103 0.593572 3.984 0.593506H6.82862C6.90149 0.593737 6.9713 0.622784 7.02282 0.674306C7.07435 0.725829 7.10339 0.795642 7.10363 0.868506V1.43301H3.70837V0.868506ZM8.87638 10.9C8.87624 11.034 8.82297 11.1624 8.72825 11.2572C8.63353 11.3519 8.5051 11.4052 8.37113 11.4054H2.4415C2.30753 11.4052 2.17909 11.3519 2.08437 11.2572C1.98965 11.1624 1.93638 11.034 1.93625 10.9V3.51988H8.87638V10.9ZM9.5625 2.84251C9.56247 2.90609 9.5372 2.96707 9.49225 3.01204C9.4473 3.05702 9.38634 3.08231 9.32275 3.08238H1.48987C1.42628 3.08231 1.3653 3.05702 1.32033 3.01205C1.27536 2.96708 1.25007 2.9061 1.25 2.84251V2.11038C1.25007 2.04678 1.27536 1.98581 1.32033 1.94084C1.3653 1.89587 1.42628 1.87057 1.48987 1.87051H9.32275C9.38634 1.87057 9.4473 1.89587 9.49225 1.94084C9.5372 1.98582 9.56247 2.04679 9.5625 2.11038V2.84251Z"
        fill="black"
      />
      <path
        d="M5.40649 9.94165C5.43523 9.94166 5.46368 9.93602 5.49023 9.92503C5.51677 9.91404 5.54089 9.89793 5.56121 9.87761C5.58153 9.8573 5.59764 9.83318 5.60863 9.80663C5.61961 9.78008 5.62526 9.75163 5.62524 9.7229V5.20215C5.62524 5.14413 5.6022 5.08849 5.56117 5.04747C5.52015 5.00645 5.46451 4.9834 5.40649 4.9834C5.34848 4.9834 5.29284 5.00645 5.25181 5.04747C5.21079 5.08849 5.18774 5.14413 5.18774 5.20215V9.7229C5.18774 9.78091 5.21079 9.83655 5.25181 9.87758C5.29284 9.9186 5.34848 9.94165 5.40649 9.94165Z"
        fill="black"
      />
      <path
        d="M7.323 9.94165C7.38101 9.94165 7.43665 9.9186 7.47768 9.87758C7.5187 9.83655 7.54175 9.78091 7.54175 9.7229V5.20215C7.54175 5.14413 7.5187 5.08849 7.47768 5.04747C7.43665 5.00645 7.38101 4.9834 7.323 4.9834C7.26498 4.9834 7.20934 5.00645 7.16832 5.04747C7.1273 5.08849 7.10425 5.14413 7.10425 5.20215V9.7229C7.10425 9.78091 7.1273 9.83655 7.16832 9.87758C7.20934 9.9186 7.26498 9.94165 7.323 9.94165Z"
        fill="black"
      />
      <path
        d="M3.48975 9.94165C3.54776 9.94165 3.6034 9.9186 3.64443 9.87758C3.68545 9.83655 3.7085 9.78091 3.7085 9.7229V5.20215C3.7085 5.14413 3.68545 5.08849 3.64443 5.04747C3.6034 5.00645 3.54776 4.9834 3.48975 4.9834C3.43173 4.9834 3.37609 5.00645 3.33507 5.04747C3.29404 5.08849 3.271 5.14413 3.271 5.20215V9.7229C3.271 9.78091 3.29404 9.83655 3.33507 9.87758C3.37609 9.9186 3.43173 9.94165 3.48975 9.94165Z"
        fill="black"
      />
    </svg>
  );
};
