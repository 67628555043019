/**
 * Date Table Component
 * @format
 */

import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Loader } from "./loader";
import { NoRecord } from "./noRecords";

const DateTableComponent = (props) => {
  const {
    pagination,
    searchQuery,
    totalRow,
    data,
    getData,
    currentPages,
    limit,
    loading,
  } = props;

  const [totalRows, setTotalRows] = useState(totalRow || 0);
  const [perPage, setPerPage] = useState(limit || 10);
  const [currentPage, setCurrentPage] = useState(currentPages || 1);
  const [isInitialize, setIsInitialize] = useState(false);

  const columns = useMemo(() => props.apiColumn);

  const handlePageChange = (page) => {
    getData(searchQuery, currentPage, perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    getData(searchQuery, currentPage, perPage);
    setPerPage(newPerPage);
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={{
        headCells: {
          style: {
            backgroundColor: props.backgroundColor,
            color: "Black",
            fontWeight: "bold",
            fontSize: "14px",
            height: "48px",
          },
        },
      }}
      progressPending={loading}
      progressComponent={
        <div className="h-[500px] w-full flex justify-center items-center bg-customBlue-mainBackground">
          <Loader />
        </div>
      }
      paginationPerPage={perPage}
      paginationRowsPerPageOptions={[10]}
      pagination={pagination}
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      subHeader={false}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      sortIcon={props.sortIcon}
      selectableRows={props.selectableRows}
      dense
      striped
      noDataComponent={
        <div className="h-full py-16">
          <NoRecord title_1={"There are no records to display"} title_2={""} />
        </div>
      }
    />
  );
};

export default DateTableComponent;
