/**
 * Beneficiary Verification Part Components
 * @format
 */

import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { BeneficiaryVerificationFields } from "../../../constants";
import { CrossCircle } from "../../../assets/icons/CrossCircle";
import { CheckCircle } from "../../../assets/icons/CheckCircle";
import { verifyBeneficiary } from "../slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  selectBeneficiaryDetailCurrentPage,
  selectBeneficiaryDetailDataPerPage,
  selectBeneficiaryStatus,
  selectBeneficiaySearch,
  selectDistricId,
  selectFilter,
  selectStateId,
} from "../selector";
import CustomButton from "../../../components/customButtion";

function BeneficiaryVerificationPart(props) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [acceptButtonLoading, setAcceptButtonLoading] = useState(false);
  const [rejectButtonLoading, setRejectButtonLoading] = useState(false);
  const [stage, setStage] = useState("");
  const [id, setId] = useState(props.userData._id || "");
  const verification = props.userVerificationData || {};
  const status = props.status || {};
  const stageIds = props.stageIds || {};
  const districtId = useSelector(selectDistricId);
  const stateId = useSelector(selectStateId);
  const filter = useSelector(selectFilter);
  const currentPage = useSelector(selectBeneficiaryDetailCurrentPage);
  const beneficiaryNabStatus = useSelector(selectBeneficiaryStatus);
  const beneficiaysearchQuery = useSelector(selectBeneficiaySearch) || "";
  const limit = useSelector(selectBeneficiaryDetailDataPerPage);

  const initialValues = {
    [BeneficiaryVerificationFields.BENEFICIARY_ID]: id,
    [BeneficiaryVerificationFields.CALL_STATUS]:
      verification?.call_status || selected,
    [BeneficiaryVerificationFields.STATUS]: verification?.status || "",
    [BeneficiaryVerificationFields.NOTES]: verification?.notes || "",
    // [BeneficiaryVerificationFields.DECLARATION]:
    //   verification.declaration || isChecked,
  };

  const validationSchema = Yup.object().shape({
    [BeneficiaryVerificationFields.CALL_STATUS]:
      Yup.string().required("Required"),
    [BeneficiaryVerificationFields.NOTES]:
      Yup.string().required("Notes is required"),
  });

  const [initialObject, setInitialObject] = useState(initialValues);

  useEffect(() => {
    if (props.userData._id !== id) {
      setId(props.userData._id);
    }

    // Update selected and isChecked states when props change
    setSelected(verification?.call_status ?? selected);
    // setIsChecked(verification?.declaration || isChecked);
  }, [props.userData, verification]);

  const isVerified = props.userData?.status === "Verified";
  const isRejected = props.userData?.status === "Rejected";
  const isPending = props.userData?.status === "Pending";

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          // values.current_status = currentStatus;
          values.beneficiary_stage_id = stage;
          // console.log({ values });

          if (!isVerified) {
            dispatch(
              verifyBeneficiary({
                values,
                onSuccess: (response) => {
                  toast.success(response?.message);
                  // actions.resetForm();
                  setSelected(null);
                  setIsChecked(false);
                  props.list(
                    beneficiaysearchQuery,
                    stateId,
                    districtId,
                    beneficiaryNabStatus,
                    currentPage,
                    limit,
                    filter
                  );
                },
              })
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
            className=""
          >
            <div
              className={`container w-full max-w-full flex flex-col h-[100%] p-3 divide-y-2 divide-gray-500 bg-[#E8F6FF] ${
                isVerified ? "opacity-80 cursor-not-allowed" : ""
              }`}
            >
              <div className="container flex flex-col justify-start w-full">
                <h1 className="text-base font-bold text-gray-tone-800 mb-4">
                  Verification
                </h1>
                <div className="grid gap-[20px] grid-cols-1 justify-between w-full">
                  <div className="">
                    <p className="text-gray-tone-800 font-bold text-sm mb-3">
                      Did you call the beneficiary?
                    </p>
                    <div className="flex space-x-3">
                      <label
                        className={`flex items-center space-x-2 cursor-pointer ${
                          isVerified ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="yesNo"
                          value={true}
                          checked={selected === true}
                          onChange={() => {
                            if (!isVerified) {
                              setSelected(true);
                              setFieldValue(
                                BeneficiaryVerificationFields.CALL_STATUS,
                                true
                              );
                            }
                          }}
                          className={`w-4 h-4 ${
                            selected === true
                              ? "accent-WildWatermelon-button"
                              : ""
                          }`}
                          disabled={isVerified}
                        />
                        <span className="font-bold text-sm">Yes</span>
                      </label>

                      <label
                        className={`flex items-center space-x-2 cursor-pointer ${
                          isVerified ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="yesNo"
                          value={false}
                          checked={selected === false}
                          onChange={() => {
                            if (!isVerified) {
                              setSelected(false);
                              setFieldValue(
                                BeneficiaryVerificationFields.CALL_STATUS,
                                false
                              );
                            }
                          }}
                          className={`w-4 h-4 ${
                            selected === false
                              ? "accent-WildWatermelon-button"
                              : ""
                          }`}
                          disabled={isVerified}
                        />
                        <span className="font-bold text-sm">No</span>
                      </label>
                    </div>
                    {touched[BeneficiaryVerificationFields.CALL_STATUS] &&
                      errors[BeneficiaryVerificationFields.CALL_STATUS] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[BeneficiaryVerificationFields.CALL_STATUS]}
                        </div>
                      )}
                  </div>

                  {/* <div
                    className={`flex items-center ${
                      isVerified ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`w-4 h-4 mr-4 ${
                        isChecked ? "accent-WildWatermelon-button" : ""
                      }`}
                      checked={isChecked}
                      name={BeneficiaryVerificationFields.DECLARATION}
                      id={BeneficiaryVerificationFields.DECLARATION}
                      onChange={() => {
                        if (!isVerified) {
                          setIsChecked(!isChecked);
                          setFieldValue(
                            BeneficiaryVerificationFields.DECLARATION,
                            !isChecked
                          );
                        }
                      }}
                      disabled={isVerified}
                    />
                    <label
                      htmlFor="declaration"
                      className="text-gray-900 text-sm font-bold"
                    >
                      I declare the beneficiary verification information is
                      accurate and complete.
                    </label>
                  </div> */}
                  <div className="">
                    <h1 className="font-bold text-gray-tone-500 mb-3 text-sm">
                      Notes
                    </h1>
                    <textarea
                      className="w-full p-4 border border-[#D1D1D1] rounded-lg text-sm mb-0"
                      placeholder="All The Documents And Information Are Verified."
                      name={BeneficiaryVerificationFields.NOTES}
                      id={BeneficiaryVerificationFields.NOTES}
                      value={values[BeneficiaryVerificationFields.NOTES]}
                      onChange={handleChange}
                      disabled={isVerified}
                    />
                    {touched[BeneficiaryVerificationFields.NOTES] &&
                      errors[BeneficiaryVerificationFields.NOTES] && (
                        <div className="mt-0 mb-2 text-xs text-red-500">
                          {errors[BeneficiaryVerificationFields.NOTES]}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {/* Buttons */}
              <div className="container flex flex-row justify-center items-end">
                <div className="flex flex-row justify-center items-end w-full h-full mt-[20px]">
                  <div className="gap-[32px] flex justify-between">
                    <div className="">
                      <CustomButton
                        type="button"
                        name="Reject"
                        onClick={() => {
                          if (!isVerified) {
                            setCurrentStatus(status?.rejected);
                            setStage(stageIds?.verification);
                            setFieldValue(
                              BeneficiaryVerificationFields.STATUS,
                              status?.rejected
                            );
                            handleSubmit();
                          }
                        }}
                        disabled={isVerified}
                        className={`flex justify-center items-center px-[32px] py-3 w-full h-full bg-black text-white font-bold rounded-xl ${
                          isVerified ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        icon={
                          <span className="mr-2">
                            <CrossCircle width={17} height={16} />
                          </span>
                        }
                        loading={false}
                      />
                      {/* <button
                        type="button"
                        name="Reject"
                        onClick={() => {
                          if (!isVerified) {
                            setCurrentStatus(status?.rejected);
                            setStage(stageIds?.verification);
                            setFieldValue(
                              BeneficiaryVerificationFields.STATUS,
                              status?.rejected
                            );
                            handleSubmit();
                          }
                        }}
                        disabled={isVerified}
                        className={`flex justify-center items-center px-[32px] py-3 w-full h-full bg-black text-white font-bold rounded-xl ${
                          isVerified ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                      >
                        <span className="mr-2">
                          <CrossCircle width={17} height={16} />
                        </span>
                        Reject
                      </button> */}
                    </div>
                    <div className="">
                      <CustomButton
                        name="Accept"
                        type="button"
                        loading={false}
                        onClick={() => {
                          if (!isVerified) {
                            setCurrentStatus(status?.verified);
                            setStage(stageIds?.verification);
                            setFieldValue(
                              BeneficiaryVerificationFields.STATUS,
                              status?.verified
                            );
                            handleSubmit();
                          }
                        }}
                        disabled={isVerified}
                        className={`flex justify-center items-center px-[32px] py-2 w-full h-full bg-WildWatermelon-button text-white font-bold rounded-xl ${
                          isVerified ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        icon={
                          <span className="mr-1">
                            <CheckCircle width={17} height={16} />
                          </span>
                        }
                      />
                      {/* <button
                        type="button"
                        name="verify"
                        onClick={() => {
                          if (!isVerified) {
                            setCurrentStatus(status?.verified);
                            setStage(stageIds?.verification);
                            setFieldValue(
                              BeneficiaryVerificationFields.STATUS,
                              status?.verified
                            );
                            handleSubmit();
                          }
                        }}
                        disabled={isVerified}
                        className={`flex justify-center items-center px-[32px] py-2 w-full h-full bg-WildWatermelon-button text-white font-bold rounded-xl ${
                          isVerified ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                      >
                        <span className="mr-1">
                          <CheckCircle width={17} height={16} />
                        </span>
                        Accept
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default BeneficiaryVerificationPart;
