import React from "react";

export const CrossCircle = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.12467 14.6668C11.7913 14.6668 14.7913 11.6668 14.7913 8.0001C14.7913 4.33344 11.7913 1.33344 8.12467 1.33344C4.45801 1.33344 1.45801 4.33344 1.45801 8.0001C1.45801 11.6668 4.45801 14.6668 8.12467 14.6668Z"
        stroke="white"
        // strokeLinecap="round"
      />
      <path
        d="M6.23828 9.88661L10.0116 6.11328"
        stroke="white"
        // strokeLinecap="round"
      />
      <path
        d="M10.0116 9.88661L6.23828 6.11328"
        stroke="white"
        // strokeLinecap="round"
      />
    </svg>
  );
};
