/**
 * User Management selectors
 * @format
 */

const userManagement = (state) => state.userManagementReducer;

export const selectUserDataList = (state) =>
  userManagement(state).allUserDataList;

export const selectSingleUserData = (state) =>
  userManagement(state).singleUserData;

export const selectUserCurrentPage = (state) =>
  userManagement(state).userCurrentPage;

export const selectUserDataPerPage = (state) =>
  userManagement(state).userDataPerPage;

export const selectUserSearchQuery = (state) =>
  userManagement(state).userSearchQuery;

export const selectStateIdForUser = (state) =>
  userManagement(state).stateIdForUser;

export const selectAllStateWithUserCount = (state) =>
  userManagement(state).stateWithUserCount;
