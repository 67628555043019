/**
 * Counter Number Component
 * @flow
 * @format
 */

import React from "react";

const CounterNumberComponent = (props) => {
  const { counterNumber } = props;
  return (
    <>
      <div className="bg-[#04CF3D] rounded-md px-[6px] py-[2px] text-center">
        <span className="text-[white] font-bold">
          Counter # <span className="font-bold">{counterNumber}</span>
        </span>
      </div>
    </>
  );
};

export default CounterNumberComponent;
