/**
 * Map Permissions Model
 * @format
 */

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getRoleById, updateRoleById } from "../slice";
import { Cross } from "../../../assets/icons/Cross";
import DataTable from "react-data-table-component";
import { Switch } from "@material-tailwind/react";
import {
  selectRole,
  selectRoleCurrentPage,
  selectRoleDataPerPage,
} from "../selector";
import { NoRecord } from "../../../components/noRecords";
import { Loader } from "../../../components";
import CustomButton from "../../../components/customButtion";

const MapPermissions = ({ role, toggleModal, getData }) => {
  const dispatch = useDispatch();
  const roleData = useSelector(selectRole) || {};
  const [permissions, setPermissions] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const currentPage = useSelector(selectRoleCurrentPage);
  const limit = useSelector(selectRoleDataPerPage);

  const initialValues = {};

  useEffect(() => {
    // Set initial permissions state from role data
    if (roleData?.permissions) {
      // roleData?.role?.permission?.
      setPermissions(roleData.permissions);
    }
  }, [roleData]);

  const getRole = (id) => {
    setDataTableLoading(true);
    dispatch(
      getRoleById({
        id,
        onSuccess: (props) => {
          setDataTableLoading(false);
          setDisabled(true);
          console.log("Role By Id loaded successfully");
        },
      })
    );
  };

  const handleSwitchChange = (id) => {
    setPermissions((prevPermissions) => {
      // Find the permission that was toggled
      const toggledPermission = prevPermissions.find(
        (permission) => permission.id === id
      );

      if (!toggledPermission) return prevPermissions;

      // Determine if the permission is being enabled or disabled
      const isEnabling = !toggledPermission.exists;

      // Create a new permissions array with the updated values
      return prevPermissions.map((permission) => {
        // Enable or disable the toggled permission
        if (permission.id === id) {
          return {
            ...permission,
            exists: isEnabling,
          };
        }

        // If enabling the permission, enable all its precedence_permissions
        if (isEnabling) {
          const shouldEnable = toggledPermission.precedence_permission.some(
            (precedence) => precedence._id === permission.id
          );

          if (shouldEnable) {
            return {
              ...permission,
              exists: true,
            };
          }
        }

        // If disabling the permission, disable all permissions that list it as a precedence_permission
        if (!isEnabling) {
          const shouldDisable = permission.precedence_permission.some(
            (precedence) => precedence._id === toggledPermission.id
          );

          if (shouldDisable) {
            return {
              ...permission,
              exists: false,
            };
          }
        }

        return permission;
      });
    });
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
  };

  const columns = [
    {
      name: "Permissions",
      selector: (row) => row?.name,
      style: columnStyle,
      cell: (row) => (
        <div className="flex flex-col justify-between space-y-1">
          <div className="">{row?.name}</div>
          <div className="text-gray-600" style={{ fontSize: "12px" }}>
            {row?.permission_description}
          </div>
        </div>
      ),
    },
    {
      name: "Linked Permission",
      selector: (row) => (
        <div className="text-sm">
          {row?.precedence_permission?.map((item, index) => (
            <div key={index}>{item.permission_display_name},</div>
          ))}
        </div>
      ),
      //   sortable: true,
      style: columnStyle,
    },
    {
      name: "Status",
      selector: (row) => row.exists,
      style: columnStyle,
      cell: (row) => (
        <div className="flex flex-row justify-between space-x-2 w-[70%]">
          <div className="flex justify-center items-center">
            <Switch
              id={row.id}
              ripple={false}
              checked={row.exists}
              onChange={() => {
                handleSwitchChange(row.id);
                setDisabled(false);
              }}
              className="h-full w-full checked:bg-[#B0E0FF]"
              containerProps={{
                className: "w-8 h-4",
              }}
              circleProps={{
                className: "before:hidden left-1 border-none w-[12px] h-[12px]",
              }}
            />
          </div>
          <span className="cursor-pointer flex justify-center items-center flex-auto font-medium text-sm">
            {row.exists === true ? "ON" : "OFF"}
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getRole(role?._id);
  }, [role]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          // Filter to get only the permission IDs where exists is true
          const truePermissions = permissions
            .filter((permission) => permission.exists)
            .map((permission) => permission.id);

          const updatedValues = {
            ...values,
            id: roleData?.role?._id,
            permission: truePermissions,
          };
          // console.log(updatedValues, "updatedValues");
          dispatch(
            updateRoleById({
              updatedValues,
              onSuccess: (props) => {
                toast.success(props?.message);
                getRole(roleData?.role?._id);
                getData("", currentPage, limit);
                setDisabled(true);
              },
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="fixed h-full inset-0 z-50 flex justify-end">
              <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
              <div
                data-aos-easing="linear"
                data-aos="fade-left"
                className="flex flex-col relative w-[43%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
              >
                <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                  <h2 className="text-xl font-bold">Map Permissions</h2>
                  <button
                    onClick={toggleModal}
                    className="text-xl font-semibold p-1"
                  >
                    <Cross width={32} height={32} />
                  </button>
                </div>
                {/* Modal content */}
                <div className="w-full flex flex-col flex-auto h-screen justify-between py-3 px-6 ">
                  <div className={"w-full h-full flex flex-col space-y-3"}>
                    <div className="bg-[#F5F5F5] rounded-[12px] ">
                      <div className=" grid gap-2 pl-6 py-3  md:pl-3">
                        <div className=" grid grid-cols-12 justify-between items-start  ">
                          <div className="flex justify-start  col-span-4 text-sm font-semibold">
                            Role Name
                          </div>
                          <div className="flex justify-start col-span-8 text-sm font-extrabold">
                            {roleData?.role?.role_name}
                          </div>
                        </div>
                        <div className=" grid grid-cols-12 justify-between items-start ">
                          <div className="flex justify-start col-span-4 text-sm">
                            Role Type
                          </div>
                          <div className="flex justify-start col-span-8 text-sm ">
                            {roleData?.role?.role_type_display_name}
                          </div>
                        </div>
                        <div className=" grid grid-cols-12 justify-between items-start ">
                          <div className="flex justify-start col-span-4 text-sm">
                            Role Description
                          </div>
                          <div className="flex justify-start col-span-8 text-sm ">
                            {roleData?.role?.role_description}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Data Table */}
                    <div className="custom-data-table-wrapper">
                      <DataTable
                        columns={columns}
                        data={permissions}
                        progressPending={dataTableLoading}
                        progressComponent={
                          <div className="h-[400px] w-full flex justify-center items-center bg-white">
                            <Loader />
                          </div>
                        }
                        customStyles={{
                          headCells: {
                            style: {
                              backgroundColor: "#DDF1FE",
                              color: "Black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            },
                          },
                        }}
                        noDataComponent={
                          <div className="h-full py-10">
                            <NoRecord
                              title_1={"There are no records to display"}
                              title_2={""}
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="w-full flex justify-end space-x-4 h-[48px] ">
                    <CustomButton
                      name="Reset"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                        getRole(role?._id);
                      }}
                      loading={false}
                      disabled={disabled}
                      className={"min-w-[32%] bg-black text-white h-full"}
                    />
                    <CustomButton
                      name="Save"
                      type="submit"
                      onClick={(e) => {}}
                      loading={false}
                      disabled={disabled}
                      className={"min-w-[32%] h-full"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export { MapPermissions };
