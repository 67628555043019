import React from "react";

export const SingleUser2 = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.95312 6.32812C3.20846 6.32812 1.78906 4.90873 1.78906 3.16406C1.78906 1.4194 3.20846 0 4.95312 0C6.69779 0 8.11719 1.4194 8.11719 3.16406C8.11719 4.90873 6.69779 6.32812 4.95312 6.32812ZM4.95312 0.9375C3.7254 0.9375 2.72656 1.93634 2.72656 3.16406C2.72656 4.39179 3.7254 5.39062 4.95312 5.39062C6.18085 5.39062 7.17969 4.39179 7.17969 3.16406C7.17969 1.93634 6.18085 0.9375 4.95312 0.9375ZM5 10.8281C4.6764 10.8281 4.41406 11.0905 4.41406 11.4141C4.41406 11.7377 4.6764 12 5 12C5.3236 12 5.58594 11.7377 5.58594 11.4141C5.58594 11.0905 5.3236 10.8281 5 10.8281ZM7.48438 11.5312C7.48438 10.1614 6.36987 9.04688 5 9.04688C3.63013 9.04688 2.51562 10.1614 2.51562 11.5312C2.51562 11.7901 2.72548 12 2.98438 12C3.24327 12 3.45312 11.7901 3.45312 11.5312C3.45312 10.6783 4.14704 9.98438 5 9.98438C5.85296 9.98438 6.54688 10.6783 6.54688 11.5312C6.54688 11.7901 6.75673 12 7.01562 12C7.27452 12 7.48438 11.7901 7.48438 11.5312ZM9.61719 11.5312C9.61719 9.17918 7.70363 7.26562 5.35156 7.26562H4.64844C2.29637 7.26562 0.382812 9.17918 0.382812 11.5312C0.382812 11.7901 0.592672 12 0.851562 12C1.11045 12 1.32031 11.7901 1.32031 11.5312C1.32031 9.69612 2.8133 8.20312 4.64844 8.20312H5.35156C7.1867 8.20312 8.67969 9.69612 8.67969 11.5312C8.67969 11.7901 8.88955 12 9.14844 12C9.40733 12 9.61719 11.7901 9.61719 11.5312Z"
        fill="#07284B"
      />
    </svg>
  );
};
