/**
 * All fields names Declare
 * @format
 */

export const UserFields = {
  USER_ID: "id",
  ROLE: "role",
  USER_TYPE: "user_type_id",
  PASSWORD: "password",
  OTP: "otp",

  NUMBER: "number",
  MEMBER_ID: "member_id",
  MOBILE_NUMBER: "mobile_number",
  SSS_MOBILE_NUMBER: "sss_mobile_number",
  ACCESS_LEVEL: "access_level",
  LOCALE: "locale",
  EMAIL: "email",
  NAME: "name",
  GENDER: "gender",
  SAMITHI_ID: "samithi_id",
  SAMITHI_NAME: "samithi_name",
  SSS_STATE_ID: "sss_state_id",
  SSS_STATE_NAME: "sss_state_name",
  SSS_DISTRICT_ID: "sss_district_id",
  SSS_DISTRICT_NAME: "sss_district_name",
  POLITICAL_STATE_ID: "political_state_id",
  POLITICAL_STATE_NAME: "political_state_name",
  POLITICAL_DISTRICT_ID: "political_district_id",
  POLITICAL_DISTRICT_NAME: "political_district_name",
  DESIGNATION_NAME: "designation_name",
  PIN_CODE: "pin_code",
  IS_ACTIVE: "is_active",
};

export const RoleFields = {
  _ID: "_id",
  ROLE_NAME: "role_name",
  ROLE_TYPE: "role_type",
  ROLE_TYPE_DISPLAY_NAME: "role_type_display_name",
  ROLE_DESCRIPTION: "role_description",
  PERMISSION: "permission",
  IS_ACTIVE: "is_active",
  IS_DELETED: "is_deleted",
};

export const BeneficiaryVerificationFields = {
  ID: "_id",
  BENEFICIARY_ID: "beneficiary_id",
  PROCESS_TYPE: "process_type",
  REGISTRATION_DATE: "registration_date",
  CALL_STATUS: "call_status",
  STATUS: "status",
  NOTES: "notes",
  DECLARATION: "declaration",
};

export const CampFields = {
  _ID: "_id",
  ID: "id",
  SCHEDULE_CAMP_ID: "scheduled_camp_id",
  SCHEDULE_CAMP_FLOW_VERSION: "camp_flow_version",
  STATE: "state",
  CAMP_CENTER: "camp_location_id",
  CAMP_CODE: "camp_code",
  CAMP_TYPE: "camp_type_id",
  MAX_NUMBER: "max_numbers",
  START_TIME: "from_time",
  END_TIME: "to_time",
  START_DATE: "from_date",
  END_DATE: "to_date",
  NOTES: "notes",
  INSTRUCTIONS: "instructions",
  USER_ID: "user_id",
  STAGE_ID: "stage_id",
  COUNTER_NO: "counter_number",
  COUNTER_ID: "counter_id",

  STATUS: "status",
  DISTRICT: "district",
  FIELD_1: "field_1",
  FIELD_2: "field_2",
  OPERATION: "operation",
  PIN_CODE: "pin_code",
  DATE: "Date",
};

export const CampCenterFields = {
  _ID: "_id",
  ID: "id",
  LOCATION_CODE: "camp_location_code",
  CAMP_CENTER_NAME: "camp_location_name",
  ADDRESS_1: "address_1",
  ADDRESS_2: "address_2",
  STATE: "state",
  DISTRICT: "district",
  PIN_CODE: "pin_code",
  GEO_COORDINATES: "geo_coordinates",
  CONTACT_PERSON_NAME: "contact_person_name",
  CONTACT_PERSON_PHONE: "contact_person_phone",
  IS_ACTIVE: "is_active",
  NOTES: "camp_notes",
};

export const FabricationFields = {
  COMPLETIONNOTES: "completion_notes",
};
