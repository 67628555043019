import React from "react";

export const Location = ({ width, height, fillColor }) => {
  return (
    <svg width={width}
      height={height}
      viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1043_1108)">
        <path d="M6.826 9.85467C7.15067 10.1727 7.578 10.3307 8.004 10.3307C8.42533 10.3307 8.84533 10.1753 9.16133 9.866L11.2993 7.96667C12.1807 7.08533 12.6667 5.91333 12.6667 4.66667C12.6667 3.42 12.1807 2.24867 11.2993 1.36667C10.4187 0.485333 9.24667 0 8 0C6.75333 0 5.58133 0.485333 4.7 1.36667C2.88067 3.186 2.88067 6.14667 4.714 7.98L6.826 9.85467ZM5.17133 1.838C5.92733 1.08267 6.932 0.666667 8 0.666667C9.068 0.666667 10.0727 1.08267 10.828 1.838C11.584 2.59333 12 3.598 12 4.66667C12 5.73533 11.584 6.73933 10.8427 7.48133L8.70733 9.378C8.318 9.76 7.68333 9.758 7.28067 9.36667L5.17133 7.49467C3.612 5.93533 3.612 3.39733 5.17133 1.838ZM8 6.65333C9.09867 6.65333 9.99333 5.75933 9.99333 4.66C9.99333 3.56067 9.09867 2.66667 8 2.66667C6.90133 2.66667 6.00667 3.56067 6.00667 4.66C6.00667 5.75933 6.90133 6.65333 8 6.65333ZM8 3.33333C8.732 3.33333 9.32667 3.92867 9.32667 4.66C9.32667 5.39133 8.73133 5.98667 8 5.98667C7.26867 5.98667 6.67333 5.39133 6.67333 4.66C6.67333 3.92867 7.26867 3.33333 8 3.33333ZM16 11.344C16 11.4613 15.9387 11.57 15.838 11.63L8.96467 15.754C8.66733 15.9327 8.334 16.022 8 16.022C7.666 16.022 7.33267 15.932 7.03533 15.754L0.162 11.6293C0.0613333 11.5693 0 11.4607 0 11.3433C0 11.226 0.0613333 11.1173 0.162 11.0573L3.49533 9.05733C3.65133 8.962 3.858 9.014 3.95267 9.172C4.048 9.32933 3.996 9.53467 3.838 9.62933L0.981333 11.3433L7.378 15.1813C7.762 15.412 8.23867 15.412 8.62267 15.1813L15.0193 11.3433L12.1453 9.61867C11.9873 9.524 11.936 9.31933 12.0307 9.16133C12.126 9.00333 12.332 8.952 12.488 9.04667L15.838 11.0573C15.9387 11.118 16 11.226 16 11.3433V11.344Z" fill={fillColor} fill-opacity="0.5" />
      </g>
      <defs>
        <clipPath id="clip0_1043_1108">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
