/**
 * permissions Management slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allPermissionDataList: {},
  permissionCurrentPage: 1,
  permissionDataPerPage: 1,
};

const permissionManagementSlice = createSlice({
  name: "permissionManagement",
  initialState,
  reducers: {
    setAllPermissionDataList(state, action) {
      state.allPermissionDataList = action.payload;
    },
    setPermissionCurrentPage(state, action) {
      state.permissionCurrentPage = action.payload;
    },
    setPermissionDataPerPage(state, action) {
      state.permissionDataPerPage = action.payload;
    },
  },
});

// Reducer
export const permissionManagementReducer = permissionManagementSlice.reducer;

// Actions
export const {
  setAllPermissionDataList,
  setPermissionCurrentPage,
  setPermissionDataPerPage,
} = permissionManagementSlice.actions;

export const allPermissionList = createAction("ALLPERMISSIONLIST");
export const createPermission = createAction("CREATENEWPERMISSION");
export const updatePermissionById = createAction("UPDATEPERMISSIONBYID");
export const getPermissionById = createAction("GETPERMISSIONBYID");
export const deletePermission = createAction("DELETEPERMISSION");
