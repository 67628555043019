/**
 * beneficiary Detail Management selector
 * @format
 */

const beneficiaryDetailManagement = (state) =>
  state.beneficiaryDetailManagementReducer;

export const selectAllBeneficiaryDetailDataList = (state) =>
  beneficiaryDetailManagement(state).allBeneficiaryDetailDataList;

export const selectAllBeneficiaryDetailListForAssessments = (state) =>
  beneficiaryDetailManagement(state).allBeneficiaryDetailListForAssessments;

export const selectAllBeneficiaryDetailListForFabrication = (state) =>
  beneficiaryDetailManagement(state).allBeneficiaryDetailListForFabrication;

export const selectAllBeneficiaryDetailListForDistribution = (state) =>
  beneficiaryDetailManagement(state).allBeneficiaryDetailListForDistribution;

export const selectActiveUser = (state) =>
  beneficiaryDetailManagement(state).activeUser;

export const selectBeneficiaryDetails = (state) =>
  beneficiaryDetailManagement(state).beneficiaryDetails;

export const selectBeneficiaryDetailCurrentPage = (state) =>
  beneficiaryDetailManagement(state).beneficiaryDetailCurrentPage;

export const selectBeneficiaryDetailDataPerPage = (state) =>
  beneficiaryDetailManagement(state).beneficiaryDetailDataPerPage;

export const selectBeneficiaryProcessDetail = (state) =>
  beneficiaryDetailManagement(state).beneficiaryProcessDetail;

export const selectBeneficiaryJourneyDetail = (state) =>
  beneficiaryDetailManagement(state).beneficiaryJourneyDetail;

export const selectStateId = (state) =>
  beneficiaryDetailManagement(state).stateId;

export const selectDistricId = (state) =>
  beneficiaryDetailManagement(state).districtId;

export const selectBeneficiaryStatus = (state) =>
  beneficiaryDetailManagement(state).beneficiaryStatus;

export const selectFilter = (state) =>
  beneficiaryDetailManagement(state).filter;

export const selectBeneficiaySearch = (state) =>
  beneficiaryDetailManagement(state).beneficiaySearch;

//current camp
export const selectCurrCampCurrentPage = (state) =>
  beneficiaryDetailManagement(state).currCampCurrentPage;

export const selectCurrCampDataPerPage = (state) =>
  beneficiaryDetailManagement(state).currCampDataPerPage;

export const selectCurrCampSearch = (state) =>
  beneficiaryDetailManagement(state).currCampSearch;

export const selectCurrCampDistrictId = (state) =>
  beneficiaryDetailManagement(state).currCampDistrictId;

export const selectCurrCampStateId = (state) =>
  beneficiaryDetailManagement(state).currCampStateId;

export const selectCurrCampFilter = (state) =>
  beneficiaryDetailManagement(state).currCampFilter;

export const selectCurrCampNavStatus = (state) =>
  beneficiaryDetailManagement(state).currCampNavStatus;
