/**
 * Camp Center Management Selector
 * @format
 */

const campCenterManagement = (state) => state.campCenterManagementReducer;

export const selectAllCampCenterList = (state) =>
  campCenterManagement(state).allCampCentersList;

export const selectCampCenter = (state) =>
  campCenterManagement(state).campCenter;

export const selectCurrentPage = (state) =>
  campCenterManagement(state).currentPage;

export const selectRespPerPage = (state) =>
  campCenterManagement(state).respPerPage;

export const selectAllStateWithCampCenterCount = (state) =>
  campCenterManagement(state).allStateWithCampCenterCount;

export const selectStateId = (state) => campCenterManagement(state).stateId;

export const selectCampCenterSearchQuery = (state) =>
  campCenterManagement(state).campCenterSearchQuery;
