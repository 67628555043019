import React from "react";

export const MarriedStatus = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4466 8.93187C11.6841 8.96187 11.8635 9.13312 12.036 9.31875C13.1891 10.56 13.856 12.2531 13.8716 13.9281C13.8791 14.715 13.3216 15.4 12.6629 15.8275C11.4135 16.6387 9.87787 16.9337 8.34912 17.0625C6.516 17.2175 4.63475 17.0981 2.89287 16.5369C1.82662 16.1931 0.709121 15.6719 0.274121 14.6806C0.101621 14.2875 0.114747 13.8412 0.152872 13.3994C0.290997 11.8131 0.992872 10.2931 2.12475 9.15C2.12475 9.15 2.40225 8.91125 2.68912 8.96125C3.12975 9.03875 3.38162 9.65812 3.011 10.0312C2.99475 10.0475 2.9785 10.0644 2.96225 10.0812C2.001 11.075 1.4135 12.4506 1.3785 13.8544C1.36537 14.385 1.92412 14.7694 2.47287 15.0325C4.10412 15.8131 6.01975 15.9431 7.80037 15.8481C9.09037 15.7794 10.3985 15.5787 11.5472 15.0219C11.916 14.8437 12.2929 14.6331 12.5197 14.29C12.6754 14.0537 12.6247 13.6962 12.5872 13.3619C12.4472 12.1087 11.8722 10.9144 10.9666 10.01C10.9666 10.01 10.7929 9.8375 10.7722 9.61062C10.7441 9.30437 10.976 8.99437 11.2835 8.93937C11.386 8.92062 11.4122 8.93 11.4466 8.93187ZM7.02787 0.875C8.6185 0.89 10.1666 1.83875 10.8941 3.2575C11.5066 4.45375 11.5241 5.93562 10.941 7.14437C10.116 8.85562 8.10412 9.91375 6.17287 9.54312C4.40287 9.20375 2.92037 7.65437 2.6685 5.85875C2.47662 4.48812 2.98037 3.03875 3.98287 2.08312C4.77787 1.325 5.85537 0.885625 6.97162 0.875H7.02787ZM6.97912 2.12437C5.55412 2.13812 4.226 3.21812 3.93912 4.61875C3.73037 5.6375 4.07162 6.75437 4.81787 7.48312C5.68037 8.325 7.0485 8.60375 8.17975 8.14125C9.17787 7.73312 9.9335 6.77562 10.0904 5.705C10.2835 4.3875 9.531 2.9825 8.3185 2.41625C7.90162 2.22125 7.44475 2.12312 6.97912 2.12437Z"
        fill="#3B395F"
      />
    </svg>
  );
};
