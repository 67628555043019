/**
 * Counter Card Component for camp overview
 * @flow
 * @format
 */

import React, { useState } from "react";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import { User } from "../../../assets/icons/User";
import StepWithCountsComponent from "./stepWithNumber";

const CounterCardComponent = (props) => {
  const { counterData } = props;

  return (
    <>
      <div className="flex flex-wrap justify-between p-2 pt-0 items-center  w-full space-y-3 border-b-2 border-[#EBEBEB]">
        <div className="flex justify-between gap-1 items-center w-full">
          <div className=" font-semibold text-WildWatermelon-button ">
            Counter # {counterData?.counter_number}
          </div>
          <div className="flex justify-between gap-1 items-center">
            <User width={14} height={14} />
            <div className="font-semibold">{counterData?.user_name}</div>
            <ArrowIcon
              width={6}
              height={10}
              // isRotated={isRotated}
              fillColor={"#000000"}
            />
          </div>
        </div>
        {/* progress */}
        <div className="flex flex-wrap justify-between  items-center rounded-lg w-full">
          <StepWithCountsComponent
            data={{
              name: "Completed",
              CountNumber: counterData?.completedBeneficiary,
            }}
            colors={{ text: "#256CD0", background: "#DDF1FE" }}
          />
          <StepWithCountsComponent
            data={{
              name: "Waiting",
              CountNumber: counterData?.waitingBeneficiary,
            }}
            colors={{ text: "#36D964", background: "#E0F9E7" }}
          />
        </div>
      </div>
    </>
  );
};

export default CounterCardComponent;
