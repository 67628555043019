import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useDispatch } from "react-redux";
import { setDistrictId, setStateId } from "../../beneficiary_management/slice";

const bgGradientClasses = {
  Verified: "from-[#C4DCFF] to-[#EEF5FF]",
  Assessments: "from-[#9DF6D3] to-[#E2FDF3]",
  Fabrication: "from-[#FFE2A2] to-[#FFF5DF]",
  Distribution: "from-[#FFB4C8] to-[#FFEAEF]",
};

const borderColorClasses = {
  Verified: "border-[#1D73FF]",
  Assessments: "border-[#1CD488]",
  Fabrication: "border-[#FBBB28]",
  Distribution: "border-[#E11E56]",
};

const CircularProgressbarPath = {
  Verified: "#1D73FF",
  Assessments: "#1CD488",
  Fabrication: "#FBBB28",
  Distribution: "#E11E56",
};

const BeneficiaryCountCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name, title = [], data = [], isActive, dataName, isVisible } = props;

  return (
    <div
      className={clsx(
        "xl:rounded-tr-xl xl:rounded-tl-xl rounded-tr-lg rounded-tl-lg p-2 xl:p-3 w-full border border-gray-50 shadow-md",
        isActive ? `bg-gradient-to-b ${bgGradientClasses[name]}` : "bg-white"
      )}
    >
      <div
        className={`flex bg-white xl:rounded-xl rounded-lg flex-col justify-between items-center border-b-4 ${borderColorClasses[name]} outline outline-customBlue-lightBorder `}
      >
        <div className="flex w-full gap-2 p-2 xl:p-3 flex-row justify-between cursor-pointer">
          <div className="">
            <h3 className="text-[#272830] font-semibold text-sm">{name}</h3>
            <p className="text-lg xl:text-[26px] font-bold">
              {data?.done || 0}
            </p>
            <p className="text-gray-tone-400 font-medium text-sm">
              of {data?.total || 0}
            </p>
          </div>
          <div className="flex flex-col justify-between items-end">
            <div className=" w-12 h-12">
              <CircularProgressbar
                value={data?.percentage || 0}
                text={`${data?.percentage || 0}%`}
                styles={buildStyles({
                  pathColor: CircularProgressbarPath[name],
                  textColor: "black",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                  textSize: "30px",
                })}
              />
            </div>
            <button
              className="text-activeLink text-xs mt-2 underline font-semibold inline-block"
              onClick={() => {
                // Set states
                dispatch(setDistrictId(""));
                dispatch(setStateId(""));
                const CardName = name === "Verified" ? "Verification" : name;

                // Navigate and pass the sendData object
                navigate(`/beneficiary/${CardName}`);
              }}
            >
              View all
            </button>
          </div>
        </div>
      </div>

      {isVisible && (
        <div className="mt-1 xl:mt-3 text-sm divide-y divide-solid divide-gray-tone-400">
          <div className="flex justify-between text-gray-tone-400 py-2">
            <span>{title[0]}</span>
            <span className="font-bold text-base text-black">
              {data[dataName[2]] || 0}
            </span>
          </div>
          <div className="flex justify-between text-gray-tone-400 py-2">
            <span>{title[1]}</span>
            <span className="font-bold text-base text-black">
              {data[dataName[3]] || 0}
            </span>
          </div>
          <div className="flex justify-between text-gray-tone-400 pb-0 pt-2 xl:py-2">
            <span>{title[2]}</span>
            <span className="font-bold text-base text-black">
              {data[dataName[4]] || 0}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeneficiaryCountCard;
