import React from "react";

export const Play = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01053 2.82949C5.01058 2.24128 3.75 2.96226 3.75 4.12239V13.8772C3.75 15.0373 5.01058 15.7583 6.01053 15.1701L14.3021 10.2927C15.288 9.71269 15.288 8.28684 14.3021 7.70686L6.01053 2.82949Z"
        fill="white"
      />
    </svg>
  );
};
