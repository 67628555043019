import React from "react";

export const MultipleUser = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9.63786C6.654 9.63786 8 8.29186 8 6.63786C8 4.98386 6.654 3.63786 5 3.63786C3.346 3.63786 2 4.98386 2 6.63786C2 8.29186 3.346 9.63786 5 9.63786ZM5 4.30452C6.28667 4.30452 7.33333 5.35119 7.33333 6.63786C7.33333 7.92452 6.28667 8.97119 5 8.97119C3.71333 8.97119 2.66667 7.92452 2.66667 6.63786C2.66667 5.35119 3.71333 4.30452 5 4.30452ZM10 13.9712V16.9712H9.33333V13.9712C9.33333 12.6845 8.28667 11.6379 7 11.6379H3C1.71333 11.6379 0.666667 12.6845 0.666667 13.9712V16.9712H0V13.9712C0 12.3172 1.346 10.9712 3 10.9712H7C8.654 10.9712 10 12.3172 10 13.9712ZM11.6667 6.97119C13.3207 6.97119 14.6667 5.62519 14.6667 3.97119C14.6667 2.31719 13.3207 0.971191 11.6667 0.971191C10.0127 0.971191 8.66667 2.31719 8.66667 3.97119C8.66667 5.62519 10.0127 6.97119 11.6667 6.97119ZM11.6667 1.63786C12.9533 1.63786 14 2.68452 14 3.97119C14 5.25786 12.9533 6.30452 11.6667 6.30452C10.38 6.30452 9.33333 5.25786 9.33333 3.97119C9.33333 2.68452 10.38 1.63786 11.6667 1.63786ZM16 11.3045V14.3045H15.3333V11.3045C15.3333 10.0179 14.2867 8.97119 13 8.97119H9C8.87067 8.97119 8.746 8.98919 8.62267 9.00919C8.76867 8.78719 8.89533 8.55186 8.99933 8.30452C8.99933 8.30452 8.99933 8.30452 9.00067 8.30452H13.0007C14.6547 8.30452 16 9.65053 16 11.3045Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
};
