import React from "react";

export const IndianRupee = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94266 3.39947H7.77822H7.77849C8.40815 3.40017 9.0237 3.58608 9.54847 3.93406C10.0732 4.28204 10.4841 4.77671 10.7297 5.35647L10.877 5.70402H10.4995H4.94255C4.70509 5.70567 4.47789 5.80111 4.31047 5.96956C4.14289 6.13818 4.04883 6.36626 4.04883 6.60399C4.04883 6.84173 4.14289 7.0698 4.31047 7.23842C4.47789 7.40687 4.70509 7.50232 4.94255 7.50397H10.4997H10.8771L10.7299 7.85149C10.4842 8.43133 10.0734 8.92609 9.54862 9.27414C9.02382 9.62219 8.40821 9.80814 7.77849 9.80882H7.77822H4.9439C4.76664 9.81196 4.59417 9.86694 4.44779 9.96699L4.30672 9.7606L4.44779 9.96699C4.3009 10.0674 4.18676 10.2087 4.11956 10.3735C4.05236 10.5382 4.03506 10.7191 4.0698 10.8936C4.10443 11.0675 4.18922 11.2274 4.31369 11.3536L11.2531 18.1439C11.4238 18.3108 11.6537 18.4032 11.8925 18.4006C12.1312 18.398 12.3591 18.3007 12.5261 18.1301C12.693 17.9594 12.7854 17.7294 12.7828 17.4907C12.7802 17.252 12.6829 17.0241 12.5122 16.8571C12.5122 16.8571 12.5122 16.8571 12.5122 16.8571L7.58636 12.0374L7.14726 11.6078L7.76159 11.6088C8.90057 11.6105 10.0062 11.2242 10.8962 10.5135C11.7863 9.80283 12.4077 8.81014 12.658 7.69902L12.702 7.50397H12.9019H15.0579C15.0579 7.50397 15.0579 7.50397 15.0579 7.50397C15.2966 7.50394 15.5255 7.4091 15.6943 7.24033C15.8631 7.07156 15.9579 6.84268 15.9579 6.604L4.94266 3.39947ZM4.94266 3.39947C4.70536 3.39759 4.4784 3.30206 4.31117 3.13365C4.14376 2.96505 4.0498 2.73708 4.04981 2.49948C4.04982 2.26187 4.14378 2.03391 4.31121 1.86532C4.47844 1.69691 4.70542 1.6014 4.94272 1.59953H15.0568C15.2939 1.60178 15.5204 1.69745 15.6873 1.86578C15.8545 2.03434 15.9483 2.26212 15.9483 2.4995C15.9483 2.73689 15.8545 2.96466 15.6873 3.13321C15.5204 3.30154 15.2938 3.3972 15.0568 3.39945H12.1201H11.6472L11.9135 3.79021C12.2681 4.31071 12.5207 4.89378 12.6579 5.50847L12.7016 5.70399H12.9019H15.0579C15.058 5.70399 15.058 5.70399 15.058 5.70399C15.2967 5.70404 15.5255 5.79887 15.6943 5.96764C15.8631 6.1364 15.9579 6.36528 15.9579 6.60394L4.94266 3.39947Z"
        fill="#3B395F"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};
