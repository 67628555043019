// /**
//  * Pagination Component
//  * @format
//  */

// import React from "react";
// import { IconButton } from "@material-tailwind/react";
// import classnames from "classnames";

// export function Pagination({ totalNumber, dataPerPage, data }) {
//   const totalPages = Math.ceil(totalNumber / dataPerPage);
//   const [active, setActive] = React.useState(1);

//   const getItemProps = (index) => ({
//     className: classnames(
//       "flex items-center justify-center w-8 h-8 rounded-lg cursor-pointer",
//       {
//         "bg-blue-500 text-white": active === index,
//         "bg-gray-200 text-gray-700": active !== index,
//       }
//     ),
//     onClick: () => {
//       setActive(index);
//       data("", "", "", "", index, dataPerPage);
//     },
//   });

//   const next = () => {
//     if (active < totalPages) {
//       setActive(active + 1);
//       data("", "", "", "", active + 1, dataPerPage);
//     }
//   };

//   const prev = () => {
//     if (active > 1) {
//       setActive(active - 1);
//       data("", "", "", "", active - 1, dataPerPage);
//     }
//   };

//   const getPageNumbers = () => {
//     if (totalPages <= 3) {
//       return Array.from({ length: totalPages }, (_, i) => i + 1);
//     }
//     if (active === 1) {
//       return [1, 2, 3];
//     } else if (active === totalPages) {
//       return [totalPages - 2, totalPages - 1, totalPages];
//     } else {
//       return [active - 1, active, active + 1];
//     }
//   };

//   const pageNumbers = getPageNumbers();

//   return (
//     <div className="flex items-center gap-3 px-4">
//       <a
//         variant="text"
//         className="flex items-center gap-2 capitalize px-0 text-[#9E9E9E] text-xs"
//         onClick={prev}
//         disabled={active === 1}
//       >
//         Previous
//       </a>
//       <div className="flex items-center gap-3 ">
//         {pageNumbers.map((num) => (
//           <IconButton key={num} {...getItemProps(num)}>
//             {num}
//           </IconButton>
//         ))}
//       </div>
//       <a
//         variant="text"
//         className="flex items-center gap-2 capitalize px-0 text-[#9E9E9E] text-xs"
//         onClick={next}
//         disabled={active === totalPages}
//       >
//         Next
//       </a>
//     </div>
//   );
// }

import React, { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBeneficiaryDetailCurrentPage,
  selectBeneficiaryStatus,
  selectCurrCampCurrentPage,
  selectCurrCampDataPerPage,
  selectCurrCampDistrictId,
  selectCurrCampFilter,
  selectCurrCampNavStatus,
  selectCurrCampSearch,
  selectCurrCampStateId,
  selectDistricId,
  selectFilter,
  selectStateId,
} from "../pages/beneficiary_management/selector";
import {
  selectBeneficiaryQueryCurrentPage,
  selectBeneficiaryQueryRespPerPage,
  selectBeneficiaryQuerySearch,
} from "../pages/camp_management/selector";
import {
  setBeneficiaryDetailDataPerPage,
  setCurrCampDataPerPage,
} from "../pages/beneficiary_management/slice";
import { setbeneficiaryQueryRespPerPage } from "../pages/camp_management/slice";

export default function Pagination(props) {
  const {
    name,
    totalNumber,
    dataPerPage,
    data,
    currentPageApi,
    perPageOptions,
    showFirstButton,
    showLastButton,
  } = props;

  const [page, setPage] = useState(currentPageApi || 0);
  const [rowsPerPage, setRowsPerPage] = useState(dataPerPage);
  const dispatch = useDispatch();
  const districtId = useSelector(selectDistricId);
  const stateId = useSelector(selectStateId);
  const statusBeneficiary = useSelector(selectBeneficiaryStatus);
  const currentPage = useSelector(selectBeneficiaryDetailCurrentPage);
  const filter = useSelector(selectFilter);

  //camp
  const campSearch = useSelector(selectCurrCampSearch);
  const campCurrentPage = useSelector(selectCurrCampCurrentPage);
  const campLimit = useSelector(selectCurrCampDataPerPage);
  const campDistrictId = useSelector(selectCurrCampDistrictId);
  const campStateId = useSelector(selectCurrCampStateId);
  const campFilter = useSelector(selectCurrCampFilter);
  const campNavStatus = useSelector(selectCurrCampNavStatus);

  //query beneficiary list
  const querySearchQuery = useSelector(selectBeneficiaryQuerySearch);
  const queryCurrentPage = useSelector(selectBeneficiaryQueryCurrentPage);
  const queryRespPerPage = useSelector(selectBeneficiaryQueryRespPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (name === "runningCampScreen") {
      data(
        campSearch,
        campStateId,
        campDistrictId,
        campNavStatus,
        newPage + 1,
        rowsPerPage,
        campFilter
      );
    } else if (name === "beneficiaryVerficationScreen") {
      data(
        "",
        stateId,
        districtId,
        statusBeneficiary,
        newPage + 1,
        rowsPerPage,
        filter
      );
    } else if (name === "queryBeneficiary") {
      data(querySearchQuery, newPage + 1, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (name === "runningCampScreen") {
      dispatch(setCurrCampDataPerPage(newRowsPerPage));
    } else if (name === "beneficiaryVerficationScreen") {
      dispatch(setBeneficiaryDetailDataPerPage(newRowsPerPage));
    } else if (name === "queryBeneficiary") {
      dispatch(setbeneficiaryQueryRespPerPage(newRowsPerPage));
    }
  };

  return (
    <TablePagination
      component="div"
      count={totalNumber || 0}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage || 0}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={perPageOptions}
      showFirstButton={showFirstButton}
      showLastButton={showLastButton}
    />
  );
}
