import React from "react";

export const Cross = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="white" />
      <g opacity="0.9" clipPath="url(#clip0_577_2583)">
        <path
          d="M16.9773 16.0091L21.1307 11.8556C21.401 11.5854 21.401 11.1486 21.1307 10.8784C20.8605 10.6082 20.4237 10.6082 20.1535 10.8784L16 15.0319L11.8466 10.8784C11.5763 10.6082 11.1396 10.6082 10.8695 10.8784C10.5992 11.1486 10.5992 11.5854 10.8695 11.8556L15.0229 16.0091L10.8695 20.1626C10.5992 20.4328 10.5992 20.8696 10.8695 21.1398C11.0041 21.2745 11.1811 21.3422 11.3581 21.3422C11.535 21.3422 11.7119 21.2745 11.8466 21.1398L16 16.9862L20.1535 21.1398C20.2883 21.2745 20.4652 21.3422 20.6421 21.3422C20.819 21.3422 20.9959 21.2745 21.1307 21.1398C21.401 20.8696 21.401 20.4328 21.1307 20.1626L16.9773 16.0091Z"
          fill="#989898"
        />
      </g>
      <defs>
        <clipPath id="clip0_577_2583">
          <rect
            width="10.6667"
            height="10.6667"
            fill="white"
            transform="translate(10.6667 10.667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
