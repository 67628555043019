/**
 * Image source
 * All static images should be import here
 * @format
 */

import loginBg from "../assets/images/loginBg.png";
import SplashScreenImage from "../assets/images/SplashScreenImage.png";
import Vector from "../assets/images/Vector.png";
import loginDesignCircle1 from "../assets/images/loginDesignCircle1.png";
import loginDesignCircle2 from "../assets/images/loginDesignCircle2.png";
import LogoSSS from "../assets/images/LogoSSS.png";
import LogoLogin from "../assets/images/login-logo.png";
import LogoDashboard from "../assets/images/logo-dashboard.svg";
import UserProfile from "../assets/images/userProfile.png";
import Disability from "../assets/images/diabilityImage.png";
import SathyaSai from "../assets/images/SathyaSai.png";
import login2 from "../assets/images/login2.png";
import CircleImage from "../assets/images/CircleImage.png";
import PeopleGroup from "../assets/images/peopleGroup.svg";
import SSSLoginCenterText from "../assets/images/SSSLoginCenterText.svg";
import SSSName from "../assets/images/SSSName.png";
import bgImageLogin from "../assets/images/bg-img.svg";
import upcomingCampImage from "../assets/images/upcomingCamp.png";
import starFixedIcon from "../assets/images/star-icon.svg";
import noRecord from "../assets/images/no-records.svg";

export const Images = {
  loginBg,
  SplashScreenImage,
  Vector,
  loginDesignCircle1,
  loginDesignCircle2,
  LogoSSS,
  LogoLogin,
  LogoDashboard,
  UserProfile,
  Disability,
  SathyaSai,
  CircleImage,
  login2,
  SSSName,
  PeopleGroup,
  SSSLoginCenterText,
  bgImageLogin,
  upcomingCampImage,
  starFixedIcon,
  noRecord,
};
