/**
 * View Time Component
 * @flow
 * @format
 */

import React from "react";
import { Clock } from "../assets/icons/Clock";

const ViewTimeComponent = (props) => {
  const { time } = props;
  return (
    <>
      <div className="flex justify-end gap-3 items-center">
        <Clock width={18} height={18} />
        <div className="text-[#666] font-medium">{time}</div>
      </div>
    </>
  );
};

export default ViewTimeComponent;
