/**
 * Auth slice
 * @format
 */

import { createAction } from "@reduxjs/toolkit";

// Actions
export const login = createAction("LOGIN");

export const sendOTP = createAction("SENDOTP");

export const reSendOTP = createAction("RESENDOTP");
