/**
 * Access Level Management Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAccessLevelDataList: [],
  accessLevelCurrentPage: 1,
  accessLevelDataPerPage: 10,
};

const accessLevelManagementSlice = createSlice({
  name: "accessLevelManagement",
  initialState,
  reducers: {
    setAllAccessLevelDataList(state, action) {
      state.allAccessLevelDataList = action.payload;
    },
    setAccessLevelCurrentPage(state, action) {
      state.accessLevelCurrentPage = action.payload;
    },
    setAccessLevelDataPerPage(state, action) {
      state.accessLevelDataPerPage = action.payload;
    },
  },
});

// Reducer
export const accessLevelManagementReducer = accessLevelManagementSlice.reducer;

// Actions
export const {
  setAllAccessLevelDataList,
  setAccessLevelCurrentPage,
  setAccessLevelDataPerPage,
} = accessLevelManagementSlice.actions;

export const allAccessLevelList = createAction("ALLACCESSLEVELLIST");
export const createAccessLevel = createAction("CREATENEWACCESSLEVEL");
export const updateAccessLevelById = createAction("UPDATEACCESSLEVELBYID");
export const getAccessLevelById = createAction("GETACCESSLEVELBYID");
export const deleteAccessLevel = createAction("DELETEACCESSLEVEL");
