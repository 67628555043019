import React from "react";

export const Edit = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2362 5.14604C10.2075 5.14602 10.1791 5.15167 10.1525 5.16266C10.126 5.17365 10.1018 5.18976 10.0815 5.21007C10.0612 5.23039 10.0451 5.25451 10.0341 5.28106C10.0231 5.30761 10.0175 5.33606 10.0175 5.36479V10.092C10.0171 10.4405 9.8785 10.7746 9.63209 11.021C9.38568 11.2674 9.0516 11.406 8.70312 11.4064H1.87687C1.5284 11.406 1.19432 11.2674 0.94791 11.021C0.701503 10.7746 0.562897 10.4405 0.5625 10.092V3.26579C0.562897 2.91732 0.701503 2.58323 0.94791 2.33683C1.19432 2.09042 1.5284 1.95181 1.87687 1.95142H6.60413C6.66214 1.95142 6.71778 1.92837 6.7588 1.88735C6.79983 1.84632 6.82288 1.79068 6.82288 1.73267C6.82288 1.67465 6.79983 1.61901 6.7588 1.57799C6.71778 1.53696 6.66214 1.51392 6.60413 1.51392H1.87687C1.41241 1.51445 0.967123 1.69919 0.638697 2.02761C0.310271 2.35604 0.125529 2.80133 0.125 3.26579V10.092C0.125529 10.5565 0.310271 11.0018 0.638697 11.3302C0.967123 11.6586 1.41241 11.8434 1.87687 11.8439H8.70312C9.16759 11.8434 9.61288 11.6586 9.9413 11.3302C10.2697 11.0018 10.4545 10.5565 10.455 10.092V5.36479C10.455 5.30677 10.432 5.25113 10.3909 5.21011C10.3499 5.16909 10.2943 5.14604 10.2362 5.14604Z"
        fill="black"
      />
      <path
        d="M11.516 0.702404L11.266 0.452404C11.1721 0.358437 11.0606 0.283895 10.9378 0.233039C10.8151 0.182182 10.6835 0.156006 10.5507 0.156006C10.4178 0.156006 10.2862 0.182182 10.1635 0.233039C10.0407 0.283895 9.92922 0.358437 9.83529 0.452404L9.01217 1.27565L4.20179 6.08678C4.17328 6.11539 4.15318 6.15128 4.14367 6.19053L3.71254 7.99278C3.70384 8.02915 3.70462 8.06714 3.71478 8.10313C3.72495 8.13911 3.74417 8.17189 3.77061 8.19833C3.79706 8.22477 3.82983 8.24399 3.86582 8.25416C3.90181 8.26433 3.9398 8.2651 3.97617 8.2564L5.77829 7.82528C5.81759 7.81579 5.85352 7.79569 5.88217 7.76715L10.6928 2.95628L11.516 2.13315C11.61 2.03922 11.6845 1.9277 11.7354 1.80495C11.7863 1.68221 11.8124 1.55064 11.8124 1.41778C11.8124 1.28491 11.7863 1.15335 11.7354 1.03061C11.6845 0.90786 11.61 0.796336 11.516 0.702404ZM5.61654 7.41403L4.22104 7.7479L4.55479 6.3524L9.16729 1.7399L10.2288 2.8014L5.61654 7.41403ZM11.2062 1.82378L10.5377 2.49228L9.47604 1.43078L10.1452 0.762779C10.253 0.655151 10.3991 0.594702 10.5514 0.594702C10.7038 0.594702 10.8499 0.655151 10.9577 0.762779L11.2077 1.01278C11.3146 1.12072 11.3745 1.26662 11.3742 1.41859C11.374 1.57056 11.3135 1.71623 11.2062 1.82378Z"
        fill="black"
      />
    </svg>
  );
};
