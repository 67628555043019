/**
 * Access Level Management Selector
 * @format
 */

const accessLevelManagement = (state) => state.accessLevelManagementReducer;

export const selectAllAccessLevelDataList = (state) =>
  accessLevelManagement(state).allAccessLevelDataList;

export const selectAccessLevelCurrentPage = (state) =>
  accessLevelManagement(state).accessLevelCurrentPage;

export const selectAccessLevelDataPerPage = (state) =>
  accessLevelManagement(state).accessLevelDataPerPage;
