import React from "react";

export const SearchIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M20.8292 20.0207L16.1819 15.4477C17.3989 14.1255 18.1466 12.3768 18.1466 10.4526C18.146 6.33639 14.7556 3 10.573 3C6.39042 3 3 6.33639 3 10.4526C3 14.5688 6.39042 17.9052 10.573 17.9052C12.3802 17.9052 14.0377 17.2802 15.3397 16.241L20.005 20.832C20.2323 21.056 20.6014 21.056 20.8287 20.832C21.0565 20.6081 21.0565 20.2447 20.8292 20.0207ZM10.573 16.7586C7.03408 16.7586 4.16522 13.9353 4.16522 10.4526C4.16522 6.96991 7.03408 4.14663 10.573 4.14663C14.112 4.14663 16.9808 6.96991 16.9808 10.4526C16.9808 13.9353 14.112 16.7586 10.573 16.7586Z"
          fill="#3B395F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2601">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
