/**
 * Auth Routers
 * @format
 */

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";


import { AuthRoutes } from "../constants";

export const AuthChecker = (props) => {
  const { token } = props;
  console.log(props, "token");
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to={AuthRoutes.LoginScreen} state={{ from: location }} replace />
  );
};
