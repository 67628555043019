/**
 * Root Saga
 * Combine all sagas to create root saga
 * @format
 */

import { spawn } from "redux-saga/effects";
import { commonSagas } from "../pages/common";
import { authSaga } from "../pages/auth/sagas";
import { userManagementSaga } from "../pages/user_management/sagas";
import { roleManagementSaga } from "../pages/role_management/sagas";
import { permissionManagementSaga } from "../pages/permissions_management/sagas";
import { accessLevelManagementSaga } from "../pages/access_level_management/sagas";
import { beneficiaryDetailManagementSaga } from "../pages/beneficiary_management/sagas";
import { dashboardManagementSaga } from "../pages/dashboard/sagas";
import { campCenterManagementSaga } from "../pages/camp_center_management/sagas";
import { scheduleCampManagementSaga } from "../pages/camp_management/sagas";

function* rootSaga() {
  yield spawn(commonSagas);
  yield spawn(authSaga);
  yield spawn(userManagementSaga);
  yield spawn(roleManagementSaga);
  yield spawn(permissionManagementSaga);
  yield spawn(accessLevelManagementSaga);
  yield spawn(beneficiaryDetailManagementSaga);
  yield spawn(dashboardManagementSaga);
  yield spawn(campCenterManagementSaga);
  yield spawn(scheduleCampManagementSaga);
}

export { rootSaga };
