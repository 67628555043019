/**
 * Distribution Components
 * @format
 */

import React, { useEffect, useState } from "react";
import { getFieldBox } from "../../../utils";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { updateRequiredDataOfCampSteps } from "../../camp_management/slice";

export function DistributionComponent(props) {
  const dispatch = useDispatch();
  const {
    campSteps,
    campData,
    beneficiary_id,
    scheduled_camp_id,
    status,
    userCountersData,
  } = props;
  const counterData = campData[0]?.camp_flow?.filter(
    (item) => item?.step_status === "completed"
  );

  // Match and merge data from counterData into campSteps
  campSteps?.camp_flow_steps?.forEach((step) => {
    const matchingCounter = counterData.find(
      (counter) =>
        counter.camp_step_id._id === step.camp_step_id.camp_step_id._id
    );

    if (matchingCounter) {
      step.data = matchingCounter.data;
    }
  });

  // State to track which step is open
  const [selected, setSelected] = useState(null);
  const [openStepBody, setOpenStepBody] = useState(null);
  const [initialObject, setInitialObject] = useState({});

  // Toggle open state for the clicked step
  const toggleStep = (index) => {
    setOpenStepBody((prevIndex) => (prevIndex === index ? null : index));
  };

  // Extract initial values from campSteps and set them
  useEffect(() => {
    const initialValues = {};

    campSteps?.camp_flow_steps?.forEach((stepItem) => {
      stepItem?.camp_step_id?.required_data_id?.forEach((fieldItem) => {
        initialValues[fieldItem?.field_name] =
          stepItem?.data?.[fieldItem?.field_name] || "";
      });
    });

    setInitialObject(initialValues);
    setSelected(initialValues?.checked_in_person ?? selected);
  }, [campSteps]);
  return (
    <>
      <Formik
        initialValues={initialObject}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const { step, ...restValues } = values;
          const counter = campData[0]?.camp_flow?.find((item) => {
            return (
              item?.camp_step_id?._id ===
                step?.camp_step_id?.camp_step_id?._id &&
              item?.step_status === "assigned"
            );
          });

          const statusId =
            step?.step_type?.camp_step_type_name === "initial" ||
            step?.step_type?.camp_step_type_name === "intermediate"
              ? status?.assessement_in_progress
              : step?.step_type?.camp_step_type_name === "final"
              ? status?.assessement_completed
              : null;

          // Filter out only the fields related to the current step
          const filteredData = {};
          step?.camp_step_id?.required_data_id?.forEach((fieldItem) => {
            const fieldName =
              fieldItem?.field_box === "textArea"
                ? `${step?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                : fieldItem?.field_name;

            if (restValues[fieldName]) {
              filteredData[fieldName] = restValues[fieldName];
            }
          });

          const updateValues = {
            beneficiary_id,
            scheduled_camp_id,
            current_status: statusId,
            camp_flow: {
              camp_step_id: values?.step?.camp_step_id?.camp_step_id?._id,
              camp_counter_id: counter?.camp_counter_id?._id,
              step_status: "completed",
              data: filteredData,
            },
          };
          console.log(
            { updateValues, values, counter },
            "Final Payload to Submit"
          );

          dispatch(
            updateRequiredDataOfCampSteps({
              values: updateValues,
              onSuccess: (props) => {
                console.log({ props });
              },
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="w-full flex flex-col justify-start items-start space-y-0 divide-y-2">
              {campSteps?.camp_flow_steps?.map((stepItem, stepIndex) => {
                const isCheckinStep =
                  userCountersData?.step ===
                  stepItem?.camp_step_id?.camp_step_id?.camp_step_name;

                return (
                  <div className="w-full py-3" key={stepIndex}>
                    {/* Label to toggle step body */}
                    <label
                      className={`cursor-pointer font-bold text-base ${
                        isCheckinStep ? "" : "text-gray-500"
                      }`}
                      onClick={() => isCheckinStep && toggleStep(stepIndex)}
                    >
                      {`Step ${stepIndex + 1}: ${
                        stepItem?.camp_step_id?.camp_step_id
                          ?.camp_step_display_name
                      }`}
                    </label>
                    {/* Show body if this step is open */}
                    {openStepBody === stepIndex && isCheckinStep && (
                      <div className="my-2">
                        {stepItem?.camp_step_id?.required_data_id?.map(
                          (fieldItem, fieldIndex) => {
                            let props = {};
                            if (fieldItem?.field_box === "radio") {
                              props = {
                                name: fieldItem?.field_name,
                                id: fieldItem?.field_name,
                                setFieldValue,
                                setSelected,
                                selected,
                                isVerified: false,
                                campStep:
                                  stepItem?.camp_step_id?.camp_step_id?._id,
                              };
                            } else if (fieldItem?.field_box === "textArea") {
                              props = {
                                name: `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`,
                                id: fieldItem?.field_name,
                                onChange: handleChange,
                                disabled: false,
                                placeholder: "",
                                value:
                                  values[
                                    `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                                  ],
                                className: "",
                              };
                            } else if (fieldItem?.field_box === "inputText") {
                              props = {
                                name: fieldItem?.field_name,
                                id: fieldItem?.field_name,
                                onChange: handleChange,
                                type: "text",
                                placeholder: "",
                                value: values[fieldItem?.field_name],
                                className: "",
                              };
                            } else if (
                              fieldItem?.field_box === "singleSelect"
                            ) {
                              props = {
                                name: fieldItem?.field_name,
                                id: fieldItem?.field_name,
                                options: fieldItem?.option_id?.map((item) => ({
                                  label: item?.state_name,
                                  value: item?._id,
                                })),
                                value:
                                  fieldItem?.option_id
                                    ?.map((item) => ({
                                      label: item?.state_name,
                                      value: item?._id,
                                    }))
                                    ?.find(
                                      (item) =>
                                        item?.value ===
                                        values[fieldItem?.field_name]
                                    ) || null,
                                onChange: (selectedOption) => {
                                  const selectedAccess =
                                    selectedOption?.value || "";
                                  setFieldValue(
                                    fieldItem?.field_name,
                                    selectedAccess
                                  );
                                },
                                touched: touched,
                                errors: errors,
                                placeholder: "Select",
                                isSearchable: true,
                                isClearable: false,
                              };
                            } else if (fieldItem?.field_box === "multiSelect") {
                              props = {
                                name: fieldItem?.field_name,
                                id: fieldItem?.field_name,
                                displayValue: "label",
                                showCheckbox: true,
                                showArrow: true,
                                placeholder: "Select",
                                options: fieldItem?.option_id?.map((item) => ({
                                  label: item?.option_display_name,
                                  value: item?._id,
                                })),
                                onSelect: (selectedList) =>
                                  setFieldValue(
                                    fieldItem?.field_name,
                                    selectedList.map((item) => item.value)
                                  ),
                                onRemove: (selectedList) =>
                                  setFieldValue(
                                    fieldItem?.field_name,
                                    selectedList.map((item) => item.value)
                                  ),
                                selectedValues: fieldItem?.option_id
                                  ?.map((item) => ({
                                    label: item?.option_display_name,
                                    value: item?._id,
                                  }))
                                  .filter((option) =>
                                    values[fieldItem?.field_name]?.includes(
                                      option.value
                                    )
                                  ),
                              };
                            }

                            return (
                              <div
                                key={fieldIndex}
                                className="flex flex-col space-y-2 items-start mb-2 w-full"
                              >
                                <div className="w-full text-black font-semibold">
                                  {fieldItem?.field_display_name}
                                </div>
                                <div className="w-full">
                                  {getFieldBox(fieldItem?.field_box, props)}
                                </div>
                              </div>
                            );
                          }
                        )}

                        <button
                          className="p-2 px-4 mt-2 bg-[#FF5976] text-white rounded-lg font-semibold"
                          onClick={() => setFieldValue("step", stepItem)}
                          type="submit"
                          disabled={!isCheckinStep}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
