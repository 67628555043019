/**
 * Camp flow Steps Card Component for camp overview
 * @flow
 * @format
 */

import React, { useState } from "react";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import StepWithCountsComponent from "./stepWithNumber";

const CampFlowStepComponent = (props) => {
  const { isViewCounter, setIsViewCounter, stepData } = props;

  return (
    <>
      <div className="p-2 space-y-3 bg-[#E3F2FF]">
        <div className="flex justify-between gap-1 items-center">
          <div className="text-base font-bold">{stepData?.name}</div>
          <div className="flex justify-between gap-1 items-center">
            <button
              className={`h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center min-w-[24px] w-[24px] min-h-[24px] ${
                isViewCounter ? "rotate-180" : "rotate-90"
              }`}
              onClick={() => {
                setIsViewCounter(!isViewCounter);
              }}
            >
              <ArrowIcon
                width={6}
                height={10}
                isRotated={isViewCounter}
                fillColor={"#ffffff"}
              />
            </button>
          </div>
        </div>
        {/* progress */}
        <div className="flex flex-wrap justify-between  items-center rounded-lg w-full">
          <StepWithCountsComponent
            data={{
              name: "Completed",
              CountNumber: stepData?.completedBeneficiary,
            }}
            colors={{ text: "#256CD0", background: "#DDF1FE" }}
          />
          <StepWithCountsComponent
            data={{
              name: "Waiting",
              CountNumber: stepData?.waitingBeneficiary,
            }}
            colors={{ text: "#36D964", background: "#E0F9E7" }}
          />
        </div>
      </div>
    </>
  );
};

export default CampFlowStepComponent;
