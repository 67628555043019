import React from "react";

export const Email = ({ width, height }) => {
  return (

    <svg width={width}
      height={height} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2133_557)">
        <path d="M15.3012 1.39999H1.9999C1.25432 1.39999 0.649902 2.00441 0.649902 2.74999V10.75C0.649902 11.4956 1.25432 12.1 1.9999 12.1H15.3012C16.0468 12.1 16.6512 11.4956 16.6512 10.75V2.74999C16.6512 2.00441 16.0468 1.39999 15.3012 1.39999Z" stroke="#3B395F" strokeWidth="1.3" />
        <path d="M3.23438 3.99219L8.64744 8.01505L14.0781 3.99219" stroke="#3B395F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2133_557">
          <rect width="18" height="12" fill="white" transform="translate(0 0.75)" />
        </clipPath>
      </defs>
    </svg>


  );
};
